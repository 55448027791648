import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../../styles/style.module.scss";
import { TrainFromContentWrapper } from "../../styles/MuiStyle";
import ApplyLeaveStepper from "../applyLeave/Stepper";
import { Menu, MenuItem, OutlinedInput } from "@mui/material";
import { MethodDropdown } from "../CustomDropdown/MehodDropdown";
import { AddAPIModal } from "../CustomDropdown/AddAPIModal";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AddResponseKeyDropdown } from "../CustomDropdown/AddResponseKeyDropdown";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomAPI,
  setAPIResponseData,
  setAPIResponseState,
  setActionState,
  setBody,
  setCloseTestingApi,
  setMethod,
  setParams,
  setQuery,
  setResponseKeyData,
  setResponseKeysArray,
  setUrl,
  updateCustom_data,
} from "../../../../redux/slices/trainAndContentSlice";
import { AddAPIModalQuery } from "../CustomDropdown/AddAPIModalQuery";
import { AddAPIModalParams } from "../CustomDropdown/AddAPIModalParams";
import { Input } from "../../../../components/common/inputs/Input";
import { useNavigate } from "react-router-dom";

export default function AddAPITestingModal({ rows }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [value, setValue] = useState("1");
  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedqueryKey, setSelectedqueryKey] = useState("");
  const [keyValuePairs, setKeyValuePairs] = useState({});
  const [querykeyValuePairs, setqueryKeyValuePairs] = useState({});
  const [inputValue, setInputValue] = useState("{}");
  const [inputValueQuery, setInputValueQuery] = useState("{}");
  const [anchorEl, setAnchorEl] = useState(null);
  const [defaultValue, setDefaultValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionValue, setSuggestionValue] = useState("");
  const [activate, setActivate] = useState(1);
  const [apiMethod, setAPIMethod] = useState("");
  const [paramsKeyValuePairs, setParamsKeyValuePairs] = useState({});
  const [selectedKey, setSelectedKey] = useState("");
  const [inputValueParams, setInputValueParams] = useState("{}");
  const [headers, setHeaders] = useState({});
  const { storeEntites } = useSelector((state) => state.trainAndContentReducer);

  const isValidJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  };
  const {
    isTestingApiModal,
    url,
    method,
    body,
    query,
    params,
    apiResponseState,
    apiResponseData,
    customActionId,
    responseKeysArray,
    pageCustom,
    limitCustom,
  } = useSelector((state) => state.trainAndContentReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to handle method change
  const handleMethodChange = (event) => {
    setAPIMethod(event.target.value);
    dispatch(setMethod(event.target.value));
  };

  const handleBackButtonStep3 = () => {
    setStep2(true);
    setStep3(false);
  };
  const handleNextStep1 = () => {
    const actualQuery = {};
    Object.keys(query).forEach((key) => {
      const value = query[key];
      const replacedValue = value.replace(
        /{{(.*?)}}/g,
        (match, p1) => query[p1.trim()] || p1
      );
      actualQuery[key] = replacedValue;
    });

    const actualParams = {};
    Object.keys(params).forEach((key) => {
      const value = params[key];
      const replacedValue = value.replace(
        /{{(.*?)}}/g,
        (match, p1) => params[p1.trim()] || p1
      );
      actualParams[key] = replacedValue;
    });

    const replacedUrl = url.replace(/:([^\/]+)/g, (match, p1) => {
      if (query.hasOwnProperty(p1.trim())) {
        return query[p1.trim()];
      }
      return match;
    });

    const replacedUrlQuery = url.replace(/:([^\/]+)/g, (match, p1) => {
      if (actualParams.hasOwnProperty(p1.trim())) {
        return actualParams[p1.trim()];
      }
      return match;
    });

    let fullUrl;
    if (new URLSearchParams(actualQuery).toString()) {
      const queryString = new URLSearchParams(actualQuery).toString();
      fullUrl = replacedUrl + "?" + queryString;
    } else if (new URLSearchParams(actualParams).toString()) {
      fullUrl = replacedUrlQuery;
    } else {
      fullUrl = replacedUrl;
    }

    dispatch(
      createCustomAPI({
        body: body,
        method: method,
        url: fullUrl,
        headers: headers,
        query: query,
        params: params,
        navigate: navigate,
      })
    );
  };

  const handleNextStep2 = () => {
    setStep3(true);
    setStep2(false);
    const values = responseKeyData.map((ele) => {
      return ele;
    });

    dispatch(setResponseKeysArray(values));
  };
  const handleNextStep3 = () => {
    const formattedBody = {};
    const formattedQuery = {};
    const formattedParams = {};

    function formatRequestData(key, value) {
      return storeEntites.find((entity) => entity.hasOwnProperty(key))
        ? `{{${key}}}`
        : value;
    }

    // Iterate through the body query and params object and format the values
    Object.keys(body).forEach((key) => {
      formattedBody[key] = formatRequestData(key, body[key]);
    });

    Object.keys(query).forEach((key) => {
      formattedQuery[key] = formatRequestData(key, query[key]);
    });
    Object.keys(params).forEach((key) => {
      formattedParams[key] = formatRequestData(key, params[key]);
    });

    const bodyData = {
      apiData: {
        url: url,
        method: method.toLowerCase(),
        headers: headers,
        body: formattedBody,
        query: formattedQuery,
        params: formattedParams,
        responseKeys: responseKeysArray,
        responseMessage: `${suggestionValue}`,
        defaultMessage: defaultValue,
      },
    };
    // Dispatch the update action
    dispatch(
      updateCustom_data({
        id: customActionId,
        body: bodyData,
        page: pageCustom,
        limit: limitCustom,
      })
    );
    handleClose();
    dispatch(setAPIResponseState(false));
    dispatch(setUrl(""));
    dispatch(setMethod("POST"));
    // dispatch(setHeaders(null));
    setHeaders({});
    dispatch(setQuery({}));
    dispatch(setBody({}));
    dispatch(setParams({}));
    dispatch(setAPIResponseData(null));
    dispatch(setResponseKeyData(null));
    dispatch(setResponseKeysArray(null));
    dispatch(setActionState(false));
  };

  const handleClose = () => {
    dispatch(setCloseTestingApi(false));
  };
  const handleBackButtonStep2 = () => {
    setStep1(true);
    setStep2(false);
  };
  useEffect(() => {
    setStep1(true);
  }, []);

  const handleDefaultMessageChange = (event) => {
    setDefaultValue(event.target.value);
  };

  const handleKeySelect = (key) => {
    setSelectedKey(key);
    const updatedPairs = { ...keyValuePairs, [key]: "" };
    setKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValue(updatedJSON);
  };
  const handleKeySelectQuery = (key) => {
    setSelectedqueryKey(key);
    const updatedPairs = { ...querykeyValuePairs, [key]: "" };
    setqueryKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValueQuery(updatedJSON);
  };
  const handleKeySelectParams = (key) => {
    setSelectedKey(key);
    const updatedPairs = { ...paramsKeyValuePairs, [key]: "" };
    setParamsKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValueParams(updatedJSON);
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    try {
      const parsedValue = JSON.parse(value);
      setKeyValuePairs(parsedValue);
      dispatch(setBody(parsedValue));
    } catch (error) {
      // Handle parsing errors if any
      setKeyValuePairs({});
      dispatch(setBody({}));
    }
  };
  const handleValueChangeQuery = (event) => {
    const value = event.target.value;
    setInputValueQuery(value);
    try {
      const parsedValue = JSON.parse(value);
      setqueryKeyValuePairs(parsedValue);
      dispatch(setQuery(parsedValue));
    } catch (error) {
      // Handle parsing errors if any
      setqueryKeyValuePairs({});
      dispatch(setQuery({}));
    }
  };
  const handleValueChangeParams = (event) => {
    const value = event.target.value;
    setInputValueParams(value);
    try {
      const parsedValue = JSON.parse(value);
      setParamsKeyValuePairs(parsedValue);
      dispatch(setParams(parsedValue));
    } catch (error) {
      // Handle parsing errors if any
      setParamsKeyValuePairs({});
      dispatch(setParams({}));
    }
  };
  const handleURL = (event) => {
    dispatch(setUrl(event.target.value));
  };
  const handleHeader = (event) => {
    // dispatch(setHeaders(event.target.value));
    setHeaders(event.target.value);
  };
  const handleClick = (element) => {
    setSelectedElements((prevSelected) => {
      let updatedSelectedElements;
      if (prevSelected.includes(element)) {
        updatedSelectedElements = prevSelected.filter(
          (item) => item !== element
        );
      } else {
        updatedSelectedElements = [...prevSelected, element];
      }
      dispatch(setResponseKeyData(updatedSelectedElements));

      return updatedSelectedElements;
    });
  };

  useEffect(() => {
    if (apiResponseState) {
      setStep2(true);
      setStep1(false);
    }
  }, [apiResponseState]);

  const { responseKeyData } = useSelector(
    (state) => state.trainAndContentReducer
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSuggestionValue(event.target.value);
    if (value.includes("@")) {
      // Filter suggestions based on the typed value after '@'
      const searchTerm = value.split("@")[1];
      const filteredSuggestions = responseKeyData.filter((ele) => {
        return ele.toLowerCase().includes(searchTerm.toLowerCase());
      });
      // Extract the last part of each suggestion
      const suggestions = filteredSuggestions.map((ele) => {
        return ele;
      });
      setSuggestions(suggestions);
      setAnchorEl(event.currentTarget);
    } else {
      setSuggestions([]);
      setAnchorEl(null);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSuggestionValue((prevValue) => prevValue + `{{${suggestion}}}`);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (step1) {
      setActivate(1);
    }
    if (step2) {
      setActivate(2);
    }
    if (step3) {
      setActivate(3);
    }
    if (undefined) {
      setActivate(3);
    }
  }, [activate, step1, step2, step3]);

  return (
    <TrainFromContentWrapper>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isTestingApiModal}
        onClose={handleClose}
      >
        {step2 || step3 ? (
          <Box
            display="flex"
            justifyContent="space-between"
            padding="15px 15px 0px 15px"
            alignItems="center"
          >
            <KeyboardBackspaceIcon
              onClick={step2 ? handleBackButtonStep2 : handleBackButtonStep3}
              sx={{ cursor: "pointer" }}
            />
            <DialogTitle className={styles.api_test_text}>
              API Testing
            </DialogTitle>
            <CloseIcon onClick={handleClose} className={styles.cross_icon} />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            padding="15px 15px 0px 15px"
            alignItems="center"
          >
            <DialogTitle className={styles.api_test_txt}>
              API Testing
            </DialogTitle>
            <CloseIcon onClick={handleClose} className={styles.cross_icon} />
          </Box>
        )}
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ApplyLeaveStepper
            step1={step1}
            step2={step2}
            step3={step3}
            value={activate}
          />
        </Box>
        {step2 ? (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            ></Box>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="API Response"
                    value="1"
                    style={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    label="Response Key's"
                    value="2"
                    style={{ textTransform: "capitalize" }}
                  />
                </TabList>
              </Box>
              <TabPanel
                style={{
                  height: "50vh",
                  overflowY: "scroll",
                  border: "1px solid #c4c4c4",
                  margin: "20px 35px 0px 35px",
                  borderRadius: "5px",
                }}
                value="1"
              >
                {apiResponseData?.map((ele, index) => {
                  return (
                    <Box
                      key={index}
                      className={styles.api_res}
                      onClick={() => handleClick(ele)}
                    >
                      {selectedElements.includes(ele) ? (
                        <RemoveIcon className={styles.minus_api_icon} />
                      ) : (
                        <AddIcon className={styles.api_icon} />
                      )}
                      <Box className={styles.api_res_name}>{ele}</Box>
                    </Box>
                  );
                })}
              </TabPanel>
              <TabPanel
                style={{
                  height: "60vh",
                  overflowY: "scroll",
                  border: "1px solid #c4c4c4",
                  margin: "0px 35px 0px 35px",
                  borderRadius: "5px",
                }}
                value="2"
              >
                {selectedElements?.map((ele) => {
                  return (
                    <Box className={styles.api_res}>
                      <RemoveIcon className={styles.minus_api_icon} />
                      <Box className={styles.api_res_name}>{ele}</Box>
                    </Box>
                  );
                })}
              </TabPanel>
            </TabContext>

            <DialogActions
              className={
                selectedElements.length === 0
                  ? styles.next_btn_disable
                  : styles.next_btn
              }
            >
              <Button
                onClick={selectedElements.length === 0 ? null : handleNextStep2}
              >
                Next
              </Button>
            </DialogActions>
          </Box>
        ) : step3 ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              m={3}
              mb={1}
              alignItems="center"
            >
              <Box color="#323454" fontWeight="bold">
                Response Message
              </Box>
              <AddResponseKeyDropdown setSuggestionValue={setSuggestionValue} />
            </Box>
            <Input
              type="text"
              handleChange={handleInputChange}
              name="inputValue"
              value={suggestionValue}
              placeholder="Tip: you can add '@' to add response keys"
              InputLabelProps={{
                shrink: true,
                position: "start",
              }}
              style={{
                borderColor: "#c4c4c4",
                margin: "0px 20px 0px 20px",
                borderRadius: "5px",
              }}
            />

            <Menu
              style={{ width: "100%" }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {suggestions?.map((option) => {
                return (
                  <MenuItem
                    key={option}
                    onClick={() => handleSuggestionClick(option)}
                  >
                    {option}
                  </MenuItem>
                );
              })}
            </Menu>

            <Box
              display="flex"
              justifyContent="space-between"
              m={3}
              mb={1}
              alignItems="center"
            >
              <Box color="#323454" fontWeight="bold">
                Default Message
              </Box>
            </Box>
            <Input
              type="text"
              handleChange={handleDefaultMessageChange}
              name="defaultValue"
              value={defaultValue}
              placeholder="Type here"
              style={{
                borderColor: "#c4c4c4",
                margin: "0px 20px 0px 20px",
                borderRadius: "5px",
              }}
            />
            <DialogActions
              className={
                !defaultValue || !suggestionValue
                  ? styles.next_btn_disable
                  : styles.next_btn
              }
            >
              <Button
                onClick={
                  !defaultValue || !suggestionValue ? null : handleNextStep3
                }
              >
                Next
              </Button>
            </DialogActions>
          </>
        ) : (
          step1 && (
            <>
              <DialogContent>
                <Box noValidate component="form">
                  <Box display="flex" gap="20px" width="100%">
                    <MethodDropdown handleMethodChange={handleMethodChange} />
                    <Box width="100%">
                      <Box color="#323454" fontWeight="bold">
                        URL
                      </Box>

                      <Input
                        handleChange={handleURL}
                        name="url"
                        value={url}
                        placeholder="Enter URL"
                      />
                    </Box>
                  </Box>
                  <Box mt={2} color="#323454" fontWeight="bold">
                    Header
                  </Box>
                  <input
                    className={styles.auth_text}
                    placeholder="{Contnent-Type:application/json Autrization: bearer <token>}"
                    onChange={handleHeader}
                    value={
                      typeof headers === "object"
                        ? JSON.stringify(headers)
                        : headers
                    }
                  />
                  {!isValidJSON(
                    typeof headers === "object"
                      ? JSON.stringify(headers)
                      : headers
                  ) && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {`Please enter valid Json format`}
                    </div>
                  )}
                  {/* body */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={4}
                    mb={2}
                    alignItems="center"
                  >
                    <Box color="#323454" fontWeight="bold">
                      Body
                    </Box>
                    <AddAPIModal
                      rows={rows}
                      handleKeySelect={handleKeySelect}
                    />
                  </Box>
                  <input
                    className={
                      !isValidJSON(inputValue) ? styles.error : styles.auth_text
                    }
                    value={inputValue}
                    onChange={handleValueChange}
                  />
                  {!isValidJSON(inputValue) && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {`Please enter valid Json format`}
                    </div>
                  )}
                  {/* query */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={4}
                    mb={2}
                    alignItems="center"
                  >
                    <Box color="#323454" fontWeight="bold">
                      Query
                    </Box>
                    <AddAPIModalQuery
                      rows={rows}
                      handleKeySelect={handleKeySelectQuery}
                    />
                  </Box>
                  <input
                    className={
                      !isValidJSON(inputValueQuery)
                        ? styles.error
                        : styles.auth_text
                    }
                    value={inputValueQuery}
                    onChange={handleValueChangeQuery}
                  />
                  {!isValidJSON(inputValueQuery) && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {`Please enter valid Json format`}
                    </div>
                  )}
                  {/* params */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={4}
                    mb={2}
                    alignItems="center"
                  >
                    <Box color="#323454" fontWeight="bold">
                      Param's
                    </Box>
                    <AddAPIModalParams
                      rows={rows}
                      handleKeySelect={handleKeySelectParams}
                    />
                  </Box>
                  <input
                    className={
                      !isValidJSON(inputValueParams)
                        ? styles.error
                        : styles.auth_text
                    }
                    value={inputValueParams}
                    onChange={handleValueChangeParams}
                  />
                  {!isValidJSON(inputValueParams) && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {`Please enter valid Json format`}
                    </div>
                  )}
                </Box>
              </DialogContent>

              <DialogActions
                className={
                  !url || !method || !headers
                    ? styles.next_btn_disable
                    : styles.next_btn
                }
              >
                <Button
                  onClick={!url || !method || !headers ? null : handleNextStep1}
                >
                  Next
                </Button>
              </DialogActions>
            </>
          )
        )}
      </Dialog>
    </TrainFromContentWrapper>
  );
}
