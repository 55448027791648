import React from "react";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox } from "@mui/material";
import styles from "./styles/style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setFilterValue } from "../../redux/slices/userManagementSilce";

const CustomDropdown = ({ open, setOpen }) => {
  const dispath = useDispatch();
  const { filterValue } = useSelector((state) => state.userManagementReducer);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (value) => {
    dispath(setFilterValue(value));
    handleClose();
  };

  return (
    <div style={{ position: "relative" }} onClose={handleClose}>
      <IconButton onClick={handleToggle}>
        <ExpandMoreIcon />
      </IconButton>
      {open && (
        <div className={styles.menu_div}>
          <MenuItem
            onClick={() => handleMenuItemClick("All")}
            className={styles.menu}
          >
            <div>All</div>
            <Checkbox checked={filterValue === "All"} />
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Verified")}
            className={styles.menu}
          >
            <div>Verified</div>
            <Checkbox checked={filterValue === "Verified"} />
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Unverified")}
            className={styles.menu}
          >
            <div>Unverified</div>
            <Checkbox checked={filterValue === "Unverified"} />
          </MenuItem>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
