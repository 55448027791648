import { Grid } from "@mui/material";
import React from "react";
import { Input } from "../../components/common/inputs/Input";
import AppSecureStorage from "../../services/storage/secureStorage";

const storage = new AppSecureStorage();

const ProfileFields = ({ handleChange, formData }) => {
  let permissions = JSON.parse(storage.get("permissions"));
  let editProfileMenuItem =
    permissions &&
    permissions["Profile"] &&
    permissions["Profile"]?.includes("Edit");
  const handleGenderChange = (e) => {
    const lowercaseValue = e.target.value.toLowerCase();
    handleChange({
      target: {
        name: "gender",
        value: lowercaseValue,
      },
    });
  };

  const inputFields = [
    {
      label: "Email",
      name: "email",
      value: formData?.email,
      disabled: true,
    },
    {
      label: "Contact no.",
      name: "contactNo",
      value: formData?.contactNo,
      disabled: editProfileMenuItem ? false : true,
      handleChange: (e) => {
        const value = parseInt(e.target.value, 10);
        handleChange({
          target: {
            name: "contactNo",
            value: isNaN(value) ? "" : value,
          },
        });
      },
    },
    {
      label: "Experience",
      name: "experience",
      value: formData?.experience,
      disabled: editProfileMenuItem ? false : true,
      handleChange: (e) => {
        const value = parseInt(e.target.value, 10);
        handleChange({
          target: {
            name: "experience",
            value: isNaN(value) ? "" : value,
          },
        });
      },
    },
    {
      label: "Gender",
      name: "gender",
      value: formData?.gender,
      disabled: editProfileMenuItem ? false : true,
      handleChange: handleGenderChange,
    },
  ];

  return (
    <>
      <Grid item xs={12} container spacing={2} mt={1.5}>
        {inputFields.map((field, index) => (
          <Grid item xs={12} md={6} key={index}>
            <div>{field.label}</div>
            <Input
              handleChange={field.handleChange || handleChange}
              name={field.name}
              value={field.value}
              disabled={field.disabled}
              placeholder={field.placeholder || field.value}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProfileFields;
