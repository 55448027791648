import React, { useCallback, useEffect, useState } from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import {
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import { ActivateBtnWrapper, IntegrationWrapper } from "./styles/MuiStyle";
import styles from "./styles/style.module.scss";
import Button from "../../components/common/buttons/Button";
import keka from "../../assets/keka.png";
import dbf from "../../assets/dbf.png";
import Telegram from "../../assets/Telegram.png";
import { useDispatch, useSelector } from "react-redux";
import {
  activateIntegrations,
  activateIntegrationsTelegram,
  getIntegrationsData,
  getOrgIntegrationsData,
  removeIntegrationsData,
  setCalender,
  setDBF,
  setFilter,
  setInstructionsData,
  setKeka,
  setTelegram,
  updateIntegrationsData,
} from "../../redux/slices/integrationSlice";
import Dropdown from "../../components/dropdown/Dropdown";
import IntegrationModal from "./IntegrationModal";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import axios from "axios";
import { API_ROUTES } from "../../utils/contants";
import AppSecureStorage from "../../services/storage/secureStorage";
import jira from "../../assets/Jira.svg";
import googleCalender from "../../assets/googleCalender.png";
import salesForce from "../../assets/Saleforce.png";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import InstructionsDrawer from "./InstructiosDrawer";
import { Input } from "../../components/common/inputs/Input";
import { useNavigate } from "react-router-dom";
import RoleDialogBox from "./RoleDialogBox";

const storage = new AppSecureStorage();

const Integration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [activate, setActivate] = useState("");
  const [state, setState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openTelegram, setOpenTelegram] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [checkedTelegram, setCheckedTelegram] = React.useState(true);
  let permissions = JSON.parse(storage.get("permissions"));
  let editIntegrationMenuItem =
    permissions &&
    permissions["Integration"] &&
    permissions["Integration"]?.includes("Add");
  const role = storage.get("role");
  const [formData, setFormData] = useState({
    activate: checkedTelegram,
    telegramToken: "",
  });

  const { orgIntegrationList, loader, integrationList } = useSelector(
    (state) => state.integrationReducer
  );

  const handleChangeTel = (event) => {
    setCheckedTelegram(event.target.checked);
    if (!event.target.checked) {
      const body = {
        name: "telegram",
        activate: false,
      };
      dispatch(activateIntegrations({ body: body, navigate: navigate }));
    } else {
      if (event.target.checked) {
        setOpenDialog(true);
      } else {
        setOpenDialog(false);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === "1") {
      dispatch(getIntegrationsData(navigate));
    } else {
      dispatch(getOrgIntegrationsData(navigate));
    }
  }, [value, dispatch]);

  const handleActivebtn = (name) => {
    console.log("name", name);
    if (name === "google_calendar") {
      const apiUrl = `${
        API_ROUTES.BASE_URL
      }integration/google-calendar?authorization=Bearer ${storage.get(
        "token"
      )}`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${storage.get("token")}`,
          },
        })
        .then((response) => {})
        .catch((error) => {});
    } else if (name === "salesforce") {
      const apiUrl = `${
        API_ROUTES.BASE_URL
      }integration/salesForce?authorization=Bearer ${storage.get("token")}`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${storage.get("token")}`,
          },
        })
        .then((response) => {})
        .catch((error) => {});
    } else if (name === "dbf") {
      setOpen(true);
      dispatch(setDBF(true));
    } else if (name === "keka") {
      setOpen(true);
      dispatch(setDBF(false));
      dispatch(setKeka(true));
    } else if (name === "telegram") {
      setOpen(true);
      dispatch(setTelegram(true));
      dispatch(setDBF(false));
      dispatch(setKeka(false));
    }
  };

  const handleDefaultCheckbox = (data) => {
    dispatch(
      updateIntegrationsData({
        category: data.category,
        app: data.name,
        navigate: navigate,
      })
    );
  };

  const handleChangeDBFInActive = (name) => {
    dispatch(removeIntegrationsData({ name: name, navigate: navigate }));
  };

  // const handleSwitchToggle = React.useCallback(
  //   (name) => {
  //     setActivate((prevActivate) => {
  //       const newActivate =
  //         prevActivate === true ? false : prevActivate === false && true;

  //       const body = {
  //         name: name,
  //         activate: newActivate,
  //       };
  //       dispatch(activateIntegrations({ body: body, navigate: navigate }));
  //       return newActivate;
  //     });
  //   },
  //   [dispatch]
  // );

  const handleSwitchToggle = useCallback(
    (name) => {
      setActivate((prevActivate) => {
        const newActivate = !prevActivate;

        const body = {
          name: name,
          activate: newActivate,
        };

        dispatch(activateIntegrations({ body: body, navigate: navigate }));

        return newActivate;
      });
    },
    [dispatch, navigate]
  );

  const toggleDrawer = () => {
    setState(!state);
  };

  const renderOrgIntegrationSection = (category) => {
    return orgIntegrationList
      ?.filter((ele) => ele.category === category)
      .map((ele, index) => (
        <Grid item xs={12} sm={4} md={3.5} key={index} rowGap={0}>
          <h4
            style={{
              paddingBottom: "0.8rem",
            }}
          >
            {category}
          </h4>
          <div
            className={styles.cardContent}
            style={{
              background:
                ele.name === "keka" ||
                ele.name === "jira" ||
                ele.name === "telegram"
                  ? "linear-gradient(to right, #6a85b6 0%, #bac8e0 100%)"
                  : ele.name === "google_calendar" || ele.name === "salesforce"
                  ? "linear-gradient(to right, #2c3e50, #4ca1af)"
                  : ele.name === "dbf" || ele.name === "gmail"
                  ? "linear-gradient(-20deg, #616161 0%, #9bc5c3 100%)"
                  : "",
            }}
          >
            <div className={styles.cardHeader}>
              <Chip
                avatar={
                  ele.name === "keka" ? (
                    <Avatar alt="Natacha" src={keka} />
                  ) : ele.name === "dbf" ? (
                    <Avatar alt="Natacha" src={dbf} />
                  ) : ele.name === "google_calendar" ? (
                    <Avatar alt="Natacha" src={googleCalender} />
                  ) : ele.name === "jira" ? (
                    <Avatar alt="Natacha" src={jira} />
                  ) : ele.name === "salesforce" ? (
                    <Avatar alt="Natacha" src={salesForce} />
                  ) : ele.name === "telegram" ? (
                    <Avatar alt="Natacha" src={Telegram} />
                  ) : ele.name === "gmail" ? (
                    // <Avatar alt="Natacha" src={jira} />
                    <EmailIcon style={{ color: "red" }} />
                  ) : (
                    ""
                  )
                }
                label={
                  <div>
                    <h4
                      style={{
                        textTransform: "capitalize",
                        color: "white",
                      }}
                    >
                      {ele.name.toUpperCase()}:
                      <span className={styles.website}>{ele.website}</span>
                    </h4>
                  </div>
                }
                variant="outlined"
              />

              {role === "admin" && ele.name === "telegram" ? (
                <Switch
                  checked={checkedTelegram}
                  onChange={handleChangeTel}
                  inputProps={{ "aria-label": "controlled" }}
                />
              ) : (
                role === "admin" && (
                  <FormGroup onChange={() => handleSwitchToggle(ele.name)}>
                    <FormControlLabel
                      control={<Switch checked={ele.activated} />}
                    />
                  </FormGroup>
                )
              )}
            </div>

            {ele.name === "gmail" ? (
              ""
            ) : (
              <p className={styles.cardText}>
                {ele?.actions.map((ele, index) => (
                  <ul>
                    <li
                      key={index}
                      style={{ margin: "5px", marginLeft: "20px" }}
                    >
                      {ele.charAt(0).toUpperCase() + ele.slice(1)}
                    </li>
                  </ul>
                ))}
              </p>
            )}
            {ele?.actions.map(
              (ele, index) =>
                ele?.actions && (
                  <ul>
                    <li
                      key={index}
                      style={{ margin: "5px", marginLeft: "45px" }}
                    >
                      {ele.charAt(0).toUpperCase() + ele.slice(1)}
                    </li>
                  </ul>
                )
            )}
          </div>
        </Grid>
      ));
  };

  const categories = [
    "HRMS",
    "Events Management",
    "EMAIL",
    "Project Management",
    "CRM",
    "Chat Platform",
    "Resource Management",
  ];

  const renderKickStarterSection = (ele) => {
    const handleKickStarterClick = () => {
      dispatch(setInstructionsData(ele));
    };

    return (
      <Button
        variant="contained"
        size="small"
        // onClick={handleKickStarterClick}
        onClick={() => {
          toggleDrawer();
          handleKickStarterClick();
        }}
        className={styles.kick_starter}
      >
        Kick-starter
      </Button>
    );
  };

  const renderCategory = (category) => (
    <div key={category}>
      <h4
        style={{
          paddingBottom: "0.8rem",
          paddingLeft: "1.5rem",
          paddingTop: "2rem",
        }}
      >
        {integrationList && category}
      </h4>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 10 }}
        rowGap={0}
        columnGap={15}
      >
        {integrationList
          ?.filter((ele) => ele.category === category)
          .map((ele, index) => (
            <Grid item xs={12} sm={4} md={3.3} key={index} rowGap={0}>
              <div
                className={styles.cardContent}
                style={{
                  background:
                    ele.name === "keka" ||
                    ele.name === "jira" ||
                    ele.name === "telegram" ||
                    ele.name === "gmail"
                      ? "linear-gradient(to right, #6a85b6 0%, #bac8e0 100%)"
                      : ele.name === "google_calendar" ||
                        ele.name === "salesforce"
                      ? "linear-gradient(to right, #2c3e50, #4ca1af)"
                      : ele.name === "dbf"
                      ? "linear-gradient(-20deg, #616161 0%, #9bc5c3 100%)"
                      : "",
                }}
              >
                <div className={styles.cardHeader}>
                  <Chip
                    avatar={
                      ele.name === "keka" ? (
                        <Avatar alt="Natacha" src={keka} />
                      ) : ele.name === "dbf" ? (
                        <Avatar alt="Natacha" src={dbf} />
                      ) : ele.name === "google_calendar" ? (
                        <Avatar alt="Natacha" src={googleCalender} />
                      ) : ele.name === "jira" ? (
                        <Avatar alt="Natacha" src={jira} />
                      ) : ele.name === "salesforce" ? (
                        <Avatar alt="Natacha" src={salesForce} />
                      ) : ele.name === "telegram" ? (
                        <Avatar alt="Natacha" src={Telegram} />
                      ) : ele.name === "gmail" ? (
                        // <Avatar alt="Natacha" src={jira} />
                        <EmailIcon style={{ color: "red" }} />
                      ) : (
                        ""
                      )
                    }
                    label={
                      <div>
                        <h4
                          style={{
                            textTransform: "capitalize",
                            color: "white",
                          }}
                        >
                          {ele.name === "google_calendar"
                            ? "Google Calendar"
                            : ele.name.toUpperCase()}
                          :<span className={styles.website}>{ele.website}</span>
                        </h4>
                      </div>
                    }
                    variant="outlined"
                  />
                  {role === "employee" && (
                    <div className={styles.set_default_checkbox}>
                      <Checkbox
                        checked={ele.isDefault === true ? true : false}
                        onChange={() => handleDefaultCheckbox(ele)}
                      />
                      <span>Set as default</span>
                    </div>
                  )}
                </div>

                {ele.name === "gmail" ? (
                  ""
                ) : (
                  <p className={styles.cardText}>
                    {ele?.actions.map((ele, index) => (
                      <ul>
                        <li
                          key={index}
                          style={{ margin: "5px", marginLeft: "20px" }}
                        >
                          {ele.charAt(0).toUpperCase() + ele.slice(1)}
                        </li>
                      </ul>
                    ))}
                  </p>
                )}
                <Button
                  className={
                    role === "employee" ? styles.cardFooter : styles.cardFooter1
                  }
                  style={{
                    backgroundColor:
                      ele.name === "keka" ||
                      ele.name === "jira" ||
                      ele.name === "telegram" ||
                      ele.name === "gmail"
                        ? "#9dafd0"
                        : ele.name === "google_calendar" ||
                          ele.name === "salesforce"
                        ? "#3b7585"
                        : ele.name === "dbf"
                        ? "#89a6a5"
                        : "",
                  }}
                  size="small"
                  fullWidth
                  variant="contained"
                >
                  {/* {role === "employee" && (
                    <div className={styles.set_default_checkbox}>
                      <Checkbox
                        checked={ele.isDefault === true ? true : false}
                        onChange={() => handleDefaultCheckbox(ele)}
                      />
                      <span>Set as default</span>
                    </div>
                  )} */}
                  {editIntegrationMenuItem && (
                    <ActivateBtnWrapper isActivated={ele.isActivated}>
                      {ele.isActivated ? (
                        <div className={styles.activateBtn}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleChangeDBFInActive(ele.name)}
                          >
                            Disconnect
                          </Button>
                          <div className={styles.kick_starter_disconnect}>
                            {renderKickStarterSection(ele)}
                          </div>
                        </div>
                      ) : ele.name == "google_calendar" ? (
                        <div
                          className={
                            // role === "employee"
                            //   ?
                            styles.ancher_tab_emp
                            // : styles.ancher_tab
                          }
                        >
                          <a
                            href={
                              API_ROUTES.BASE_URL +
                              "integration/google-calendar?authorization=Bearer " +
                              storage.get("token")
                            }
                          >
                            Activate
                          </a>
                          {renderKickStarterSection(ele)}
                        </div>
                      ) : ele.name == "jira" ? (
                        <div
                          className={
                            // role === "employee"
                            //   ?
                            styles.ancher_tab_emp
                            // : styles.ancher_tab
                          }
                        >
                          <a
                            href={
                              API_ROUTES.BASE_URL +
                              "integration/jira?authorization=Bearer " +
                              storage.get("token")
                            }
                          >
                            Activate
                          </a>
                          {renderKickStarterSection(ele)}
                        </div>
                      ) : ele.name == "salesforce" ? (
                        <div
                          className={
                            // role === "employee"
                            //   ?
                            styles.ancher_tab_emp
                            // : styles.ancher_tab
                          }
                        >
                          <a
                            href={
                              API_ROUTES.BASE_URL +
                              "integration/salesForce?authorization=Bearer " +
                              storage.get("token")
                            }
                          >
                            Activate
                          </a>
                          {renderKickStarterSection(ele)}
                        </div>
                      ) : (
                        <div
                          className={
                            ele.name !== "gmail"
                              ? styles.activateBtn
                              : styles.gmailActivateBtn
                          }
                        >
                          {ele.name !== "gmail" && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleActivebtn(ele.name)}
                            >
                              Activate
                            </Button>
                          )}
                          {renderKickStarterSection(ele)}
                        </div>
                      )}
                    </ActivateBtnWrapper>
                  )}
                </Button>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );

  const handleChangeTelegram = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmtTelegram = () => {
    dispatch(
      activateIntegrationsTelegram({ body: formData, navigate: navigate })
    );
    setOpenDialog(false);
  };

  return (
    <IntegrationWrapper>
      <div>
        <ChatNav title={"Integration"} />
        <Card>
          <div className={styles.integration_wrapper}>
            <CssBaseline />
            <Box>
              {role === "admin" && (
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                    className={styles.box}
                  >
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Your apps" value="1" />
                      <Tab label="Organization apps" wrapped value="2" />
                    </TabList>
                  </Box>
                </TabContext>
              )}
            </Box>
            {loader ? (
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress disableShrink />
              </Container>
            ) : value === "1" ? (
              <Box paddingTop={5}>
                {categories
                  .filter((category) =>
                    integrationList.some((ele) => ele.category === category)
                  )
                  .map((category) => renderCategory(category))}
              </Box>
            ) : (
              role === "admin" &&
              orgIntegrationList.length > 0 && (
                <Box paddingTop={5}>
                  {[
                    "HRMS",
                    "EMAIL",
                    "Events Management",
                    "CRM",
                    "Chat Platform",
                    "Resource Management",
                  ].map((category, index) => (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 12, sm: 12, md: 12 }}
                      rowGap={0}
                      columnGap={15}
                      paddingTop={index === 0 ? 0 : 3}
                      paddingBottom={index === 4 ? 2 : 0}
                      key={index}
                    >
                      {renderOrgIntegrationSection(category)}
                    </Grid>
                  ))}
                </Box>
              )
            )}
          </div>
        </Card>
      </div>
      <InstructionsDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        setState={setState}
      />
      <IntegrationModal
        open={open}
        setOpen={setOpen}
        isActivated={integrationList.isActivated}
      />
      {value === "2" && (
        <Dialog
          fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle
            id="alert-dialog-title"
            textAlign="center"
            color="#8176f2"
          >
            Telegram
          </DialogTitle>
          <DialogContent>
            <Input
              value={formData.telegramToken}
              name="telegramToken"
              handleChange={handleChangeTelegram}
              placeholder="Please enter telegram token here"
              required
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              textTransform: "capitalize",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
            }}
          >
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
            <Button onClick={handleSubmtTelegram}>Submit</Button>
          </DialogActions>
        </Dialog>
      )}
      <RoleDialogBox />
    </IntegrationWrapper>
  );
};

export default Integration;
