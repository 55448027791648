import { ThemeProvider, createTheme } from "@mui/material";

const EditRolesWrapper = ({ children }) => {
  const EditRoles_theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "start",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "0px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#8176f2 !important",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: "0px !important",
            height: "74vh !important",
            borderBottom: "none !important",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: "#202346 !important",
            color: "white !important",
            boxShadow: "none !important",
            border: "0px solid #202346 !important",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td, &:last-child th": { border: 0 },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none !important",
            borderBottom: "1px solid #e0e0e0",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={EditRoles_theme}>{children}</ThemeProvider>;
};

export { EditRolesWrapper };
