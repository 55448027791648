import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./styles/modal.module.scss";
import { ActiveWrapper } from "./styles/MuiStyles";
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationData } from "../../redux/slices/organizationSlice";
import {
  addNewUser,
  getAllPermissions,
  setAddUserRoleData,
  setIsActive,
} from "../../redux/slices/userManagementSilce";
import { Input } from "../common/inputs/Input";
import { useNavigate } from "react-router-dom";

const CommanModal = ({
  open,
  setOpen,
  addNewRole,
  setAddNewRole,
  userData,
  setUserData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orgData, setOrgData] = useState({
    name: "",
    domain: "",
    admin: "",
  });

  const { page, limit, filterValue } = useSelector(
    (state) => state.userManagementReducer
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleNext = () => {
    dispatch(setAddUserRoleData(userData));
    dispatch(setIsActive(true));
    handleClose();
    dispatch(getAllPermissions(navigate));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setOrgData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeRole = (e) => {
    e.preventDefault();
    setAddNewRole((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/organization") {
      dispatch(addOrganizationData(orgData, navigate));
      setOrgData({
        name: "",
        domain: "",
        admin: "",
      });
    } else if (window.location.pathname === "/userManagement") {
      dispatch(
        addNewUser({
          body: userData,
          navigate: navigate,
          page: page,
          limit: limit,
          filterValue: filterValue.toLowerCase(),
        })
      );
      setUserData({
        email: "",
        roleId: "",
      });
    }
    handleClose();
  };

  const [modalData, setModalData] = useState({
    title: "",
    buttonText: "",
    buttonHandler: handleNext, // Set a default handler
  });

  const allAvailableRoles = useSelector(
    (state) => state.userManagementReducer.getRolesData
  );

  useEffect(() => {
    switch (window.location.pathname) {
      case "/editRoles":
        setModalData({
          title: "Add a new role",
          buttonText: "Next",
          buttonHandler: handleNext,
        });
        break;
      case "/organization":
        setModalData({
          title: "Add new Organization",
          buttonText: "Next",
          buttonHandler: onSubmit,
        });
        break;
      case "/userManagement":
        setModalData({
          title: "Add a new user",
          buttonText: "Submit",
          buttonHandler: onSubmit,
        });
        break;
      default:
        break;
    }
  }, [window.location.pathname, userData]);

  return (
    <ActiveWrapper>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={onSubmit}>
          <DialogTitle className={styles.header}>{modalData.title}</DialogTitle>
          <DialogContent>
            {window.location.pathname === "/organization" ? (
              <>
                <div style={{ marginTop: "10px" }}>Enter Name</div>
                <Input
                  value={orgData.name}
                  name="name"
                  handleChange={handleChange}
                  placeholder="Type here"
                  required
                />
                <div style={{ marginTop: "10px" }}>Enter Domain</div>
                <Input
                  value={orgData.domain}
                  name="domain"
                  handleChange={handleChange}
                  placeholder="Type here"
                  required
                />
                <div style={{ marginTop: "10px" }}>Company Email</div>
                <Input
                  value={orgData.admin}
                  name="admin"
                  handleChange={handleChange}
                  placeholder="Type here"
                  required
                />
              </>
            ) : window.location.pathname === "/editRoles" ? (
              <>
                <div style={{ marginTop: "10px" }}>Role Name</div>
                <Input
                  value={addNewRole.name}
                  name="name"
                  handleChange={handleChangeRole}
                  placeholder="Type here"
                  required
                />
              </>
            ) : window.location.pathname === "/userManagement" ? (
              <>
                <div style={{ marginTop: "10px" }}>Email</div>
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <TextField
                    name="email"
                    value={userData.email}
                    onChange={handleUserDataChange}
                  />
                </FormControl>
                <div style={{ marginTop: "10px" }}>Name</div>
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <TextField
                    name="name"
                    value={userData.name}
                    onChange={handleUserDataChange}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: "25px" }}>
                  <div style={{ margin: "10px 0px" }}>Select Role</div>
                  <Select
                    name="roleId"
                    value={userData.roleId}
                    onChange={handleUserDataChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    {allAvailableRoles?.data?.map((role) => (
                      <MenuItem
                        key={role._id}
                        value={role._id}
                        sx={{ color: "#000 !important" }}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : null}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              background:
                window.location.pathname === "/editRoles"
                  ? !addNewRole.name
                    ? "#c4c4c4"
                    : "#8176f2"
                  : !userData.email || !userData.roleId || !userData.name
                  ? "#c4c4c4"
                  : "#8176f2",
              margin: "1.5rem",
              borderRadius: "5px",
              cursor:
                window.location.pathname === "/editRoles"
                  ? !addNewRole.name
                    ? "not-allowed"
                    : "pointer"
                  : !userData.email || !userData.roleId || !userData.name
                  ? "not-allowed"
                  : "pointer",
              color: "white",
              padding: "15px 0px",
            }}
            onClick={modalData.buttonHandler}
          >
            {modalData.buttonText}
          </DialogActions>
        </form>
      </Dialog>
    </ActiveWrapper>
  );
};

export default CommanModal;
