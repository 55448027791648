// src/redux/chatSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import EditRoleService from "../../services/api/editRoleService";
import { setLoading } from "./global";
import { unauthorizedError } from "../../utils/helpers";

const editRole = new EditRoleService();

export const getRolesData = createAsyncThunk(
  `/admin/role`,
  async ({ page, limit, navigate }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await editRole.getRolesData({ page, limit, navigate });
      dispatch(setLoading(false));
      return response;
    } catch (error) {
      if (error?.response) {
        // unauthorizedError(navigate);
      } else {
        // toast.error("Something went wrong");
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

//add new role api integration
export const addNewRoleData = createAsyncThunk(
  "/admin/role/",
  async ({ body, page, limit, navigate }, { dispatch }) => {
    try {
      const response = await editRole.addNewRole(body, page, limit, navigate);
      dispatch(getRolesData({ page, limit, navigate }));
      toast.success("Add new role successfully");
      return response;
    } catch (error) {
      if (error?.response) {
        unauthorizedError(navigate);
      } else {
        toast.error(
          error?.data?.errors ? error?.data?.errors : error?.data?.message
        );
      }

      throw error?.data?.message;
    }
  }
);

export const editRoleData = createAsyncThunk(
  "/admin/editrole/",
  async ({ body, id, page, limit, navigate }, { dispatch }) => {
    try {
      const response = await editRole.editRole({
        body,
        id,
        page,
        limit,
        navigate,
      });
      dispatch(getRolesData({ page, limit, navigate }));
      toast.success("edit role successfully");
      return response;
    } catch (error) {
      if (error?.response && error?.response.status === 401) {
        unauthorizedError(navigate);
      } else {
        toast.error(
          error?.data?.errors ? error?.data?.errors : error?.data?.message
        );
      }
      throw error?.data?.message;
    }
  }
);

const editRoleSlice = createSlice({
  name: "editRoles",
  initialState: {
    loading: false,
    roles: [],
    selectedRoleData: null,
    renderallCustomData: null,
    page: 1,
    limit: 10,
  },
  reducers: {
    setSelectedRoleData: (state, action) => {
      state.selectedRoleData = action.payload;
    },
    clearedSelectedRole: (state, action) => {
      state.selectedRoleData = null;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRolesData.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
        state.renderallCustomData = action.payload;
      })
      .addCase(getRolesData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addNewRoleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewRoleData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewRoleData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(editRoleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(editRoleData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editRoleData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setSelectedRoleData, clearedSelectedRole, setPage, setLimit } =
  editRoleSlice.actions;

export default editRoleSlice.reducer;
