import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ButtonPdf from "@mui/material/Button";
import { AddTrainWrapper, UploadPdfButton } from "../styles/MuiStyle";
import DialogTitle from "@mui/material/DialogTitle";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Box,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Button from "../../../components/common/buttons/Button";
import TrainContentModal from "./TrainContentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenAddTrainMoadl,
  setRoleData,
} from "../../../redux/slices/trainAndContentSlice";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../styles/style.module.scss";
import { Input } from "../../../components/common/inputs/Input";
import { getRoles } from "../../../redux/slices/userManagementSilce";
import { useNavigate } from "react-router-dom";
import RoleDropdown from "./RoleDropdown";

export default function AddTrainModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openTrainModal, setOpenTrainModal] = useState(false);
  const [selected, setValue] = useState(null);
  const { openAddTrainMoadl, activeButton, roleData } = useSelector(
    (state) => state.trainAndContentReducer
  );

  const { pageRole, limitRole } = useSelector(
    (state) => state.userManagementReducer
  );
  const [uploadURL, setUploadURL] = useState({
    url: "",
    urlFileName: "",
    role: roleData,
  });
  const [uploadTextData, setUploadTextData] = useState({
    text: "",
    textFileName: "",
    role: roleData,
  });

  const handleOptionChange = (option) => {
    setSelectedOptions([option]);
    setValue(option);
  };

  const addUserAvatar = (e) => {
    setFileType("pdf");
    const reader = new FileReader();
    if (e.target.files[0]) {
      setAttachment(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setUploadFile(readerEvent.target.result);
    };
  };
  const handleClose = () => {
    dispatch(setOpenAddTrainMoadl(false));
    setOpenTrainModal(false);
  };
  const handleNext = () => {
    if (selectedOptions?.length === 0) {
    } else if (
      (selectedOptions.includes("text") &&
        (!uploadTextData.textFileName || !uploadTextData.text)) ||
      !uploadTextData.role
    ) {
    } else if (
      (selectedOptions.includes("url") &&
        (!uploadURL.url || !uploadURL.urlFileName)) ||
      !uploadURL.role
    ) {
    } else if (selectedOptions.includes("pdf") && !attachment) {
    } else {
      dispatch(setOpenAddTrainMoadl(false));
      setOpenTrainModal(true);
    }
  };
  const handleUploadURL = (event) => {
    setUploadURL({ ...uploadURL, [event.target.name]: event.target.value });
  };
  const handleUploadText = (event) => {
    setUploadTextData({
      ...uploadTextData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (selectedOptions.includes("text")) {
      dispatch(setRoleData([]));
    }
    if (selectedOptions.includes("url")) {
      dispatch(setRoleData([]));
    }
    if (selectedOptions.includes("pdf")) {
      dispatch(setRoleData([]));
    }
    dispatch(
      getRoles({ page: pageRole, limit: limitRole, navigate: navigate })
    );
    dispatch(setRoleData([]));
  }, [activeButton, pageRole, limitRole, selectedOptions]);

  return (
    <AddTrainWrapper>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openAddTrainMoadl}
        onClose={handleClose}
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle
            sx={{
              color: "#8176f2",
              textAlign: "center",
              width: "90%",
              position: "relative",
              right: "24px",
            }}
          >
            Train from content
          </DialogTitle>
          <CloseIcon className={styles.cross_icon_btn} onClick={handleClose} />
        </Box>
        <DialogContent>
          <DialogContentText>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  dense
                  button
                  onClick={() => handleOptionChange("text")}
                >
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text" />
                  <Radio
                    checked={selectedOptions.includes("text")}
                    onChange={() => handleOptionChange("text")}
                  />
                </ListItemButton>
              </ListItem>
              {selectedOptions.includes("text") && (
                <Box
                  style={{
                    zIndex: "1",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Box ml="10px" mt="25px">
                    Please Enter text file name
                  </Box>
                  <Input
                    type="text"
                    handleChange={handleUploadText}
                    name="textFileName"
                    value={uploadTextData.textFileName}
                    placeholder="Enter text name here"
                  />
                  <Box ml="10px" mt="25px">
                    Please Select role here
                  </Box>
                  <RoleDropdown />
                  <Box ml="10px" mt="25px">
                    Please Enter description here
                  </Box>
                  <FormControl sx={{ m: 0.5 }} fullWidth variant="outlined">
                    <textarea
                      type="text"
                      name="text"
                      value={uploadTextData.text}
                      onChange={handleUploadText}
                      placeholder="Enter text"
                      style={{
                        padding: "10px",
                        height: "200px",
                        borderRadius: "5px",
                        border: "1px solid #c4c4c4",
                      }}
                    />
                  </FormControl>
                </Box>
              )}
              <ListItem disablePadding sx={{ marginTop: "25px" }}>
                <ListItemButton
                  dense
                  button
                  onClick={() => handleOptionChange("url")}
                >
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="URL" />
                  <Radio
                    checked={selectedOptions.includes("url")}
                    onChange={() => handleOptionChange("url")}
                  />
                </ListItemButton>
              </ListItem>
              {selectedOptions.includes("url") && (
                <Box
                  style={{
                    zIndex: "1",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Box ml="10px" mt="25px">
                    Please Enter URL name
                  </Box>

                  <Input
                    type="text"
                    handleChange={handleUploadURL}
                    name="urlFileName"
                    value={uploadURL.urlFileName}
                    placeholder="Enter URL name here"
                  />
                  <Box ml="10px" mt="25px">
                    Please Select role here
                  </Box>
                  <RoleDropdown />
                  <Box ml="10px" mt="25px">
                    Please Enter URL
                  </Box>
                  <Input
                    type="text"
                    handleChange={handleUploadURL}
                    name="url"
                    value={uploadURL.url}
                    placeholder="Enter URL"
                  />
                </Box>
              )}

              <ListItem disablePadding sx={{ marginTop: "25px" }}>
                <ListItemButton
                  dense
                  button
                  onClick={() => handleOptionChange("pdf")}
                >
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="PDF" />
                  <Radio
                    checked={selectedOptions.includes("pdf")}
                    onChange={() => handleOptionChange("pdf")}
                  />
                </ListItemButton>
              </ListItem>
              {selectedOptions.includes("pdf") && (
                <Box style={{ width: "100%" }}>
                  <Box ml="10px" mt="25px">
                    Please Select role here
                  </Box>
                  <RoleDropdown />
                  <Box ml="8px" mt="25px" mb="2px">
                    Please Upload PDF
                  </Box>
                  <UploadPdfButton>
                    <ButtonPdf
                      style={{
                        backgroundColor: "white",
                        right: "1px",
                        width: "100%",
                      }}
                    >
                      <input
                        type="file"
                        id="file-input"
                        name="file-input"
                        style={{ display: "none" }}
                        onChange={addUserAvatar}
                        accept=".pdf"
                      />

                      <label
                        style={{ width: "100%" }}
                        id="file-input-label"
                        for="file-input"
                      >
                        {uploadFile ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{attachment?.name}</div>
                            <div style={{ color: "green" }}>
                              <UploadFileIcon />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Attach or browser pdf</div>
                            <div>
                              <UploadFileIcon />
                            </div>
                          </div>
                        )}
                      </label>
                    </ButtonPdf>
                  </UploadPdfButton>
                </Box>
              )}
            </List>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          className={
            selectedOptions?.length === 0
              ? styles.next_btn_addTrain_dis
              : styles.next_btn_addTrain && selectedOptions.includes("text")
              ? !uploadTextData.textFileName ||
                !uploadTextData.text ||
                !uploadTextData.role
                ? styles.next_btn_addTrain_dis
                : styles.next_btn_addTrain
              : selectedOptions.includes("url")
              ? !uploadURL.url || !uploadURL.urlFileName || !uploadURL.role
                ? styles.next_btn_addTrain_dis
                : styles.next_btn_addTrain
              : selectedOptions.includes("pdf") &&
                (!attachment || !roleData.length > 0)
              ? styles.next_btn_addTrain_dis
              : styles.next_btn_addTrain
          }
        >
          <Button variant="contained" size="large" onClick={handleNext}>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <TrainContentModal
        filename={attachment?.name}
        open={openTrainModal}
        setAttachment={setAttachment}
        setUploadFile={setUploadFile}
        uploadURL={uploadURL}
        setUploadURL={setUploadURL}
        fileType={fileType}
        file={attachment}
        closeField={setValue}
        selectedOptions={selectedOptions}
        uploadTextData={uploadTextData}
        setUploadTextData={setUploadTextData}
        setOpenTrainModal={setOpenTrainModal}
        setSelectedOptions={setSelectedOptions}
        setValue={setValue}
      />
    </AddTrainWrapper>
  );
}
