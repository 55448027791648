import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const DropdownWrapper = ({ children }) => {
  const dropdown_theme = createTheme({
    components: {
      select: {
        "&:before": {
          borderColor: "1px solid red !important",
        },
        "&:after": {
          borderColor: "1px solidblack !important",
        },
      },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            paddingTop: "2px !important",
            paddingBottom: "2px !important",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "0px !important",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            color: "white !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={dropdown_theme}>{children}</ThemeProvider>;
};

export { DropdownWrapper };
