import React, { useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import styles from "../../styles/style.module.scss";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TrainFromContentWrapper } from "../../styles/MuiStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditOpenTestingApi,
  setEditableData,
  setStoreEntitiesArray,
  setStorePromts,
} from "../../../../redux/slices/trainAndContentSlice";
import { TrainedData } from "../TrainedData";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EditApplyLeave = () => {
  const dispatch = useDispatch();
  let pairsObject = {};
  const {
    getactionName,
    getPrompts,
    singleDataResponse,
    selectedTrainedState,
    trainedData,
    actionState,
    openCustomAction,
  } = useSelector((state) => state.trainAndContentReducer);
  const [value, setValue] = React.useState("1");
  const [rowsPrompt, setRowsPrompts] = React.useState([""]);
  const [rows, setRows] = React.useState(
    singleDataResponse && singleDataResponse.entities
      ? Object.entries(singleDataResponse.entities).map(
          ([name, description]) => ({ name, description })
        )
      : []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addRowPrompts = () => {
    setRowsPrompts([...rowsPrompt, ""]);
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const deleteRowsPrompt = (index) => {
    const updatedPrompts = [...rowsPrompt];
    updatedPrompts.splice(index, 1);
    setRowsPrompts(updatedPrompts);
  };

  const addRow = () => {
    const lastRow = rows[rows.length - 1];
    const newRow = {
      [lastRow?.name]: lastRow?.designation,
    };
    setRows([...rows, newRow]);
  };

  const handleRowChange = (index, field, newValue) => {
    const updatedRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        const updatedField = field === "name" ? newValue : row.description;
        const updatedKey = field === "name" ? newValue : Object.keys(row)[0];
        return {
          [updatedKey]: updatedField,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRowChangePrompts = (index, newValue) => {
    const updatedPrompts = [...rowsPrompt];
    if (newValue.trim() === "") {
      updatedPrompts.splice(index, 1);
    } else {
      updatedPrompts[index] = newValue.trim();
    }
    setRowsPrompts(updatedPrompts);

    // Filter out null or empty prompts
    const promptStrings = updatedPrompts.filter(
      (prompt) => prompt.trim() !== ""
    );
    dispatch(setStorePromts(promptStrings));
  };

  rows?.forEach((item) => {
    const keys = Object.keys(item);
    keys.forEach((key) => {
      pairsObject[key] = item[key];
    });
  });
  const handleNext = () => {
    const data = {
      name: getactionName,
      prompts: getPrompts,
      entities: pairsObject,
    };
    dispatch(setStoreEntitiesArray(rows));
    dispatch(setEditableData(data));
    dispatch(setEditOpenTestingApi(true));
  };

  React.useEffect(() => {
    if (!singleDataResponse) {
      return;
    }
    if (!singleDataResponse.prompts) {
      return;
    }
    if (!Array.isArray(singleDataResponse.prompts)) {
      return;
    }

    // Map over the prompts and setRowsPrompts with the new value
    let promptData = singleDataResponse.prompts.map((ele) => ele);
    setRowsPrompts([...promptData]);
  }, [singleDataResponse, setRowsPrompts, setRows]);

  useEffect(() => {}, [selectedTrainedState, trainedData, openCustomAction]);

  return (
    <TrainFromContentWrapper>
      {selectedTrainedState ? (
        trainedData && <TrainedData />
      ) : openCustomAction ? (
        <TrainedData />
      ) : (
        actionState && (
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              marginTop: "70px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Add Manually"
                    value="1"
                    style={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    label="Upload"
                    value="2"
                    style={{ textTransform: "capitalize" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div>Enter prompts</div>
                  <Button onClick={addRowPrompts} className={styles.add_btn}>
                    Add row
                  </Button>
                </div>

                {rowsPrompt.map((row, index) => (
                  <Box key={index} display="flex" gap="20px" marginTop="10px">
                    <TextField
                      placeholder="How To Apply Leave ?"
                      fullWidth
                      value={row}
                      onChange={(e) =>
                        handleRowChangePrompts(index, e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DeleteIcon
                              className={styles.delete_icon}
                              onClick={() => deleteRowsPrompt(index)}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ))}
                <div>
                  <Divider
                    style={{
                      margin: "20px 0px",
                      height: "2px",
                      border: "1px solid #e0e0e0",
                      background: "#e0e0e0",
                    }}
                  ></Divider>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>Enter entities</div>
                    <Button onClick={addRow} className={styles.add_btn}>
                      Add row
                    </Button>
                  </div>
                  {rows &&
                    rows.map((row, index) => (
                      <Box
                        key={index}
                        display="flex"
                        gap="20px"
                        marginTop="10px"
                      >
                        <TextField
                          className={styles.api_date_text}
                          placeholder="Enter entity name"
                          name="name"
                          value={row?.name}
                          onChange={(e) =>
                            handleRowChange(index, "name", e.target.value)
                          }
                        />
                        <TextField
                          placeholder="Enter entity description"
                          fullWidth
                          name="designation"
                          value={row?.description}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DeleteIcon
                                  className={styles.delete_icon}
                                  onClick={() => deleteRow(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "designation",
                              e.target.value
                            )
                          }
                        />
                      </Box>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div>
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "39px",
                        flexWrap: "wrap",
                        backgroundImage: "linear-gradient(#e8e3f5, #e0e8f4)",
                        padding: "20px",
                        borderRadius: "5px",
                        width: "75%",
                      }}
                    >
                      <Box mt={1}>Drag and Drop your file here</Box>
                      <Box mt={1}>or</Box>
                      <Button
                        style={{ padding: "12px 35px" }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput type="file" />
                      </Button>
                    </div>
                  </Container>
                  <Divider
                    style={{
                      margin: "20px 0px",
                      height: "1px",
                      border: "1px solid #e0e0e0",
                      background: "#e0e0e0",
                    }}
                  ></Divider>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>Enter entities</div>
                      <Button onClick={addRow} className={styles.add_btn}>
                        Add row
                      </Button>
                    </div>
                    {rows &&
                      rows.map((row, index) => (
                        <Box
                          key={index}
                          display="flex"
                          gap="20px"
                          marginTop="10px"
                        >
                          <TextField
                            className={styles.api_date_text}
                            placeholder="Enter entity name"
                            name="name"
                            onChange={(e) =>
                              handleRowChange(index, "name", e.target.value)
                            }
                          />
                          <TextField
                            placeholder="Enter entity description"
                            fullWidth
                            name="designation"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DeleteIcon
                                    className={styles.delete_icon}
                                    onClick={() => deleteRow(index)}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              handleRowChange(
                                index,
                                "designation",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      ))}
                  </div>
                </div>
              </TabPanel>
            </TabContext>
            <Container>
              <Button
                className={styles.next_btn_txt_enable}
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Box>
        )
      )}
    </TrainFromContentWrapper>
  );
};

export default EditApplyLeave;
