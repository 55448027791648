import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["", "", ""];

export default function ApplyLeaveStepper({ step1, step2, step3, value }) {
  let stepsvalue = step1 ? 0 : step2 ? 1 : step3 ? 2 : 0;
  return (
    <Box sx={{ width: "50%" }}>
      <Stepper activeStep={stepsvalue} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
