import React, { useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  List,
  OutlinedInput,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import styles from "./styles/style.module.scss";
import Button from "../../components/common/buttons/Button";
import io from "socket.io-client";
import {
  addDateAndTime,
  formatTime,
  unauthorizedError,
} from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatData,
  setFeedbackStatus,
  setJobId,
  setMessages,
  setNewMessage,
  setSocket,
} from "../../redux/slices/chatSlice";
import HorizontalLoader from "../../components/loader/verticalLoader/VerticalLoader";
import AppSecureStorage from "../../services/storage/secureStorage";
import { toast } from "react-toastify";
import googleCalender from "../../assets/googleCalender.png";
import dbf from "../../assets/dbf.png";
import keka from "../../assets/keka.png";
import jira from "../../assets/Jira.svg";
import salesForce from "../../assets/Saleforce.png";
import { API_ROUTES } from "../../utils/contants";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import sound from "../../assets/chatSound.mp3";
import { useNavigate } from "react-router-dom";
import { Feedback } from "./Feedback";
let socket;
const storage = new AppSecureStorage();

const Messages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messagesRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [varaib, setVaraib] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [shouldScrollSuggestions, setShouldScrollSuggestions] = useState(false);
  const [askForFeedback, setAskForFeedback] = useState(false);
  const { messages, newMessage } = useSelector((state) => state.chatReducer);
  const isLoading = useSelector((state) => state.chatReducer.loading);
  const linkUrl = addDateAndTime(messages);
  const latestMessage = messages[messages.length - 1];

  useEffect(() => {
    socket = io(
      window.location.origin === "https://app.simplifypath.com"
        ? "https://api.simplifypath.com/"
        : "https://staging-api.simplifypath.com/",
      {
        query: {
          userId: storage.get("id"),
          source: "simplifypath_standalone_app",
        },
      }
    );
    dispatch(setSocket(socket));
    socket.on("bot_reply", (newMessage) => {
      dispatch(setJobId(newMessage?.jobId));
      setAskForFeedback(newMessage?.askForFeedback);
      newMessage.timestamp = new Date().toISOString();
      dispatch(setMessages(newMessage));
      setLoading(false);
      setVaraib("");
      playSendMessageSound();
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    setShouldScrollSuggestions(suggestions.length > 5);
    const handleClickOutside = (event) => {
      if (suggestions.length > 0) {
        const suggestionsContainer = document.getElementById(
          "suggestions-container"
        );
        if (
          suggestionsContainer &&
          !suggestionsContainer.contains(event.target)
        ) {
          // Click occurred outside of suggestions, close the suggestions
          setSuggestions([]);
          setShowSuggestions(false);
        }
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [suggestions]);

  const fetchSuggestions = async (keyword) => {
    try {
      if (keyword.trim() !== "") {
        const response = await axios.get(
          `${API_ROUTES.BASE_URL}chat/suggestion/${keyword}`,
          {
            headers: {
              Authorization: `Bearer ${storage.get("token")}`,
            },
          }
        );
        setSuggestions(response.data);
        setShowSuggestions(true);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } catch (error) {
      if (error?.response && error?.response.status === 401) {
        unauthorizedError(navigate);
      }
    }
  };

  const sendMessage = (message) => {
    setLoading(true);
    if (socket?.connected) {
      socket.emit("user_reply", {
        message,
        sentby: "user",
        userId: storage.get("id"),
        socketId: socket.id,
        timestamp: new Date().toISOString(),
      });

      const userMessage = {
        message,
        sentby: "user",
        userId: storage.get("id"),
        timestamp: new Date().toISOString(),
      };
      dispatch(setMessages(userMessage));
      dispatch(setNewMessage(""));
      setSuggestions([]);
      setShowSuggestions(false);
      playSendMessageSound();
    } else {
      console.error("Socket is not initialized yet.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setVaraib(
        setTimeout(() => {
          dispatch(
            setMessages({
              message:
                "We are currently processing your request, and you will receive a confirmation message once its completed. In the meantime, feel free to provide any additional prompts or inquiries.",
              sentby: "bot",
              userId: storage.get("id"),
              timestamp: new Date().toISOString(),
            })
          );
          playSendMessageSound();
        }, 3000)
      );

      if (varaib) {
        setLoading(false);
      }
    } else {
      clearTimeout(varaib);
    }
  }, [loading]);

  const scrollToBottom = () => {
    messagesRef.current.scrollIntoView({ behavior: "auto" });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") {
      toast.error("Message cannot be empty");
    } else if (newMessage.includes("<") || newMessage.includes(">")) {
      toast.error("Message cannot contain '<' or '>'");
    } else {
      scrollToBottom();
      sendMessage(newMessage);
      setNewMessage("");
    }
  };

  const playSendMessageSound = () => {
    const audio = new Audio(sound);
    audio.load();
    audio.play();
  };

  const onChange = (e) => {
    const inputText = e.target.value;
    dispatch(setNewMessage(inputText));

    // Only fetch suggestions and show them if newMessage is not empty
    if (inputText.trim() !== "") {
      fetchSuggestions(inputText);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const urlify = (text) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    let replacedText = text.replace(urlRegex, function (url) {
      if (url.length > 110) {
        let trimmedUrl = url.substring(0, 110) + "...";
        return `<Tooltip title="${url}"><a href="${url}" target="_blank">${trimmedUrl}</a></Tooltip>`;
      } else {
        return `<Tooltip title="${url}"><a href="${url}" target="_blank">${url}</a></Tooltip>`;
      }
    });

    return `<div style="word-wrap: break-word;">${replacedText}</div>`;
  };
  const handleSuggestionClick = (selectedSuggestion) => {
    dispatch(setNewMessage(selectedSuggestion));
    setSuggestions([]);
    setShowSuggestions(false);
    sendMessage(selectedSuggestion);
  };

  useEffect(() => {
    scrollToBottom();
    dispatch(setFeedbackStatus(""));
  }, [messages]);

  useEffect(() => {
    dispatch(getChatData(navigate));
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <List>
        <Container maxWidth="xl">
          {!isLoading ? (
            linkUrl?.map((message, index) => {
              return (
                <Grid
                  container
                  key={index}
                  className={
                    message.sentby === "user" ? styles.sender_msg_style : null
                  }
                >
                  {message.sentby === "user" ? (
                    <Grid item md={7.5} xs={12}>
                      <div className={styles.sender_msg_style}>
                        <div
                          className={`${styles.bubble} ${styles.right}`}
                          style={{ display: "inline-block" }}
                        >
                          {message.message ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: urlify(
                                  message?.message?.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br>"
                                  )
                                ),
                              }}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className={styles.date_user}>
                          {formatTime(message.timestamp)}
                        </div>
                      </div>
                    </Grid>
                  ) : message.day ? (
                    <>
                      <div className={styles.date_time_section}>
                        <div>{message.day.replace(/,/g, "")}</div>
                      </div>
                      {message.sentby === "bot" && (
                        <Grid item md={7.5} xs={12}>
                          <div className={styles.bot_msg_style}>
                            <div className={`${styles.bubble} ${styles.left}`}>
                              {message.message ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: urlify(
                                      message?.message?.replace(
                                        /(?:\r\n|\r|\n)/g,
                                        "<br>"
                                      )
                                    ),
                                  }}
                                ></div>
                              ) : (
                                ""
                              )}
                            </div>
                            {message.timestamp && (
                              <div className={styles.date}>
                                {formatTime(message.timestamp)}
                              </div>
                            )}
                          </div>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item md={7.5} xs={12} key={index}>
                      <div className={styles.bot_msg_style}>
                        <div className={`${styles.bubble} ${styles.left}`}>
                          {message.message ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: urlify(
                                  message.message.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br>"
                                  )
                                ),
                              }}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                        {message.timestamp && (
                          <div className={styles.date}>
                            {formatTime(message.timestamp)}
                          </div>
                        )}
                        {askForFeedback &&
                          message === latestMessage &&
                          message?.message !==
                            "We are currently processing your request, and you will receive a confirmation message once its completed. In the meantime, feel free to provide any additional prompts or inquiries." && (
                            <Feedback />
                          )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              );
            })
          ) : (
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <CircularProgress disableShrink />
            </Container>
          )}

          <div ref={messagesRef}></div>
        </Container>
        <Grid container>
          <Grid className={styles.loader_grid} item md={1} xs={12}>
            {loading ? <HorizontalLoader /> : ""}
          </Grid>
        </Grid>
      </List>

      <Paper elevation={3}>
        {/* Suggestions */}
        <div
          id="suggestions-container"
          className={
            shouldScrollSuggestions ? styles.scrollableSuggestions : ""
          }
        >
          {showSuggestions &&
            suggestions.length > 0 &&
            newMessage.trim() !== "" &&
            suggestions.map((suggestion, index) => (
              <div
                className={styles.suggestionList}
                key={index}
                onClick={() => handleSuggestionClick(suggestion.text)}
              >
                <div style={{ marginRight: "20px" }}>
                  {suggestion.app === "keka" ? (
                    <img src={keka} alt="keka" className={styles.icons} />
                  ) : suggestion.app === "googlecalendar" ? (
                    <img
                      src={googleCalender}
                      alt="googleCalender"
                      className={styles.icons}
                    />
                  ) : suggestion.app === "dbf" ? (
                    <img src={dbf} alt="dbf" className={styles.icons} />
                  ) : suggestion.app === "jira" ? (
                    <img src={jira} alt="jira" className={styles.icons} />
                  ) : suggestion.app === "salesforce" ? (
                    <img
                      src={salesForce}
                      alt="salesForce"
                      className={styles.icons}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.suggetions_div}>
                  <div>{suggestion.text}</div>
                  <div className={styles.suggetions}>
                    {suggestion.intent === "Informational" && (
                      <div className={styles.suggetions}>
                        <InfoIcon style={{ color: "#707070" }} />
                        {suggestion.intent}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.navigationB}>
          <OutlinedInput
            autoFocus={loading ? false : true}
            value={newMessage}
            name="newMessage"
            type="text"
            fullWidth
            placeholder={"Type a message"}
            onChange={onChange}
            inputProps={{ autoComplete: "off" }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
            }}
          />
          <Button
            type="submit"
            className={loading ? styles.disabled_send_btn : styles.send_btn}
            onClick={loading ? null : onSubmit}
          >
            <SendIcon />
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default Messages;
