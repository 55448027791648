import React from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import { Card } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./styles/style.module.scss";
import Messages from "./Messages";
import { ChatWrapper, MessageWrapper } from "./styles/MuiStyle";

const Chat = () => {
  return (
    <ChatWrapper>
      <div>
        <ChatNav title={"Chat"} />
        {/* <ChatNav title={"Chat"} isSearch={true} /> */}
        <Card>
          <div className={styles.message_wrapper}>
            <CssBaseline />
            <MessageWrapper>
              <Messages />
            </MessageWrapper>
          </div>
        </Card>
      </div>
    </ChatWrapper>
  );
};

export default Chat;
