import React from "react";
import "../styles/loader.scss";

const HorizontalLoader = (props) => {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default HorizontalLoader;
