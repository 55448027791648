import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { setOepnTelegram } from "../../redux/slices/integrationSlice";
import SouthIcon from "@mui/icons-material/South";
import { Box } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export default function RoleDialogBox() {
  const dispatch = useDispatch();
  const { openTelegram, isTelegramActivate } = useSelector(
    (state) => state.integrationReducer
  );

  const handleClose = () => {
    dispatch(setOepnTelegram(false));
  };

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  `;

  const AnimatedBox = styled(Box)`
    text-align: center;
    margin: 30px 0px;
    animation: ${bounce} 2s infinite;
  `;

  return (
    <React.Fragment>
      <Dialog open={openTelegram} onClose={handleClose}>
        <DialogTitle textAlign="center" color="#8176f2">
          Telegram Activate Successfully{" "}
          <Box color="green">
            <TaskAltIcon />
          </Box>
        </DialogTitle>
        <DialogContent>
          <h3>Please click the link below to connect with the Telegram bot:</h3>
          <AnimatedBox>
            <SouthIcon />
          </AnimatedBox>
          <DialogContentText textAlign="center" margin="20px 0px">
            <a href={isTelegramActivate} target="_blank" onClick={handleClose}>
              {isTelegramActivate}
            </a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
