import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { setRoleData } from "../../../redux/slices/trainAndContentSlice";
import { RoleDropdownWrapper } from "../styles/MuiStyle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RoleDropdown() {
  const dispatch = useDispatch();
  const allAvailableRoles = useSelector(
    (state) => state.userManagementReducer.getRolesData
  );
  const { roleData } = useSelector((state) => state.trainAndContentReducer);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setRoleData(typeof value === "string" ? value.split(",") : value));
  };

  return (
    <RoleDropdownWrapper>
      <FormControl fullWidth sx={{ mt: 1, mb: 1, mr: 1 }}>
        <Select
          multiple
          value={roleData}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {allAvailableRoles?.data?.map((role) => (
            <MenuItem key={role?.name} value={role?.name}>
              <Checkbox checked={roleData.indexOf(role?.name) > -1} />
              <ListItemText primary={role?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </RoleDropdownWrapper>
  );
}
