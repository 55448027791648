import { createTheme, Paper, styled, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

const TrainFromContentWrapper = ({ children }) => {
  const selectedItem = useSelector(
    (state) => state.sidebarReducer.selectedItem
  );
  const train_content_theme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: "center",
            color: "#8176f2",
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            height: "60vh",
            overflowY: "scroll",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px !important",
            boxShadow: "none",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderLeft: "1px solid gray !important",
            height: "300vh",
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        },
      },
      MuiDivider: {
        root: {
          "&::before": {
            content: '""',
            display: "block",
            border: "5px solid red",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            background: "#202346",
            color: "white",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            width: "68%",
            marginLeft: "auto",
            boxSizing: "border-box",
            marginRight: "auto",
            display: selectedItem === "custom" ? "flex" : "block",
            justifyContent: selectedItem === "custom" && "center",
            alignItems: selectedItem === "custom" && "center",
            flexDirection: selectedItem === "custom" && "column",
            marginTop: selectedItem === "custom" ? "0%" : "7%",
            border: selectedItem === "custom" ? "none" : "",
            color: "#6E6E6E",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={train_content_theme}>{children}</ThemeProvider>;
};
const TrainFromContentWrapperBox = ({ children }) => {
  const selectedItem = useSelector(
    (state) => state.sidebarReducer.selectedItem
  );
  const train_content_theme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: "center",
            color: "#8176f2",
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            height: "60vh",
            overflowY: "scroll",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px !important",
            boxShadow: "none",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderLeft: "1px solid gray !important",
            // height: "0vh",
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        },
      },
      MuiDivider: {
        root: {
          "&::before": {
            content: '""',
            display: "block",
            border: "5px solid red",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            background: "#202346",
            color: "white",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            width: "68%",
            marginLeft: "auto",
            boxSizing: "border-box",
            marginRight: "auto",
            display: selectedItem === "custom" ? "flex" : "block",
            justifyContent: selectedItem === "custom" && "center",
            alignItems: selectedItem === "custom" && "center",
            flexDirection: selectedItem === "custom" && "column",
            marginTop: selectedItem === "custom" ? "0%" : "7%",
            border: selectedItem === "custom" ? "none" : "",
            color: "#6E6E6E",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={train_content_theme}>{children}</ThemeProvider>;
};

const MsgInputWrapper = ({ children }) => {
  const input_theme = createTheme();

  const StyledPaper = styled(Paper)({
    position: "absolute",
    bottom: 0,
    width: "100%",
    [input_theme.breakpoints.up("md")]: {
      width: "66.5%",
    },
    right: 0,
    padding: "0px",
    boxShadow: "none",
  });

  return (
    <ThemeProvider theme={input_theme}>
      <StyledPaper>{children}</StyledPaper>
    </ThemeProvider>
  );
};

const AddTrainWrapper = ({ children }) => {
  const addTrain_theme = createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            background: "#f8f8f8 !important",
            borderRadius: "5px !important",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#8176f2 !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={addTrain_theme}>{children}</ThemeProvider>;
};

const UploadPdfButton = ({ children }) => {
  const addTrain_theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid black",
            backgroundColor: "white !important",
            color: "black",
            textTransform: "capitalize",
            padding: "12px",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={addTrain_theme}>{children}</ThemeProvider>;
};

const TrainFormWrapper = ({ children }) => {
  const train_form_theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            border: "none",
            boxShadow: "none",
            marginTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px !important",
            boxShadow: "none",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            border: "1px solid #d3d3d3",
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#47a3a4 !important",
          },
          "&.Mui-checked": {
            color: "#47a3a4 !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={train_form_theme}>{children}</ThemeProvider>;
};
const TrainFormWrapperCustom = ({ children }) => {
  const train_form_theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            border: "none",
            boxShadow: "none",
            marginTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "white",
            boxShadow: "none",
            color: "white",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            padding: "0px",
            fontWeight: "bold",
            fontSize: "17px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            border: "1px solid #d3d3d3",
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#47a3a4 !important",
          },
          "&.Mui-checked": {
            color: "#47a3a4 !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={train_form_theme}>{children}</ThemeProvider>;
};
const TrainFormWrapperCustomAction = ({ children }) => {
  const train_form_theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            border: "none",
            boxShadow: "none",
            marginTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "#202346",
            boxShadow: "none",
            color: "white",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            border: "1px solid #d3d3d3",
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#47a3a4 !important",
          },
          "&.Mui-checked": {
            color: "#47a3a4 !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={train_form_theme}>{children}</ThemeProvider>;
};
const RoleDropdownWrapper = ({ children }) => {
  const role_theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#202346",
            },
          },
        },
      },
    },
  });

  return <ThemeProvider theme={role_theme}>{children}</ThemeProvider>;
};

const FormWrapper = ({ children }) => {
  const input_theme = createTheme({
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 490,
            "& ul": { padding: 0 },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            border: "1px solid #d3d3d3",
            marginTop: "10px",
            borderRadius: "5px ",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#47a3a4 !important",
          },
          "&.Mui-checked": {
            color: "#47a3a4 !important",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            border: "none",
            boxShadow: "none",
            marginTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
    },
  });
  const isOpen = useSelector((state) => state.sidebarReducer.isOpen);

  const StyledPaper = styled(Paper)({
    width: "100%",
    position: "",
    [input_theme.breakpoints.up("xl")]: {},
    maxWidth: isOpen ? 450 : 488,
  });

  return (
    <ThemeProvider theme={input_theme}>
      <StyledPaper>{children}</StyledPaper>
    </ThemeProvider>
  );
};

const ButtonBgWrapper = ({ children }) => {
  const btn_bg_theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#8176f2",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={btn_bg_theme}>{children}</ThemeProvider>;
};

const DropdownWrapper = ({ children }) => {
  const drop_bg_theme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#ffffff",
            border: "1px solid gray !important",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            color: "black",
            border: "1px solid gray !important",
            display: "flex",
            justifyContent: "space-between",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={drop_bg_theme}>{children}</ThemeProvider>;
};

const ChildMenuWrapper = ({ children }) => {
  const drop_bg_theme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            color: "white !important",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            backgroundColor: "#202346 !important",
            color: "white !important",
            boxShadow: "none !important",
            display: "flex !important",
            justifyContent: "center !important",
            flexDirection: "column !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: "1px solid #808080",
            marginLeft: "10px",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={drop_bg_theme}>{children}</ThemeProvider>;
};

const ParentWrapper = ({ children }) => {
  const drop_bg_theme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#202346 !important",
            border: "1px solid gray !important",
          },
          list: {
            backgroundColor: "#202346 !important",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#202346f !important",
            color: "black",
            border: "1px solid gray !important",
            display: "flex",
            justifyContent: "space-between",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={drop_bg_theme}>{children}</ThemeProvider>;
};

export {
  TrainFromContentWrapper,
  TrainFromContentWrapperBox,
  MsgInputWrapper,
  AddTrainWrapper,
  TrainFormWrapper,
  FormWrapper,
  ButtonBgWrapper,
  UploadPdfButton,
  TrainFormWrapperCustom,
  TrainFormWrapperCustomAction,
  DropdownWrapper,
  ChildMenuWrapper,
  ParentWrapper,
  RoleDropdownWrapper,
};
