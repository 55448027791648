// sidebarSlice.js
import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isOpen: true,
    selectedItem: "content",
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setToggleClose: (state, action) => {
      state.isOpen = false;
    },
  },
});

export const { toggleSidebar, setSelectedItem, setToggleClose } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
