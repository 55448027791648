import { Navigate } from "react-router-dom";
import AppSecureStorage from "../../services/storage/secureStorage";
const ProtectedRoute = ({ children }) => {
  const storage = new AppSecureStorage();
  const tokenExpiry = storage.get("tokenExpiry");
  let isAuthenticated =
    storage.get("token") && new Date() < new Date(tokenExpiry);

  return isAuthenticated ? children : <Navigate to={"/"} />;
};

export default ProtectedRoute;
