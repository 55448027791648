import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, FormControl, OutlinedInput } from "@mui/material";
import styles from "../../styles/style.module.scss";
import {
  setActionState,
  setEditOpen,
  setSelectedTrainedState,
  setStoreActionName,
} from "../../../../redux/slices/trainAndContentSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

export default function EditCustomModal() {
  const dispatch = useDispatch();
  const { singleDataResponse, editOpen } = useSelector(
    (state) => state.trainAndContentReducer
  );

  const [actionName, setActionName] = React.useState(singleDataResponse?.name);
  const handleChange = (event) => {
    setActionName(event.target.value);
  };

  const onSubmit = () => {
    dispatch(setStoreActionName(actionName));
    handleClose();
    dispatch(setSelectedTrainedState(false));
    dispatch(setActionState(true));
  };
  const handleClose = () => {
    dispatch(setEditOpen(false));
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={editOpen}
        onClose={handleClose}
      >
        <Box className={styles.add_action_header}>
          <DialogTitle className={styles.add_action_text}>
            Add custom action
          </DialogTitle>
          <CloseIcon
            className={styles.cross_icon_add_custom}
            onClick={handleClose}
          />
        </Box>
        <Box ml={2} color="black">
          Please enter action name
        </Box>
        <FormControl sx={{ ml: 2, mr: 2, mb: 2, mt: 1 }} variant="outlined">
          <OutlinedInput
            placeholder="Enter action name"
            fullWidth
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            value={actionName}
            name="actionName"
            onChange={handleChange}
          />
        </FormControl>
        <DialogActions>
          <Button
            disabled={actionName?.length > 0 ? false : true}
            onClick={onSubmit}
            autoFocus
            className={
              actionName?.length > 0
                ? styles.done_button
                : styles.done_button_dis
            }
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
