import React, { useEffect } from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import {
  Card,
  Grid,
  List,
  Container,
  IconButton,
  Box,
  CircularProgress,
  Pagination,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TrainFrom from "./trainForm/TrainForm";
import styles from "./styles/style.module.scss";
import { TrainFromContentWrapper } from "./styles/MuiStyle";
import { useDispatch, useSelector } from "react-redux";
import LinkIcon from "@mui/icons-material/Link";
import ApplyLeave from "./custom/applyLeave/ApplyLeave";
import { registerServiceWorker } from "./firebaseNotification/main";
import Notification from "./firebaseNotification/Notification";
import EditApplyLeave from "./custom/applyLeave/EditApplyLeave";
import { setPage } from "../../redux/slices/trainAndContentSlice";

const TrainFromContent = () => {
  const dispatch = useDispatch();
  const previewData = useSelector(
    (state) => state.trainAndContentReducer.previewData
  );
  const allDocumentsData = useSelector(
    (state) => state.trainAndContentReducer.allDocument
  );
  const { page, limit } = useSelector((state) => state.trainAndContentReducer);
  let totalPages = Math.ceil(allDocumentsData?.total / limit);
  const { activeButton, openCustomAction } = useSelector(
    (state) => state.trainAndContentReducer
  );
  const previewState = useSelector(
    (state) => state.trainAndContentReducer.previewState
  );
  const { loading, editApplyLeave } = useSelector(
    (state) => state.trainAndContentReducer
  );
  const selectedItem = useSelector(
    (state) => state.sidebarReducer.selectedItem
  );
  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  useEffect(() => {
    if (selectedItem === "content") {
      registerServiceWorker();
    }
  }, [selectedItem]);

  return (
    <TrainFromContentWrapper>
      <div>
        <ChatNav
          title={
            selectedItem === "content"
              ? "Train from content"
              : "Custom API Mapping"
          }
        />
        <Card>
          <div className={styles.trainContentWrapper}>
            <CssBaseline />
            <Grid
              container
              spacing={{ xs: 1, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} sm={12} md={4}>
                <TrainFrom />
                {selectedItem === "content"
                  ? allDocumentsData &&
                    allDocumentsData.data &&
                    allDocumentsData.data.length > 0 && (
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        className={styles.pageination_data}
                      />
                    )
                  : ""}
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                {!openCustomAction && (
                  <div className={styles.leftTrainContainer}>
                    <ChatNav
                      title={
                        selectedItem === "content"
                          ? "Test bot text preview"
                          : "Apply Leave"
                      }
                    />
                  </div>
                )}
                <>
                  <List>
                    {loading ? (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "80vh",
                        }}
                      >
                        <CircularProgress disableShrink />
                      </Container>
                    ) : selectedItem === "content" && previewState ? (
                      <Container className={styles.preview_contaier}>
                        {!previewData ? (
                          <Box>No preview available</Box>
                        ) : previewData && activeButton === "Url" ? (
                          <>
                            <IconButton>
                              <LinkIcon
                                style={{
                                  height: "35px",
                                  width: "35px",
                                }}
                              />
                            </IconButton>
                            <a
                              style={{ color: "blue" }}
                              href={previewData}
                              target="_blank"
                            >
                              {previewData}
                            </a>
                          </>
                        ) : (
                          <iframe
                            className={styles.ifrm}
                            style={{
                              width: "100%",
                              height: "100%",
                              style: "border:0",
                              referrerpolicy: "no-referrer-when-downgrade",
                              border: "none",
                              name: "iframe_all",
                              frameborder: "0",
                            }}
                            allowfullscreen
                            src={previewData && previewData + "#toolbar=0"}
                          ></iframe>
                        )}
                      </Container>
                    ) : selectedItem === "custom" ? (
                      editApplyLeave ? (
                        <EditApplyLeave />
                      ) : (
                        <ApplyLeave />
                      )
                    ) : null}
                  </List>
                </>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
      <Notification />
    </TrainFromContentWrapper>
  );
};

export default TrainFromContent;
