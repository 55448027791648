import React, { useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import styles from "../../styles/style.module.scss";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TrainFromContentWrapper } from "../../styles/MuiStyle";
import AddAPITestingModal from "../CustomModals/AddAPITestingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustom_data,
  setStoreEntitiesArray,
  setStorePromts,
} from "../../../../redux/slices/trainAndContentSlice";
import { TrainedData } from "../TrainedData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ApplyLeave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [rows, setRows] = React.useState([{ entityName: "" }]);
  const [rowsPrompt, setRowsPrompts] = React.useState([""]);
  const {
    getactionName,
    getPrompts,
    actionState,
    selectedTrainedState,
    trainedData,
    openCustomAction,
  } = useSelector((state) => state.trainAndContentReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addRowPrompts = () => {
    setRowsPrompts([...rowsPrompt, ""]);
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const deleteRowsPrompt = (index) => {
    const updatedPrompts = [...rowsPrompt];
    updatedPrompts.splice(index, 1);
    setRowsPrompts(updatedPrompts);
  };

  const addRow = () => {
    const lastRow = rows[rows.length - 1];
    const newRow = {
      [lastRow.name]: lastRow.designation,
    };
    setRows([...rows, newRow]);
  };

  const handleRowChange = (index, field, newValue) => {
    const updatedRows = rows.map((row, rowIndex) => {
      if (index === rowIndex) {
        const key = Object.keys(row)[0];
        return {
          [field === "name" ? newValue : key]:
            field === "name" ? row[key] : newValue,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRowChangePrompts = (index, field, newValue) => {
    const updatedPrompts = [...rowsPrompt];
    if (newValue.trim() === "") {
      updatedPrompts.splice(index, 1);
    } else {
      updatedPrompts[index] = { ...updatedPrompts[index], [field]: newValue };
    }
    setRowsPrompts(updatedPrompts);

    // Filter out null or empty prompts and extract prompt strings
    const promptStrings = updatedPrompts
      .filter((promptObj) => promptObj && promptObj.prompt.trim() !== "")
      .map((promptObj) => promptObj.prompt.trim());

    dispatch(setStorePromts(promptStrings));
  };
  let pairsObject = {};

  rows?.forEach((item) => {
    const keys = Object.keys(item);
    keys.forEach((key) => {
      pairsObject[key] = item[key];
    });
  });
  const handleNext = () => {
    // Check if there are less than 20 rows
    if (rowsPrompt.length <= 19) {
      toast.error("Enter prompts should be 20 or more.");
      return;
    }

    // Check if any prompt values are missing
    const arePromptsMissing = rowsPrompt.some((promptObj) => {
      const prompt = promptObj.prompt;
      return !prompt || !prompt.trim();
    });
    if (arePromptsMissing) {
      toast.error("All prompt values should required.");
      return;
    } else {
      const data = {
        name: getactionName,
        prompts: getPrompts,
        entities: pairsObject,
      };
      dispatch(setStoreEntitiesArray(rows));
      dispatch(createCustom_data(data, navigate));
    }
  };

  let isDisable =
    !rows.every(
      (entity) =>
        entity.entityName?.trim() !== "" && entity.designation?.trim() !== ""
    ) ||
    !getactionName ||
    !getPrompts;

  useEffect(() => {}, [selectedTrainedState, trainedData, openCustomAction]);

  return (
    <TrainFromContentWrapper>
      {selectedTrainedState ? (
        trainedData && <TrainedData />
      ) : openCustomAction ? (
        <TrainedData />
      ) : (
        actionState && (
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              marginTop: "70px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Add Manually"
                    value="1"
                    style={{ textTransform: "capitalize" }}
                  />
                  <Tab
                    label="Upload"
                    value="2"
                    style={{ textTransform: "capitalize" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div>Enter prompts</div>
                  <Button onClick={addRowPrompts} className={styles.add_btn}>
                    Add row
                  </Button>
                </div>
                {rowsPrompt.length <= 19 && (
                  <p
                    style={{
                      fontStyle: "italic",
                      marginTop: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Enter the maximum number of prompts for achieving good
                    accuracy. A minimum of 20 prompts is mandatorily required.
                  </p>
                )}
                {rowsPrompt.map((row, index) => (
                  <Box key={index} display="flex" gap="20px" marginTop="10px">
                    <TextField
                      placeholder="How To Apply Leave ?"
                      fullWidth
                      name="prompt"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DeleteIcon
                              className={styles.delete_icon}
                              onClick={() => deleteRowsPrompt(index)}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        handleRowChangePrompts(index, "prompt", e.target.value)
                      }
                    />
                  </Box>
                ))}
                <div>
                  <Divider
                    style={{
                      margin: "20px 0px",
                      height: "2px",
                      border: "1px solid #e0e0e0",
                      background: "#e0e0e0",
                    }}
                  ></Divider>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>Enter entities</div>
                    <Button onClick={addRow} className={styles.add_btn}>
                      Add row
                    </Button>
                  </div>
                  {rows &&
                    rows.map((row, index) => (
                      <Box
                        key={index}
                        display="flex"
                        gap="20px"
                        marginTop="10px"
                      >
                        <TextField
                          className={styles.api_date_text}
                          placeholder="Enter entity name"
                          name="name"
                          onChange={(e) =>
                            handleRowChange(index, "name", e.target.value)
                          }
                        />
                        <TextField
                          placeholder="Enter entity description"
                          fullWidth
                          name="designation"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DeleteIcon
                                  className={styles.delete_icon}
                                  onClick={() => deleteRow(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "designation",
                              e.target.value
                            )
                          }
                        />
                      </Box>
                    ))}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div>
                  <Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "39px",
                        flexWrap: "wrap",
                        backgroundImage: "linear-gradient(#e8e3f5, #e0e8f4)",
                        padding: "20px",
                        borderRadius: "5px",
                        width: "75%",
                      }}
                    >
                      <Box mt={1}>Drag and Drop your file here</Box>
                      <Box mt={1}>or</Box>
                      <Button
                        style={{ padding: "12px 35px" }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput type="file" />
                      </Button>
                    </div>
                  </Container>
                  <Divider
                    style={{
                      margin: "20px 0px",
                      height: "1px",
                      border: "1px solid #e0e0e0",
                      background: "#e0e0e0",
                    }}
                  ></Divider>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>Enter entities</div>
                      <Button onClick={addRow} className={styles.add_btn}>
                        Add row
                      </Button>
                    </div>
                    {rows &&
                      rows.map((row, index) => (
                        <Box
                          key={index}
                          display="flex"
                          gap="20px"
                          marginTop="10px"
                        >
                          <TextField
                            className={styles.api_date_text}
                            placeholder="Enter entity name"
                            name="name"
                            onChange={(e) =>
                              handleRowChange(index, "name", e.target.value)
                            }
                          />
                          <TextField
                            placeholder="Enter entity description"
                            fullWidth
                            name="designation"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DeleteIcon
                                    className={styles.delete_icon}
                                    onClick={() => deleteRow(index)}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              handleRowChange(
                                index,
                                "designation",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      ))}
                  </div>
                </div>
              </TabPanel>
            </TabContext>
            <Container>
              <Button
                className={
                  isDisable ? styles.next_btn_txt : styles.next_btn_txt_enable
                }
                disabled={isDisable ? true : false}
                onClick={isDisable ? null : handleNext}
              >
                Next
              </Button>
            </Container>
          </Box>
        )
      )}

      <AddAPITestingModal rows={rows} />
    </TrainFromContentWrapper>
  );
};

export default ApplyLeave;
