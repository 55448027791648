import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
} from "@mui/material";
import styles from "../styles/style.module.scss";
import { TableWrapper } from "../styles/MuiStyle";

const TableContent = ({ data, keyName }) => {
  const rows = useMemo(
    () =>
      data.map((item, index) => ({
        id: index + 1,
        name:
          item[keyName].charAt(0).toUpperCase() +
          item[keyName].slice(1).replace(/_/g, " ").replace(/-/g, " "),
        value: item.averageTime,
      })),
    [data, keyName]
  );

  return (
    <TableWrapper>
      <Table aria-label="simple table" style={{ marginBottom: "20px" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "white" }}>
              S.No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: "white", padding: "0px" }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: "white" }}
              align="center"
            >
              Response Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell component="th" scope="row">
                <div className={styles.tableName}>
                  <Avatar
                    alt={row.name}
                    src={`/static/images/avatar/${row.name}.jpg`}
                  />
                  <span>{row.name}</span>
                </div>
              </TableCell>
              <TableCell align="center">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default function ResponseWiseTable() {
  const { analyticsData } = useSelector((state) => state.analyticsReducer);

  const dataSources = {
    "Action Wise Response Time": analyticsData?.actionAvgResponseTimes || [],
    "App Wise Response Time": analyticsData?.appAvgResponseTimes || [],
    "Category Wise Response Time":
      analyticsData?.categoryAvgResponseTimes || [],
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabTitles = Object.keys(dataSources);
  const keyMapping = {
    "Action Wise Response Time": "action",
    "App Wise Response Time": "app",
    "Category Wise Response Time": "category",
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="data tables"
      >
        {tabTitles.map((title, index) => (
          <Tab
            sx={{ textTransform: "capitalize", fontSize: "17px" }}
            label={title}
            key={index}
          />
        ))}
      </Tabs>
      {tabTitles.map((title, index) => (
        <div key={index} hidden={index !== selectedTab}>
          <TableContent data={dataSources[title]} keyName={keyMapping[title]} />
        </div>
      ))}
    </div>
  );
}
