import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
} from "@mui/material";
import styles from "../styles/style.module.scss";
import { TableWrapper } from "../styles/MuiStyle";

export default function AnalyticsTable() {
  const { analyticsData } = useSelector((state) => state.analyticsReducer);

  const dataSources = useMemo(
    () => ({
      "Category Wise Requests": analyticsData?.categoryWiseRequests || {},
      "Source Wise Requests": analyticsData?.sourceWiseRequests || {},
      "Action Wise Requests": analyticsData?.actionWiseRequests || {},
    }),
    [analyticsData]
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const convertDataToRows = (data) =>
    Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }));

  const tabTitles = Object.keys(dataSources);
  const tabData = Object.values(dataSources);

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="data tables"
      >
        {tabTitles.map((title, index) => (
          <Tab
            sx={{ textTransform: "capitalize", fontSize: "17px" }}
            label={title}
            key={index}
          />
        ))}
      </Tabs>

      {tabData[selectedTab] && (
        <TableWrapper>
          <Table aria-label="simple table" style={{ marginBottom: "20px" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                  S.No.
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: "white", padding: "0px" }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: "white" }}
                  align="center"
                >
                  Requests
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {convertDataToRows(tabData[selectedTab]).map((row, index) => {
                const formattedName =
                  row.name.charAt(0).toUpperCase() +
                  row.name.slice(1).replace(/_/g, " ").replace(/-/g, " ");
                return (
                  <TableRow key={row.name}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      <div className={styles.tableName}>
                        <Avatar
                          alt={formattedName}
                          src={`/static/images/avatar/${formattedName}.jpg`}
                        />
                        <span>{formattedName}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      )}
    </div>
  );
}
