import React from "react";
import classes from "./Error.module.scss";

const Error = () => {
  return (
    <div className={classes.errorPage}>
      <h1>oops! you have landed on wrong page</h1>
    </div>
  );
};

export default Error;
