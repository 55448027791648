import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import { Avatar, Box, Chip, List, ListItem } from "@mui/material";
import jira from "../../assets/Jira.svg";
import salesForce from "../../assets/Saleforce.png";
import Telegram from "../../assets/Telegram.png";
import googleCalender from "../../assets/googleCalender.png";
import EmailIcon from "@mui/icons-material/Email";
import keka from "../../assets/keka.png";
import dbf from "../../assets/dbf.png";
import styles from "./styles/style.module.scss";
import { IntegrationWrapper } from "./styles/MuiStyle";
import CloseIcon from "@mui/icons-material/Close";

export default function InstructionsDrawer({ toggleDrawer, state }) {
  const instructionsData = useSelector(
    (state) => state.integrationReducer.instructionsData
  );
  return (
    <div>
      <IntegrationWrapper>
        <Drawer anchor="right" open={state} onClose={() => toggleDrawer()}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              className={styles.chip}
              avatar={
                instructionsData.name === "keka" ? (
                  <Avatar alt="Natacha" src={keka} className={styles.avatar} />
                ) : instructionsData.name === "dbf" ? (
                  <Avatar alt="Natacha" src={dbf} className={styles.avatar} />
                ) : instructionsData.name === "telegram" ? (
                  <Avatar
                    alt="Natacha"
                    src={Telegram}
                    className={styles.avatar}
                  />
                ) : instructionsData.name === "salesforce" ? (
                  <Avatar
                    alt="Natacha"
                    src={salesForce}
                    className={styles.avatar}
                  />
                ) : instructionsData.name === "google_calendar" ? (
                  <Avatar
                    alt="Natacha"
                    src={googleCalender}
                    className={styles.avatar}
                  />
                ) : instructionsData.name === "jira" ? (
                  <Avatar alt="Natacha" src={jira} className={styles.avatar} />
                ) : (
                  instructionsData.name === "gmail" && (
                    <EmailIcon
                      style={{ color: "red" }}
                      className={styles.avatar}
                    />
                  )
                )
              }
            />
            <Box className={styles.name}>
              {instructionsData?.name}
              <Box className={styles.web}>{instructionsData?.website}</Box>
            </Box>

            <Box className={styles.close_btn}>
              <CloseIcon onClick={() => toggleDrawer()} />
            </Box>
          </Box>

          <Box
            mt={5}
            color="white"
            className={`${styles.bubble} ${styles.left}`}
          >
            <Box className={styles.instruction_text}>Description:</Box>
            <Box mt={2} mb={2}>
              {instructionsData?.description}
            </Box>
          </Box>
          <Box>
            <List
              sx={{
                bgcolor: "background.paper",
                margin: "2rem 0rem",
                padding: "2rem 1rem",
              }}
              className={`${styles.bubble} ${styles.right}`}
            >
              <Box className={styles.instruction_text}>Instructions:</Box>
              <ul>
                {instructionsData?.actions?.map((ele, index) =>
                  ele ? (
                    <li key={index} style={{ margin: "35px 35px 35px 0px" }}>
                      <span className={styles.number}>{index + 1}</span>
                      {ele.charAt(0).toUpperCase() + ele.slice(1)}
                    </li>
                  ) : (
                    <span className={styles.noData}>
                      No instructions are available
                    </span>
                  )
                )}
              </ul>
            </List>
          </Box>
        </Drawer>
      </IntegrationWrapper>
    </div>
  );
}
