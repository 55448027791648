import { Box, Grid, Divider, styled, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import styles from "../styles/style.module.scss";
import { useEffect, useState } from "react";
import { TrainFromContentWrapperBox } from "../styles/MuiStyle";
import {
  setSelectedAction,
  setSelectedApp,
} from "../../../redux/slices/trainAndContentSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "start",
  color: "black",
  border: "1px solid #b6b6b6",
  marginTop: "10px",
  padding: "10px 20px !important",
  wordWrap: "break-word !important",
}));

export const TrainedData = () => {
  const {
    trainedData,
    openCustomAction,
    renderallCustomAction,
    selectedCategory,
    selectedAction,
    selectedApp,
    selectedActionData,
    actionName,
  } = useSelector((state) => state.trainAndContentReducer);
  const actionNameData = useSelector(
    (state) => state.trainAndContentReducer.actionName
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!renderallCustomAction) {
      dispatch(setSelectedAction(null));
    }
  }, [openCustomAction, selectedAction, selectedActionData]);

  return (
    <>
      {
        openCustomAction ? (
          <TrainFromContentWrapperBox>
            <Box
              sx={{
                width: "100%",
                marginTop: openCustomAction ? "20px" : "70px",
                padding: "1rem 2rem",
              }}
            >
              <Grid container spacing={5} md={12}>
                {selectedCategory && (
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="space-between"
                    md={12}
                    gap={6}
                  >
                    <Grid fullWidth className={styles.header_name} md={12}>
                      <Box fullWidth className={styles.header_name}>
                        Select Action
                      </Box>
                      <Select
                        value={selectedAction || null}
                        onChange={(event) =>
                          dispatch(setSelectedAction(event.target.value))
                        }
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value={null} disabled>
                          Select Action
                        </MenuItem>
                        {renderallCustomAction
                          .filter(
                            (action) => action.category === selectedCategory
                          )
                          .map((action) => (
                            <MenuItem key={action._id} value={action}>
                              {action.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    {selectedAction && (
                      <Grid item xs={4} md={12}>
                        <Grid fullWidth className={styles.header_name} md={12}>
                          <Box fullWidth className={styles.header_name}>
                            Select Apps
                          </Box>
                          <Select
                            value={selectedApp}
                            onChange={(event) =>
                              dispatch(setSelectedApp(event.target.value))
                            }
                            displayEmpty
                            fullWidth
                          >
                            <MenuItem value={null} disabled>
                              Select Apps
                            </MenuItem>
                            {selectedAction?.apps?.map((app) => (
                              <MenuItem key={app._id} value={app}>
                                {app.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              {selectedActionData?.categoryName && (
                <Grid container spacing={5} mt={0.1}>
                  <Grid item xs={12}>
                    <Box fullWidth className={styles.header_name}>
                      Category Name
                    </Box>
                    <Item>{selectedActionData?.categoryName}</Item>
                  </Grid>
                </Grid>
              )}
              {selectedActionData && (
                <Grid container spacing={2} mt={0}>
                  {selectedActionData.actionData?.apiData.method && (
                    <Grid item xs={12}>
                      <Box fullWidth className={styles.header_name}>
                        Method
                      </Box>
                      <Item>
                        {selectedActionData.actionData?.apiData.method}
                      </Item>
                    </Grid>
                  )}
                  {selectedActionData.actionData?.apiData.url && (
                    <Grid item mt={0} ml={0.5}>
                      <Box fullWidth className={styles.header_name}>
                        URL
                      </Box>
                      <Item>{selectedActionData.actionData?.apiData.url}</Item>
                    </Grid>
                  )}
                </Grid>
              )}
              {Array.isArray(selectedActionData?.actionData?.prompts)}
              <Grid item xs={12} mt={2} ml={0.5}>
                {Array.isArray(selectedActionData?.actionData?.prompts) && (
                  <Box fullWidth className={styles.header_name}>
                    Prompts
                  </Box>
                )}
                {Array.isArray(selectedActionData?.actionData?.prompts) &&
                  selectedActionData?.actionData?.prompts.map((prompt) => (
                    <Item key={prompt.intent}>{prompt}</Item>
                  ))}
              </Grid>
              {/* {selectedAction?.category && (
                <Grid item xs={12} mt={1}>
                  <Box fullWidth className={styles.header_name}>
                    Category
                  </Box>
                  <Item>{selectedAction?.category}</Item>
                </Grid>
              )} */}
            </Box>
          </TrainFromContentWrapperBox>
        ) : (
          ""
        )
        // (
        //   <Box
        //     sx={{
        //       width: "100%",
        //       typography: "body1",
        //       marginTop: "70px",
        //       padding: "1rem 2rem",
        //     }}
        //   >
        //     <Grid container spacing={5}>
        //       {trainedData?.apiData?.method && (
        //         <Grid item xs={4}>
        //           <Box fullWidth className={styles.header_name}>
        //             Methods
        //           </Box>
        //           <Item>{trainedData?.apiData?.method}</Item>
        //         </Grid>
        //       )}
        //       {trainedData?.apiData?.url && (
        //         <Grid item xs={8}>
        //           <Box fullWidth className={styles.header_name}>
        //             URL
        //           </Box>
        //           <Item>{trainedData?.apiData?.url}</Item>
        //         </Grid>
        //       )}
        //     </Grid>
        //     {trainedData?.prompts && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.prompts && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Prompts
        //           </Box>
        //           {trainedData?.prompts?.map((ele) => (
        //             <Item>{ele}</Item>
        //           ))}
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.entities && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.entities && (
        //       <Box fullWidth className={styles.header_name}>
        //         Entities
        //       </Box>
        //     )}
        //     {trainedData?.entities && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={4}>
        //           {trainedData &&
        //             Object.entries(trainedData?.entities).map(
        //               ([key, value, i]) => <Item>{key}</Item>
        //             )}
        //         </Grid>
        //         <Grid item xs={8}>
        //           {trainedData &&
        //             Object.entries(trainedData?.entities).map(
        //               ([key, value, i]) => (
        //                 <Item>{value ? value : "No description available"}</Item>
        //               )
        //             )}
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.headers && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.headers && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Header
        //           </Box>
        //           <Item>
        //             {trainedData?.apiData?.headers &&
        //               Object.entries(trainedData?.apiData?.headers).map(
        //                 ([key, value, i]) => (
        //                   <Item>
        //                     {key} :{value}
        //                   </Item>
        //                 )
        //               )}
        //           </Item>
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.body && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.body && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Body
        //           </Box>
        //           <Item>
        //             {trainedData?.apiData?.body &&
        //               Object.entries(trainedData?.apiData?.body).map(
        //                 ([key, value, i]) => (
        //                   <Item>
        //                     {key} :{value}
        //                   </Item>
        //                 )
        //               )}
        //           </Item>
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.params && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.params && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Params
        //           </Box>
        //           <Item>
        //             {trainedData?.apiData?.params &&
        //               Object.entries(trainedData?.apiData?.params).map(
        //                 ([key, value, i]) => (
        //                   <Item>
        //                     {key} :{value}
        //                   </Item>
        //                 )
        //               )}
        //           </Item>
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.query && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.query && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Query
        //           </Box>
        //           <Item>
        //             {trainedData?.apiData?.query &&
        //               Object.entries(trainedData?.apiData?.query).map(
        //                 ([key, value, i]) => (
        //                   <Item>
        //                     {key} :{value}
        //                   </Item>
        //                 )
        //               )}
        //           </Item>
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.responseKeys && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.responseKeys && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Response Key
        //           </Box>
        //           {trainedData &&
        //             trainedData?.apiData?.responseKeys?.map((value) => (
        //               <Item>{value}</Item>
        //             ))}
        //         </Grid>
        //       </Grid>
        //     )}
        //     {trainedData?.apiData?.responseMessage && (
        //       <Divider
        //         style={{
        //           margin: "20px 0px",
        //           height: "2px",
        //           border: "1px solid #e0e0e0",
        //           background: "#e0e0e0",
        //         }}
        //       ></Divider>
        //     )}
        //     {trainedData?.apiData?.responseMessage && (
        //       <Grid container spacing={5}>
        //         <Grid item xs={12}>
        //           <Box fullWidth className={styles.header_name}>
        //             Response Message
        //           </Box>
        //           <Item>{trainedData?.apiData?.responseMessage}</Item>
        //         </Grid>
        //       </Grid>
        //     )}
        //   </Box>
        // )
      }
    </>
  );
};
