import React from "react";
import styles from "./styles/style.module.scss";
import ChatNav from "../../components/chatNav/ChatNav";
import { Card, CircularProgress, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AreaChartPage from "./Graph/AreaChart";
import PieChartPage from "./Graph/PieChart";
import AnalyticsTable from "./AnalyticsTable/AnalyticsTable";
import { AnalytisWrapper } from "./styles/MuiStyle";
import { useSelector } from "react-redux";
import ResponseWiseTable from "./AnalyticsTable/ResponseWiseTable";

const Analytics = () => {
  const { loading, analyticsData } = useSelector(
    (state) => state.analyticsReducer
  );
  const extractNumericValue = (str) => {
    const match = str.match(/\d+(\.\d+)?/);
    return match ? parseFloat(match[0]) : null;
  };
  const avgResponseTime = analyticsData?.avgResponseTime;
  const numericValue = avgResponseTime
    ? extractNumericValue(avgResponseTime)
    : null;
  // Function to extract the unit of time
  function extractUnitOfTime(str) {
    const match = str.match(/\b(?:seconds?|milliseconds?|minutes?|hours?)\b/i);
    return match ? match[0] : null;
  }

  // Extract the unit of time from the average response time
  const avgResponseTimes = analyticsData?.avgResponseTime;
  const unitOfTime = avgResponseTimes
    ? extractUnitOfTime(avgResponseTime)
    : null;

  return (
    <AnalytisWrapper>
      <div>
        <ChatNav title={"Analytics"} />
        <Card>
          <div className={styles.analytics_wrapper}>
            <CssBaseline />
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={12} ml={1}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <AreaChartPage
                    numericValue={numericValue}
                    unitOfTime={unitOfTime}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={2} sm={4} md={12} height={300}>
              {loading ? <CircularProgress /> : <PieChartPage />}
            </Grid>
            <Grid item xs={2} sm={4} md={12} mt={8}>
              <AnalyticsTable />
            </Grid>
            <Grid item xs={2} sm={4} md={12} mt={8}>
              <ResponseWiseTable />
            </Grid>
          </div>
        </Card>
      </div>
    </AnalytisWrapper>
  );
};

export default Analytics;
