import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import UserService from "../../services/api/userService";
import { setLoading } from "./global";
import { unauthorizedError } from "../../utils/helpers";

const userService = new UserService();

export const fetchUserStats = createAsyncThunk(
  "stats/fetchUserStats",
  async (type, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const userStatsResponse = await userService.getUserStats({ type });
      dispatch(setLoading(false));
      toast.success("User stats fetched Successfully");
      return userStatsResponse.data;
    } catch (error) {
      if (error?.response && error?.response.status === 401) {
        // unauthorizedError(navigate);
      } else {
        toast.error("loading business stats failed");
      }
      dispatch(setLoading(false));
    }
  }
);

const statsSlice = createSlice({
  name: "stats",
  initialState: {
    userStats: [],
    businessStats: [],
  },
  reducers: {
    setUserStats: (state, action) => {
      state.userStats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserStats.fulfilled, (state, action) => {
      state.userStats = action.payload;
    });
  },
});

export const { setUserStats } = statsSlice.actions;

export default statsSlice.reducer;
