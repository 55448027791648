import * as React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Checkbox,
  OutlinedInput,
  MenuItem,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import styles from "./styles/style.module.scss";
import { DropdownWrapper } from "./styles/MuiStyles";
import { useDispatch, useSelector } from "react-redux";
import ActiveDeactiveModal from "../modal/ActiveDeactiveModal";
import {
  setPageRole,
  update_user,
} from "../../redux/slices/userManagementSilce";
import { toast } from "react-toastify";
import Button from "../../components/common/buttons/Button";
import AppSecureStorage from "../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";

const storage = new AppSecureStorage();

const Dropdown = ({ page, values, currentValue, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let permissions = JSON.parse(storage.get("permissions"));
  let User =
    permissions && permissions["User"] && permissions["User"]?.includes("Edit");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        backgroundColor: "#202346",
        color: "white",
        height: "300px",
      },
    },
  };

  const names = [
    "All",
    "Accounts",
    "Documents",
    "HRMA",
    "Messaging",
    "Project Management",
  ];

  const [selectedValue, setSelectedValue] = React.useState(currentValue);
  const [roleChanged, setRoleChanged] = React.useState(false);
  const limit = useSelector((state) => state.userManagementReducer.limit);
  const paginationData = useSelector(
    (state) => state.userManagementReducer.page
  );
  const filterValue = useSelector(
    (state) => state.userManagementReducer.filterValue
  );
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (page === "userManagement") {
      setRoleChanged(true);
    }
  };

  const handlePageChange = (event, value) => {
    dispatch(setPageRole(value));
  };

  const cancelUpdateUserRole = () => {
    setRoleChanged(false);
  };
  const updateUserRole = () => {
    if (User) {
      dispatch(
        update_user({
          id: userId,
          data: { role: selectedValue },
          navigate: navigate,
          page: paginationData,
          limit: limit,
          filterValue: filterValue.toLowerCase(),
        })
      );
      if (userId === storage.get("id")) {
        setTimeout(() => {
          navigate("/");
          storage.clearStorage();
        }, 2000);
      }

      setRoleChanged(false);
    } else {
      toast.error("You dosn't have permission to change this role");
      setRoleChanged(false);
    }
  };

  return (
    <>
      <DropdownWrapper>
        <FormControl fullWidth>
          <Select
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
                border: "0.5px solid #e0e0e0",
              },
            }}
            displayEmpty
            value={[]}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <div className={styles.filter}>
                    {page === "integration" ? <FilterAltIcon /> : null}
                    <div>
                      {page === "integration" ? "Filter" : selectedValue}
                    </div>
                  </div>
                );
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {page === "integration"
              ? names?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <div className={styles.dropdown_items}>
                      <div>{name}</div>
                      <Checkbox defaultChecked />
                    </div>
                  </MenuItem>
                ))
              : values?.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <div>{name}</div>
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <ActiveDeactiveModal userRole={values} />
      </DropdownWrapper>
      {roleChanged && (
        <Dialog open={roleChanged} onClose={cancelUpdateUserRole}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to change role for this user to ${selectedValue}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.confirmation_dialog}>
            <Button
              color="primary"
              className={styles.confirm_button}
              onClick={cancelUpdateUserRole}
            >
              No
            </Button>
            <Button
              className={styles.confirm_button}
              onClick={updateUserRole}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Dropdown;
