import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, Fab } from "@mui/material";
import "./styles/navbar.module.scss";
import logo from "../../assets/logo.svg";
import { NavbarWrapper } from "./styles/MuiStyles";
import AppSecureStorage from "../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";
const storage = new AppSecureStorage();

export default function Navbar() {
  const navigate = useNavigate();
  const handleAvatar = () => {
    navigate("/profile");
  };

  return (
    <NavbarWrapper>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Avatar
              alt=""
              src={logo}
              sx={{ width: 58, height: 56, borderRadius: "0px" }}
            />
            <Typography
              className="icon-text"
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#8176f2",
                marginLeft: "10px",
                fontWeight: "bold",
              }}
            >
              <div style={{ position: "relative", top: "5px" }}>
                SimplifyPath
              </div>
              <div style={{ position: "relative", bottom: "4px" }}>
                <a
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                  }}
                  href="https://www.tftus.com/"
                  target="_blanck"
                >
                  Powered by TFT
                </a>
              </div>
            </Typography>
            {/* <Fab variant="extended" color="primary" onClick={handleAvatar}>
              <Avatar
                sx={{ mr: 1 }}
                src="https://static.vecteezy.com/system/resources/previews/020/765/399/non_2x/default-profile-account-unknown-icon-black-silhouette-free-vector.jpg"
              />
            </Fab> */}
          </Toolbar>
        </AppBar>
      </Box>
    </NavbarWrapper>
  );
}
