import React, { useEffect } from "react";
import {
  Box,
  Card,
  Grid,
  List,
  Container,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardContent,
  Radio,
  Tooltip,
  Pagination,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddTrainModal from "../AddTrainModal/AddTrainModal";
import { FormWrapper, TrainFormWrapper } from "../styles/MuiStyle";
import styles from "../styles/style.module.scss";
import Button from "../../../components/common/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustom_data,
  getAllUploadedDocuments,
  removePDF,
  setActiveButton,
  setOpenAddTrainMoadl,
  setPage,
  setPageCustom,
  setPreviewData,
  setPreviewState,
} from "../../../redux/slices/trainAndContentSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import CirculerLoader from "../../../components/loader/circulerLoader/CirculerLoader";
import CustomForm from "../custom/CustomForm";
import AppSecureStorage from "../../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";

const storage = new AppSecureStorage();

const TrainFrom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [selectedPdf, setSelectedPdf] = React.useState(null);
  const [selectedText, setSelectedText] = React.useState(null);
  const [selectedURL, setSelectedURL] = React.useState(null);
  const [fileType, setFileType] = React.useState("Text");
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedDeleteItemId, setSelectedDeleteItemId] = React.useState(null);
  let permissions = JSON.parse(storage.get("permissions"));
  let TrainContent =
    permissions &&
    permissions["TrainContent"] &&
    permissions["TrainContent"]?.includes("Add");
  let TrainContentDelete =
    permissions &&
    permissions["TrainContent"] &&
    permissions["TrainContent"]?.includes("Delete");
  const { page, limit, limitCustom, pageCustom, renderallCustomData } =
    useSelector((state) => state.trainAndContentReducer);
  const allDocumentsData = useSelector(
    (state) => state.trainAndContentReducer.allDocument
  );
  const activeButton = useSelector(
    (state) => state.trainAndContentReducer.activeButton
  );
  let fileTypeData = activeButton.toLocaleLowerCase();
  const loading = useSelector((state) => state.trainAndContentReducer.loading);
  const profileLoading = useSelector((state) => state.profileReducer.loading);
  const selectedItem = useSelector(
    (state) => state.sidebarReducer.selectedItem
  );
  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  const handlePageChangeCustom = (event, value) => {
    dispatch(setPageCustom(value));
  };

  const handlePdfSelection = ({ fileName, list }) => {
    dispatch(setPreviewState(false));
    dispatch(setPreviewState(true));
    setSelectedPdf((prevState) => (prevState === fileName ? null : fileName));
    setSelectedText(null);
    setSelectedURL(null);
    dispatch(setPreviewData(list?.location));
  };

  const handleTextSelection = ({ fileName, list }) => {
    dispatch(setPreviewState(false));
    dispatch(setPreviewState(true));
    setSelectedPdf(null);
    setSelectedText((prevState) => (prevState === fileName ? null : fileName));
    setSelectedURL(null);
    dispatch(setPreviewData(list?.location));
  };

  const handleURLSelection = ({ fileName, list }) => {
    dispatch(setPreviewState(false));
    dispatch(setPreviewState(true));
    setSelectedPdf(null);
    setSelectedText(null);
    setSelectedURL((prevState) => (prevState === fileName ? null : fileName));
    dispatch(setPreviewData(list?.urlLink));
  };

  const handleClickInsidePreview = () => {
    dispatch(setPreviewData(""));
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleRemovePDF = ({ fileName, id }) => {
    dispatch(
      removePDF({
        id: id,
        page: page,
        limit: limit,
        fileType: fileName,
        navigate: navigate,
      })
    );
  };
  const handleYesButtonClick = (list) => {
    handleRemovePDF({
      fileName: list.type,
      id: list._id,
    });
    setOpenConfirmation(false);
  };

  const handlePreviewClick = () => {
    dispatch(setPreviewState(true));
  };

  useEffect(() => {
    if (selectedItem === "content") {
      dispatch(
        getAllUploadedDocuments({
          page: page,
          limit: limit,
          type: fileTypeData,
          navigate: navigate,
        })
      );
    } else if (selectedItem === "custom") {
      dispatch(
        getAllCustom_data({
          page: pageCustom,
          limit: limitCustom,
          navigate: navigate,
        })
      );
    }
  }, [page, limit, fileTypeData, selectedItem, pageCustom, limitCustom]);

  useEffect(() => {
    let selectedData = allDocumentsData?.data.map(
      (config, index) => config.filename
    );
    if (selectedData) {
      setSelectedPdf(null);
      setSelectedText(null);
      setSelectedURL(null);
    }
  }, [activeButton, allDocumentsData]);

  const handleActiveButton = (fileType) => {
    dispatch(setActiveButton(fileType));
    setFileType(fileType);
    if (selectedItem === "content") {
      dispatch(setPage(1));
    } else {
      dispatch(setPageCustom(1));
    }
  };

  const renderButtons = () => {
    return (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {buttonConfigs.map((config, index) => (
          <Grid item key={index} {...config.sizes}>
            <div
              className={
                activeButton === config.label
                  ? styles.btnOptions
                  : styles.inactive_btn
              }
              onClick={() => handleActiveButton(config.label)}
            >
              <Button size="large" startIcon={config.icon}>
                {config.label}
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleConfirmation = (itemId) => {
    setOpenConfirmation(true);
    setSelectedDeleteItemId(itemId);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setSelectedDeleteItemId(null);
  };

  const buttonConfigs = [
    { icon: <NoteAddIcon />, label: "Text", sizes: { xs: 12, sm: 12, md: 4 } },
    { icon: <InsertLinkIcon />, label: "Url", sizes: { xs: 2, sm: 4, md: 4 } },
    {
      icon: <PictureAsPdfIcon />,
      label: "Pdf",
      sizes: { xs: 2, sm: 4, md: 4 },
    },
  ];

  useEffect(() => {
    let selectedData = allDocumentsData?.data.map(
      (config, index) => config.filename
    );
    if (selectedData) {
      setSelectedPdf(null);
      setSelectedText(null);
      setSelectedURL(null);
    }

    // Filter data based on the active button
    let filteredData = [];
    if (activeButton === "Text") {
      filteredData = allDocumentsData?.data.filter(
        (doc) => doc.type === "text"
      );
    } else if (activeButton === "Url") {
      filteredData = allDocumentsData?.data.filter((doc) => doc.type === "url");
    } else if (activeButton === "Pdf") {
      filteredData = allDocumentsData?.data.filter((doc) => doc.type === "pdf");
    }

    // Select the first item in the corresponding category
    if (filteredData?.length > 0) {
      const firstItem = filteredData[0];
      if (activeButton === "Text") {
        setSelectedText(firstItem.fileName);
        dispatch(setPreviewData(firstItem?.location));
      } else if (activeButton === "Url") {
        setSelectedURL(firstItem.fileName);
        dispatch(setPreviewData(firstItem?.urlLink));
      } else if (activeButton === "Pdf") {
        setSelectedPdf(firstItem.fileName);
        dispatch(setPreviewData(firstItem?.location));
      }
    } else {
      // If there are no items of the selected type, set previewData to null
      dispatch(setPreviewData(null));
    }

    // Check if the preview state needs to be triggered
    if (selectedPdf || selectedText || selectedURL || activeButton === "Text") {
      dispatch(setPreviewState(true));
    }
  }, [activeButton, allDocumentsData]);

  return (
    <TrainFormWrapper>
      <FormWrapper>
        <Paper>
          <Container maxWidth="xl">
            <div>
              {selectedItem === "content" && TrainContent && (
                <div className={styles.options}>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    startIcon={<NoteAddIcon />}
                    onClick={() => dispatch(setOpenAddTrainMoadl(true))}
                    className={styles.add_train_data}
                  >
                    Add Training Data
                  </Button>
                </div>
              )}
              <Divider />
              <div className={styles.train_form}>
                {selectedItem === "content" ? (
                  <Card>
                    <CardContent>
                      <p>Train Form</p>
                    </CardContent>
                    <>{renderButtons()}</>
                  </Card>
                ) : (
                  selectedItem === "custom" && <CustomForm />
                )}
                {selectedItem === "content" && (
                  <Box>
                    <nav aria-label="main mailbox folders">
                      <List>
                        {loading || profileLoading ? (
                          <CirculerLoader />
                        ) : (
                          buttonConfigs.map(
                            (config, index) =>
                              activeButton === config.label &&
                              config.label === "Pdf" &&
                              allDocumentsData?.data
                                ?.filter((doc) => doc.type === "pdf")
                                .map((list, i) => {
                                  const fileName = list.fileName;
                                  const truncatedFileName =
                                    fileName.length > 20
                                      ? fileName.substring(0, 20) + "..."
                                      : fileName;

                                  return (
                                    <ListItem
                                      className={styles.div}
                                      disablePadding
                                      sx={{
                                        backgroundColor:
                                          selectedPdf === list.fileName
                                            ? "#202346"
                                            : "#fff",
                                        color:
                                          selectedPdf === list.fileName
                                            ? "white"
                                            : "black",
                                      }}
                                      key={i}
                                    >
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <Radio
                                            onClick={() =>
                                              handlePdfSelection({
                                                fileName,
                                                list,
                                              })
                                            }
                                            checked={
                                              selectedPdf === list.fileName
                                            }
                                            value={list.fileName}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "A" }}
                                          />
                                        </ListItemIcon>
                                        <Tooltip
                                          title={fileName.replace(".pdf", "")}
                                        >
                                          <ListItemText
                                            primary={truncatedFileName.replace(
                                              ".pdf",
                                              ""
                                            )}
                                          />
                                        </Tooltip>
                                        {TrainContentDelete && (
                                          <div
                                            className={styles.icons}
                                            onClick={() =>
                                              handleConfirmation(list._id)
                                            }
                                          >
                                            <DeleteIcon />
                                          </div>
                                        )}
                                        {openConfirmation && (
                                          <Dialog
                                            open={
                                              openConfirmation &&
                                              selectedDeleteItemId === list._id
                                            }
                                            onClose={handleClose}
                                          >
                                            <DialogContent>
                                              <DialogContentText id="alert-dialog-description">
                                                Are you sure you want to delete{" "}
                                                {list.fileName.replace(
                                                  ".pdf",
                                                  ""
                                                )}
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions
                                              className={
                                                styles.confirmation_dialog
                                              }
                                            >
                                              <Button
                                                onClick={handleClose}
                                                color="primary"
                                                className={
                                                  styles.confirm_button
                                                }
                                              >
                                                No
                                              </Button>
                                              <Button
                                                className={
                                                  styles.confirm_button
                                                }
                                                onClick={() =>
                                                  handleYesButtonClick(list)
                                                }
                                                color="primary"
                                                autoFocus
                                              >
                                                Yes
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        )}
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })
                          )
                        )}
                      </List>
                      {/* Text */}
                      <List>
                        {loading ? (
                          <CirculerLoader />
                        ) : (
                          buttonConfigs.map(
                            (config, index) =>
                              activeButton === config.label &&
                              config.label === "Text" &&
                              allDocumentsData?.data
                                ?.filter((doc) => doc.type === "text")
                                .map((list, i) => {
                                  const fileName = list.fileName;
                                  const truncatedFileName =
                                    fileName.length > 20
                                      ? fileName.substring(0, 20) + "..."
                                      : fileName;

                                  return (
                                    <ListItem
                                      disablePadding
                                      sx={{
                                        backgroundColor:
                                          selectedText === list.fileName
                                            ? "#202346"
                                            : "#fff",
                                        color:
                                          selectedText === list.fileName
                                            ? "white"
                                            : "black",
                                      }}
                                      key={i}
                                    >
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <Radio
                                            checked={
                                              selectedText === list.fileName
                                            }
                                            onClick={() =>
                                              handleTextSelection({
                                                fileName,
                                                list,
                                              })
                                            }
                                            value={list.fileName}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "A" }}
                                          />
                                        </ListItemIcon>
                                        <Tooltip
                                          title={fileName.replace(".txt", "")}
                                        >
                                          <ListItemText
                                            primary={truncatedFileName.replace(
                                              ".txt",
                                              ""
                                            )}
                                          />
                                        </Tooltip>
                                        <div
                                          className={styles.icons}
                                          onClick={() =>
                                            handleConfirmation(list._id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </div>
                                        {openConfirmation && (
                                          <Dialog
                                            open={
                                              openConfirmation &&
                                              selectedDeleteItemId === list._id
                                            }
                                            onClose={handleClose}
                                          >
                                            <DialogContent>
                                              <DialogContentText id="alert-dialog-description">
                                                Are you sure you want to delete{" "}
                                                {list.fileName.replace(
                                                  ".txt",
                                                  ""
                                                )}
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions
                                              className={
                                                styles.confirmation_dialog
                                              }
                                            >
                                              <Button
                                                onClick={handleClose}
                                                color="primary"
                                                className={
                                                  styles.confirm_button
                                                }
                                              >
                                                No
                                              </Button>
                                              <Button
                                                className={
                                                  styles.confirm_button
                                                }
                                                onClick={() =>
                                                  handleYesButtonClick(list)
                                                }
                                                color="primary"
                                                autoFocus
                                              >
                                                Yes
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        )}
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })
                          )
                        )}
                      </List>
                      {/* Url */}
                      <List>
                        {loading || profileLoading ? (
                          <CirculerLoader />
                        ) : (
                          buttonConfigs.map(
                            (config, index) =>
                              activeButton === config.label &&
                              config.label === "Url" &&
                              allDocumentsData?.data
                                ?.filter((doc) => doc.type === "url")
                                .map((list, i) => {
                                  const fileName = list.fileName;
                                  const truncatedFileName =
                                    fileName.length > 20
                                      ? fileName.substring(0, 20) + "..."
                                      : fileName;

                                  return (
                                    <ListItem
                                      disablePadding
                                      sx={{
                                        backgroundColor:
                                          selectedURL === list.fileName
                                            ? "#202346"
                                            : "#fff",
                                        color:
                                          selectedURL === list.fileName
                                            ? "white"
                                            : "black",
                                      }}
                                      key={i}
                                    >
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <Radio
                                            onClick={() =>
                                              handleURLSelection({
                                                fileName,
                                                list,
                                              })
                                            }
                                            checked={
                                              selectedURL === list.fileName
                                            }
                                            value={list.fileName}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "A" }}
                                          />
                                        </ListItemIcon>
                                        <Tooltip
                                          title={fileName.replace(".doc", "")}
                                        >
                                          <ListItemText
                                            primary={truncatedFileName.replace(
                                              ".doc",
                                              ""
                                            )}
                                          />
                                        </Tooltip>
                                        <div
                                          className={styles.icons}
                                          onClick={() =>
                                            handleConfirmation(list._id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </div>
                                        {openConfirmation && (
                                          <Dialog
                                            open={
                                              openConfirmation &&
                                              selectedDeleteItemId === list._id
                                            }
                                            onClose={handleClose}
                                          >
                                            <DialogContent>
                                              <DialogContentText id="alert-dialog-description">
                                                Are you sure you want to delete{" "}
                                                {list.fileName.replace(
                                                  ".doc",
                                                  ""
                                                )}
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions
                                              className={
                                                styles.confirmation_dialog
                                              }
                                            >
                                              <Button
                                                onClick={handleClose}
                                                color="primary"
                                                className={
                                                  styles.confirm_button
                                                }
                                              >
                                                No
                                              </Button>
                                              <Button
                                                className={
                                                  styles.confirm_button
                                                }
                                                onClick={() =>
                                                  handleYesButtonClick(list)
                                                }
                                                color="primary"
                                                autoFocus
                                              >
                                                Yes
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        )}
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })
                          )
                        )}
                      </List>
                    </nav>
                  </Box>
                )}
              </div>
            </div>
          </Container>
        </Paper>
      </FormWrapper>
      <AddTrainModal />
    </TrainFormWrapper>
  );
};

export default TrainFrom;
