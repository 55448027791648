import { ThemeProvider, createTheme } from "@mui/material";

const NavbarWrapper = ({ children }) => {
  const navbar_theme = createTheme({
    components: {
      MuiFab: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            backgroundColor: "#ffffff !important",
            color: "black",
            fontWeight: "550",
            textTransform: "capitalize",
            height: "100%",
            borderRadius: "50%",
            width: "3%",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--white)",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={navbar_theme}>{children}</ThemeProvider>;
};

export { NavbarWrapper };
