import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { Select } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const EditAPIModalParams = ({ rows, handleKeySelect }) => {
  const { singleDataResponse } = useSelector(
    (state) => state.trainAndContentReducer
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyItemClick = (key) => {
    handleKeySelect(key);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        startIcon={
          <AddIcon
            style={{ display: "flex", position: "relative", right: "20px" }}
          />
        }
        endIcon={
          <KeyboardArrowDownIcon
            style={{ display: "flex", position: "relative", left: "20px" }}
          />
        }
        style={{
          background: "white",
          color: "black",
          border: "1px solid #c4c4c4",
          padding: "10px 42px",
          display: "flex",
          textTransform: "capitalize",
        }}
      >
        Add Entities
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {singleDataResponse?.apiData?.params &&
          Object.entries(singleDataResponse?.apiData?.params).map(
            ([key, value], i) => (
              <MenuItem key={i} onClick={() => handleKeyItemClick(key)}>
                {key}
              </MenuItem>
            )
          )}
      </StyledMenu>
    </div>
  );
};
