import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { TrainFromContentWrapperBox } from "../../trainFromContent/styles/MuiStyle";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { getAnalytics_Data } from "../../../redux/slices/analyticsSlice";
import styles from "../styles/style.module.scss";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const color1 = "#baddf8";
const yAxisTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const formatYAxis = (tickItem) => {
  return tickItem.toString();
};

const formatChartData = (data) => {
  return data.map(([key, value]) => ({
    name: key.replace(/[_-]/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
    value,
  }));
};

const AreaChartPage = ({ unitOfTime, numericValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const endDateData = `${year}-${month}-${day}`;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, analyticsData } = useSelector(
    (state) => state.analyticsReducer
  );
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const initialStartDate = new Date(endDateData);
  initialStartDate.setDate(initialStartDate.getDate() - 3);
  const startDateData = `${initialStartDate.getFullYear()}-${(
    initialStartDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${initialStartDate
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const [startDate, setStartDate] = useState(startDateData);
  const [endDate, setEndDate] = useState(endDateData);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const fetchData = () => {
    if (startDate && endDate) {
      dispatch(getAnalytics_Data({ startDate, endDate, navigate }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (analyticsData) {
      setData1(
        formatChartData(
          Object.entries(analyticsData?.categoryWiseRequests || {})
        )
      );
      setData2(
        formatChartData(Object.entries(analyticsData?.sourceWiseRequests || {}))
      );
      setData3(
        formatChartData(Object.entries(analyticsData?.actionWiseRequests || {}))
      );
    }
  }, [analyticsData]);

  return (
    <>
      <TrainFromContentWrapperBox>
        <Box
          sx={{ display: "flex", flexDirection: "column", margin: "20px 0" }}
        >
          <Grid container spacing={isSmallScreen ? 2 : 3}>
            <Grid item xs={12} md={6} className={styles.startDate}>
              <Grid container spacing={2} className={styles.startDate}>
                <Grid item>
                  <label htmlFor="startDatePicker">
                    <h3>Start Date:</h3>
                  </label>
                </Grid>
                <Grid item>
                  <input
                    type="date"
                    id="startDatePicker"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className={styles.datepicker}
                  />
                </Grid>
                <Grid item>
                  <label htmlFor="endDatePicker">
                    <h3>End Date:</h3>
                  </label>
                </Grid>
                <Grid item>
                  <input
                    type="date"
                    id="endDatePicker"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className={styles.datepicker}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: isSmallScreen ? "center" : "end",
                alignItems: "center",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #c4c4c4",
                  padding: "5px 20px",
                  borderRadius: "4px",
                  marginBottom: isSmallScreen ? "10px" : "0",
                }}
              >
                <label htmlFor="startDatePicker">
                  <h3>Total Requests:</h3>
                </label>
                <div className={styles.total_request}>
                  {analyticsData?.totalRequests}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: isSmallScreen ? "0" : "35px",
                  border: "1px solid #c4c4c4",
                  padding: "5px 20px",
                  borderRadius: "4px",
                }}
              >
                <label htmlFor="endDatePicker">
                  <h3>Average Response Time:</h3>
                </label>
                <div className={styles.total_request}>
                  {numericValue}
                  <span>{unitOfTime}</span>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TrainFromContentWrapperBox>
      <h3 style={{ margin: "20px 0px" }}>Category wise request</h3>
      <ResponsiveContainer
        width="100%"
        height={266}
        className={styles.pieChartContainer}
      >
        <BarChart
          data={data1}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 0,
          }}
          style={{ paddingRight: "30px" }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis
            ticks={yAxisTicks}
            tick={{ fontSize: 12 }}
            tickFormatter={formatYAxis}
          />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: 12 }} />
          <Bar dataKey="value" fill={color1} />
        </BarChart>
      </ResponsiveContainer>
      <h3 style={{ margin: "20px 0px" }}>Source wise request</h3>
      <ResponsiveContainer
        width="100%"
        height={266}
        className={styles.pieChartContainer}
      >
        <BarChart
          data={data2}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 0,
          }}
          style={{ paddingRight: "30px" }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis
            ticks={yAxisTicks}
            tick={{ fontSize: 12 }}
            tickFormatter={formatYAxis}
          />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: 12 }} />
          <Bar dataKey="value" fill={color1} />
        </BarChart>
      </ResponsiveContainer>
      <h3 style={{ margin: "20px 0px" }}>Action wise request</h3>
      <ResponsiveContainer
        width="100%"
        height={266}
        className={styles.pieChartContainer}
      >
        <BarChart
          data={data3}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 0,
          }}
          style={{ paddingRight: "30px" }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis
            ticks={yAxisTicks}
            tick={{ fontSize: 12 }}
            tickFormatter={formatYAxis}
          />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: 12 }} />
          <Bar dataKey="value" fill={color1} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default AreaChartPage;
