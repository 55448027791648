import {
  Button,
  Collapse,
  Fab,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Chat from "../../pages/chat/Chat";
import expandImg from "../../assets/expand.svg";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import SettingsIcon from "@mui/icons-material/Settings";
import { CgPlayListCheck } from "react-icons/cg";
import { IoMdPerson } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import Integration from "../../pages/integration/Integration";
import Analytics from "../../pages/analytics/Analytics";
import "../sidebar/styles/sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import UserManagement from "../../pages/userManagement/UserManagement";
import TrainFromContent from "../../pages/trainFromContent/TrainFromContent";
import {
  setSelectedItem,
  setToggleClose,
  toggleSidebar,
} from "../../redux/slices/sidebarSlice";
import TitleIcon from "@mui/icons-material/Title";
import { SidebarWrapper } from "./styles/MuiStyles";
import Profile from "../../pages/profile/Profile";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditRoles from "../../pages/editRoles/EditRoles";
import { LiaUserEditSolid } from "react-icons/lia";
import AppSecureStorage from "../../services/storage/secureStorage";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Organization from "../../pages/orgnization/Organization";

const storage = new AppSecureStorage();

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subMenuOpen, setSuMenuOpen] = useState(false);
  const { isOpen, selectedItem } = useSelector((state) => state.sidebarReducer);
  const [open, setOpen] = useState(false);

  const handleItemClick = (item) => {
    dispatch(setSelectedItem(item));
  };

  const handleClick = () => {
    setOpen(!open);
    setSuMenuOpen(!subMenuOpen);
  };

  const selectedRole = storage.get("role");
  const clientId = useSelector((state) => state.authReducer.clientId);
  const credentials = useSelector((state) => state.authReducer.credentials);

  let permissions = JSON.parse(storage.get("permissions"));

  const menuItem = [
    permissions &&
      permissions["Chat"] &&
      permissions["Chat"]?.includes("Get") && {
        path: "/chat",
        name: "Chat",
        icon: <ChatBubbleIcon />,
        component: <Chat />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["Integration"] &&
      permissions["Integration"]?.includes("Get") && {
        path: "/integration",
        name: "Integration",
        icon: <CgPlayListCheck />,
        component: <Integration />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["Profile"] &&
      permissions["Profile"]?.includes("Get") && {
        path: "/profile",
        name: "Profile",
        icon: <IoMdPerson />,
        component: <Profile />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["Role"] &&
      permissions["Role"]?.includes("Get") && {
        path: "/editRoles",
        name: "Edit Roles",
        icon: <LiaUserEditSolid style={{ width: "20px", height: "20px" }} />,
        component: <EditRoles />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["User"] &&
      permissions["User"]?.includes("Get") && {
        path: "/userManagement",
        name: "User Management",
        icon: <ManageAccountsIcon />,
        component: <UserManagement />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["Analytics"] &&
      permissions["Analytics"]?.includes("Get") && {
        path: "/analytics",
        name: "Analytics",
        icon: <DashboardIcon />,
        component: <Analytics />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["Organisation"] &&
      permissions["Organisation"]?.includes("Get") && {
        path: "/organization",
        name: "Organization",
        icon: <ChatBubbleIcon />,
        component: <Organization />,
        onClick: () => setOpen(false),
        permission: true,
      },
    permissions &&
      permissions["TrainContent"] &&
      permissions["TrainContent"]?.includes("Get") && {
        path: "/trainFromContent",
        name: "Train",
        icon: <TextSnippetIcon />,
        component: <TrainFromContent />,
        submenu: [{ name: "Starred", permission: true }],
        permission: true,
      },
  ].filter(Boolean);

  const handleLogout = async () => {
    storage.clearStorage();
    navigate("/");
    // storage.setItem("token", "");
    // storage.setItem("role", "");
    // storage.setItem("domain", "");
    // storage.setItem("id", "");
    // storage.setItem("name", "");
    // const body = {
    //   clientId: clientId,
    //   credentials: credentials,
    // };
    // try {
    //   const response = await auth.logout(body);
    //   if (response) {
    //     toast.success("Logged out successfully");
    //     navigate("/");
    //   } else {
    //     throw new Error();
    //   }
    // } catch (error) {
    //   if (error?.response && error?.response.status === 401) {
    //     toast("wrong credentials");
    //   }
    // }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1920) {
        dispatch(setToggleClose());
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  return (
    <SidebarWrapper>
      <div className="container">
        <div style={{ width: isOpen ? "300px" : "70px" }} className="sidebar">
          <div className="top_section">
            {/* <div className="sidebar_section"> */}
            <div className="bars">
              <div onClick={() => dispatch(toggleSidebar())}>
                <img className="expandImg" src={expandImg} />
              </div>
            </div>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "70vh",
              scrollbarWidth: "thin",
            }}
          >
            {menuItem.map((item, index) => {
              // Check if the permission is true for the current menu item
              const hasPermission = item.permission;

              return hasPermission ? (
                <List
                  sx={{
                    width: "100%",
                  }}
                  onClick={item.onClick}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  key={index}
                >
                  <Tooltip
                    title={item.name}
                    placement="right-end"
                    disableHoverListener={isOpen}
                  >
                    <NavLink
                      to={item.path}
                      key={index}
                      className="link"
                      activeClassName="active"
                    >
                      <div
                        onClick={() => {
                          item.name === "Train" && handleClick();
                        }}
                        className="icon"
                      >
                        {item.icon}
                      </div>
                      <div
                        onClick={() => {
                          item.name === "Train" && handleClick();
                        }}
                        className={
                          item.name === "Train"
                            ? "text_link_train"
                            : "link_text"
                        }
                      >
                        <div style={{ display: isOpen ? "block" : "none" }}>
                          {item.name}
                        </div>
                        {item.name === "Train" && (
                          <>
                            <div style={{ display: isOpen ? "block" : "none" }}>
                              {open ? <ExpandLess /> : <ExpandMore />}
                            </div>
                          </>
                        )}
                      </div>
                    </NavLink>
                  </Tooltip>
                </List>
              ) : null;
            })}
            {permissions &&
              permissions["TrainContent"] &&
              permissions["TrainContent"]?.includes("Get") && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List className="list-data" disablePadding>
                    <Tooltip
                      title="Content"
                      placement="right-end"
                      disableHoverListener={isOpen}
                    >
                      <ListItemButton
                        className={
                          selectedItem === "content"
                            ? "content_selected"
                            : "content"
                        }
                        onClick={() => handleItemClick("content")}
                      >
                        <TitleIcon />
                        <ListItemText
                          primary="Content"
                          style={{ display: isOpen ? "block" : "none" }}
                        />
                      </ListItemButton>
                    </Tooltip>
                    <Tooltip
                      title="Custom"
                      placement="right-end"
                      disableHoverListener={isOpen}
                    >
                      <ListItemButton
                        className={
                          selectedItem === "custom"
                            ? "custom_selected"
                            : "custom"
                        }
                        onClick={() => handleItemClick("custom")}
                      >
                        <SettingsIcon />
                        <ListItemText
                          primary="Custom"
                          style={{ display: isOpen ? "block" : "none" }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </List>
                </Collapse>
              )}
          </div>

          {/* </div> */}

          <Fab>
            <Button
              style={{
                backgroundColor: "white",
                color: "white",
                boxShadow: "none",
              }}
              variant="contained"
              fullWidth
            >
              <Tooltip
                title="Logout"
                placement="right-end"
                disableHoverListener={isOpen}
              >
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#8176f2",
                    color: "white",
                    display: "flex",
                    justifyContent: isOpen && "space-between",
                    textTransform: "capitalize",
                    padding: isOpen && "10px",
                  }}
                  onClick={() => handleLogout()}
                >
                  {isOpen ? <div style={{ fontSize: "17px" }}>Logout</div> : ""}
                  <div>
                    <CiLogout
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </div>
                </Button>
              </Tooltip>
            </Button>
          </Fab>
        </div>
        <main>{children}</main>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
