import React from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMethod } from "../../../../redux/slices/trainAndContentSlice";

export const MethodDropdown = ({ handleMethodChange }) => {
  const dispatch = useDispatch();
  const { method } = useSelector((state) => state.trainAndContentReducer);

  return (
    <Box width="100%">
      <Box color="#323454" fontWeight="bold">
        Methods
      </Box>
      <FormControl fullWidth>
        <Select
          fullWidth
          value={method}
          onChange={handleMethodChange}
          inputProps={{
            name: "max-width",
            id: "max-width",
          }}
        >
          <MenuItem value="POST">POST</MenuItem>
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem>
          <MenuItem value="HEAD">HEAD</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
