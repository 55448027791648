import React, { useEffect, useState } from "react";
import styles from "./styles/style.module.scss";
import ChatNav from "../../components/chatNav/ChatNav";
import {
  ButtonBase,
  Card,
  Chip,
  Grid,
  styled,
  CssBaseline,
  Box,
  Tooltip,
  Container,
  CircularProgress,
  Pagination,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Avatar from "@mui/material/Avatar";
import { ProfileWrapper } from "./styles/MuiStyle";
import Button from "../../components/common/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import keka from "../../assets/keka.png";
import dbf from "../../assets/dbf.png";
import jira from "../../assets/Jira.svg";
import tft from "../../assets/tft.png";
import salesForce from "../../assets/Saleforce.png";
import Telegram from "../../assets/Telegram.png";
import googleCalender from "../../assets/googleCalender.png";
import {
  getProfileDetails,
  updateProfileDetails,
} from "../../redux/slices/profileSlice";
import ProfileFields from "./ProfileFields";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import CirculerLoader from "../../components/loader/circulerLoader/CirculerLoader";
import {
  getAllUploadedDocumentsProfile,
  setPage,
} from "../../redux/slices/trainAndContentSlice";
import LinkIcon from "@mui/icons-material/Link";
import AppSecureStorage from "../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  height: "100%",
});
const storage = new AppSecureStorage();
const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissions = JSON.parse(storage.get("permissions"));
  let editProfileMenuItem =
    permissions &&
    permissions["Profile"] &&
    permissions["Profile"]?.includes("Edit");
  const { user } = useSelector((state) => state.profileReducer);
  const allDocumentsData = useSelector(
    (state) => state.trainAndContentReducer.allDocument
  );
  const { page, limit } = useSelector((state) => state.trainAndContentReducer);
  let totalPages = Math.ceil(allDocumentsData?.total / limit);
  const [value, setValue] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState({
    email: "",
    contactNo: "",
    gender: "",
    experience: "",
    name: "",
  });

  useEffect(() => {
    // Update formData whenever user data changes
    setFormData({
      email: user?.email || "",
      contactNo: user?.contactNo || "",
      gender: user?.gender || "",
      experience: user?.experience || "",
      name: user?.name || "",
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleProfileDetails = () => {
    if (!isMobileNumberValid(formData?.contactNo)) {
      toast.error(
        "Mobile number should not be more than or less than 10 digits"
      );
    } else {
      const body = {
        gender: formData?.gender,
        contactNo: String(formData?.contactNo),
        experience: String(formData?.experience),
      };
      dispatch(
        updateProfileDetails({
          body,
          navigate: navigate,
        })
      );
    }
  };
  const handleResetProfile = () => {
    setFormData({
      contactNo: "",
      gender: "",
      experience: "",
      name: "",
    });
  };

  // Validation function for mobile number
  const isMobileNumberValid = (mobileNumber) => {
    return /^\d{10,10}$/.test(mobileNumber);
  };

  const handleDownload = async (fileUrl, fileName) => {
    let modifiedFileName = fileName;
    if (fileName.includes(".txt")) {
      modifiedFileName = fileName.replace(/\.(txt)$/i, ".pdf");
    }
    const downloadingToastId = toast.success("File Downloading...", {
      isLoading: true,
    });

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = modifiedFileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      toast.update(downloadingToastId, {
        render: "Download Completed!",
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.update(downloadingToastId, {
        render: "Error downloading PDF!",
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };

  const renderDownloadItems = () => {
    return (
      allDocumentsData &&
      allDocumentsData?.data?.map((item, index) => {
        let modifiedFileName = item.fileName;
        if (item.fileName.includes(".txt") || item.fileName.includes(".pdf")) {
          modifiedFileName = item.fileName.replace(/\.(txt|pdf)$/i, "");
        }

        return (
          <div key={index} className={styles.socialLinkContainer}>
            <div>
              <div className={styles.skillsWraper}>
                {item.type !== "url" && (
                  <div style={{ marginLeft: "10px" }}>{modifiedFileName}</div>
                )}
                {
                  <a
                    href={item.urlLink}
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                  >
                    {item.type === "url" && item.urlLink}
                  </a>
                }
              </div>
            </div>
            {item.type !== "url" ? (
              <Button
                className={styles.download_btn}
                onClick={() => handleDownload(item.location, item.fileName)}
              >
                <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                  <DownloadIcon />
                </span>
              </Button>
            ) : (
              <Tooltip title="Url Copied" placement="top">
                <Button
                  className={styles.download_btn}
                  onClick={() => copyToClipboard(item.urlLink)}
                >
                  <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                    <LinkIcon />
                  </span>
                </Button>
              </Tooltip>
            )}
          </div>
        );
      })
    );
  };

  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  useEffect(() => {
    dispatch(getProfileDetails(navigate));
    dispatch(
      getAllUploadedDocumentsProfile({
        page: page,
        limit: limit,
        navigate: navigate,
      })
    );
  }, [dispatch, page]);
  return (
    <ProfileWrapper>
      <div>
        <ChatNav title={"Profile"} />
        <Card>
          <div className={styles.profile_wrapper}>
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12} md={3.5}>
                <div>
                  <ButtonBase>
                    <Img alt="complex" src={tft} />
                  </ButtonBase>
                </div>
              </Grid>
              <Grid item xs={12} md={8.5}>
                <div>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <div className={styles.profile_header}>
                        <div className={styles.user_name}>
                          {user && user.name}
                        </div>
                        {/* <Grid item className="dflex-justfy-center">
                          <LocationOnIcon />
                          <span>Gurgaon , India</span>
                        </Grid> */}
                      </div>

                      {/* Profile fields */}
                      {user?.email?.length > 0 ? (
                        <ProfileFields
                          handleChange={handleChange}
                          formData={formData}
                        />
                      ) : (
                        <Container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "30vh",
                          }}
                        >
                          <CircularProgress disableShrink />
                        </Container>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={styles.integration_wrapper}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                label="Integration"
                                value="1"
                                className={styles.text}
                              />
                              <Tab
                                label="Uploaded Documents"
                                value="2"
                                className={styles.text}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            {user?.email?.length > 0 ? (
                              user?.integrations
                                ?.filter((ele) => ele.isActivated)
                                .map((ele, index) => (
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 0 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                    className={styles.integration_wrap}
                                  >
                                    <Grid
                                      item
                                      xs={2}
                                      sm={4}
                                      md={0.6}
                                      key={index}
                                    >
                                      {ele.name === "dbf" ? (
                                        <Avatar alt="Natacha" src={dbf} />
                                      ) : ele.name === "keka" ? (
                                        <Avatar alt="Natacha" src={keka} />
                                      ) : ele.name === "jira" ? (
                                        <Avatar alt="Natacha" src={jira} />
                                      ) : ele.name === "google_calendar" ? (
                                        <Avatar
                                          alt="Natacha"
                                          src={googleCalender}
                                        />
                                      ) : ele.name === "salesforce" ? (
                                        <Avatar
                                          alt="Natacha"
                                          src={salesForce}
                                        />
                                      ) : ele.name === "telegram" ? (
                                        <Avatar alt="Natacha" src={Telegram} />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={4}
                                      md={2}
                                      key={index}
                                      mt={1}
                                      ml={3}
                                    >
                                      <div
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {ele.name === "dbf"
                                          ? "DBF"
                                          : ele.name === "google_calendar"
                                          ? "Google Calendar"
                                          : ele.name}
                                      </div>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={3} key={index}>
                                      <Chip label={ele.website} />
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={5} key={index}>
                                      {ele.description}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              <Container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "30vh",
                                }}
                              >
                                <CircularProgress disableShrink />
                              </Container>
                            )}
                          </TabPanel>
                          <TabPanel value="2">
                            {renderDownloadItems()}
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                marginTop: "10px",
                              }}
                            >
                              <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                className={styles.pageination_data}
                              />
                            </div>
                          </TabPanel>
                        </TabContext>
                      </Box>
                      <div className={styles.bottom_btns}>
                        <Button
                          disabled={editProfileMenuItem ? false : true}
                          className={styles.reset_btn}
                          onClick={handleResetProfile}
                        >
                          Reset Profile
                        </Button>
                        <Button
                          disabled={editProfileMenuItem ? false : true}
                          className={styles.save_btn}
                          onClick={handleProfileDetails}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
