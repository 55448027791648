import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 260,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const AddResponseKeyDropdown = ({ setSuggestionValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelectedItems] = useState("");
  const open = Boolean(anchorEl);
  const { responseKeyData } = useSelector(
    (state) => state.trainAndContentReducer
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (suggestion) => {
    setSuggestionValue((prevValue) => prevValue + ` {{${suggestion}}}`);
    setAnchorEl(null);
  };

  const isSelected = (item) => selectedItems.indexOf(item) !== -1;

  return (
    <div>
      <Button
        onClick={handleClick}
        startIcon={
          <AddIcon
            style={{ display: "flex", position: "relative", right: "20px" }}
          />
        }
        endIcon={
          <KeyboardArrowDownIcon
            style={{ display: "flex", position: "relative", left: "20px" }}
          />
        }
        style={{
          background: "white",
          color: "black",
          border: "1px solid #c4c4c4",
          padding: "10px 42px",
          display: "flex",
          textTransform: "capitalize",
        }}
      >
        App Response Key
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {responseKeyData &&
          responseKeyData.map((ele) => (
            <MenuItem
              key={ele}
              selected={isSelected(ele)}
              onClick={() => handleSelect(ele)}
            >
              {ele}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};
