import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const ActiveWrapper = ({ children }) => {
  const activeModal_theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            width: "514px !important",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            width: "420px !important",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            color: "white !important",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            "&.Mui-checked": {
              color: "#47a3a4 !important",
            },
          },
          track: {
            backgroundColor: "white !important",
            border: "1px solid gray !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={activeModal_theme}>{children}</ThemeProvider>;
};

export { ActiveWrapper };
