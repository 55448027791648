import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import styles from "./styles/activeModal.module.scss";
import dropDown from "../../assets/dropdown.svg";
import { ActiveWrapper } from "./styles/MuiStyles";
import { useDispatch, useSelector } from "react-redux";
import { addNewRoleData } from "../../redux/slices/editRoleSlice";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

const ActiveDeactiveModal = ({
  isActive,
  pageName,
  onClose,
  addNewRole,
  setAddNewRole,
  userData,
  setUserData,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setChecked] = React.useState({});
  const [isDropdownOpen, setIsDropdownOpen] = React.useState({});
  const [isAnyMethodSelected, setIsAnyMethodSelected] = React.useState(false);
  const role = useSelector((state) => state.userManagementReducer.getRolesData);
  const getPermission = useSelector(
    (state) => state.userManagementReducer.getAllPermission
  );
  const { page, limit } = useSelector((state) => state.editRoleReducer);

  const handleDropdownClick = (label) => {
    setIsDropdownOpen((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const handleSwitchChange = (label) => {
    setChecked((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));

    // Ensure the dropdown opens when the switch is turned on
    setIsDropdownOpen((prev) => ({
      ...prev,
      [label]: !isChecked[label],
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (label, method) => (event) => {
    const { checked } = event.target;

    setChecked((prev) => ({
      ...prev,
      [label]: {
        ...prev[label],
        [method]: checked,
      },
    }));

    setAddNewRole((prev) => {
      const updatedPermissions = { ...prev.permissions };

      if (checked) {
        updatedPermissions[label] = updatedPermissions[label]
          ? [...updatedPermissions[label], method]
          : [method];
      } else {
        updatedPermissions[label] = updatedPermissions[label].filter(
          (item) => item !== method
        );
      }

      return {
        ...prev,
        permissions: updatedPermissions,
      };
    });
    setUserData((prev) => {
      const updatedPermissions = { ...prev.permissions };

      if (checked) {
        updatedPermissions[label] = updatedPermissions[label]
          ? [...updatedPermissions[label], method]
          : [method];
      } else {
        updatedPermissions[label] = updatedPermissions[label].filter(
          (item) => item !== method
        );
      }

      return {
        ...prev,
        permissions: updatedPermissions,
      };
    });
  };

  const SwitchSection = ({ label, methods }) => (
    <Box mt={2}>
      <div
        className={
          isChecked[label] ? styles.active_section : styles.inactive_section
        }
      >
        <div className={styles.text}>
          <img
            src={dropDown}
            alt="dropdown"
            onClick={() => handleDropdownClick(label)}
            style={{ cursor: "pointer" }}
          />
          <div>{label}</div>
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={!!isChecked[label]}
                onChange={() => handleSwitchChange(label)}
              />
            }
          />
        </FormGroup>
      </div>
      {isDropdownOpen[label] && (
        <div>
          <ul
            style={{
              display: "flex",
              margin: "0.2rem 1.5rem 0rem 1.5rem",
              justifyContent: "space-between",
              background: "#3e3e3e",
              paddingRight: "0.6rem",
              color: "white",
            }}
          >
            {methods.map((method, index) => (
              <div key={index}>
                <Checkbox
                  checked={isChecked[label] && isChecked[label][method]}
                  onChange={handleChange(label, method)}
                  value={method}
                />
                <span style={{ marginLeft: "5px" }}>{method}</span>
              </div>
            ))}
          </ul>
        </div>
      )}
    </Box>
  );

  const handleSubmit = () => {
    dispatch(
      addNewRoleData({
        body: pageName === "editRoles" ? addNewRole : "",
        page: page,
        limit: limit,
        navigate: navigate,
      })
    );
    handleClose();
  };

  const handleBackArrow = () => {
    setOpen(true);
    handleClose();
  };

  useEffect(() => {
    const anySelected = Object.values(isChecked).some((section) =>
      Object.values(section).some((checked) => checked)
    );
    setIsAnyMethodSelected(anySelected);
  }, [isChecked]);

  return (
    <ActiveWrapper>
      <Dialog open={isActive} onClose={handleClose}>
        <Box className={styles.header_box}>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={handleBackArrow}
          />
          <DialogTitle className={styles.header}>
            {pageName === "editRoles" ? "Activate/Deactivate Features" : ""}
          </DialogTitle>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
        {Object.entries(getPermission).map(([section, methods], index) => (
          <SwitchSection key={index} label={section} methods={methods} />
        ))}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            background: isAnyMethodSelected ? "#8176f2" : "#c4c4c4",
            margin: "1.5rem",
            borderRadius: "5px",
            cursor: !isAnyMethodSelected ? "not-allowed" : "pointer",
            color: "white",
            padding: "15px 0px",
          }}
          onClick={isAnyMethodSelected ? handleSubmit : null}
        >
          Submit
        </DialogActions>
      </Dialog>
    </ActiveWrapper>
  );
};

export default ActiveDeactiveModal;
