import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import styles from "./styles/style.module.scss";
import Button from "../../components/common/buttons/Button";
import {
  deleteUser,
  sendReminderEmail,
} from "../../redux/slices/userManagementSilce";
import { useDispatch, useSelector } from "react-redux";
import AppSecureStorage from "../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";

const AVAILABLE_ACTIONS = {
  DELETE: "delete",
  SEND_REMINDER: "sendReminder",
};
const storage = new AppSecureStorage();
export const UserManagementActions = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [promptedAction, setPromptedAction] = useState("");
  const [actionId, setActionId] = useState(null);
  const { page, limit, filterValue } = useSelector(
    (state) => state.userManagementReducer
  );
  let permissions = JSON.parse(storage.get("permissions"));
  let User =
    permissions &&
    permissions["User"] &&
    permissions["User"]?.includes("Delete");
  const handleMenuOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const promptAction = (id, action) => {
    setPromptedAction(action);
    setActionId(id);
  };

  const cancelAction = () => {
    setActionId(null);
  };

  const handleAction = () => {
    if (promptedAction === AVAILABLE_ACTIONS.DELETE) {
      dispatch(
        deleteUser({
          id: actionId,
          page: page,
          limit: limit,
          navigate: navigate,
          filterValue: filterValue?.toLowerCase(),
        })
      );
    } else if (promptedAction === AVAILABLE_ACTIONS.SEND_REMINDER) {
      dispatch(sendReminderEmail({ id: actionId, navigate: navigate }));
    }
    setActionId(null);
  };

  return (
    <>
      <IconButton aria-label="more" onClick={handleMenuOpen}>
        <MoreVertIcon className={styles.three_dots_icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {User && (
          <MenuItem
            onClick={promptAction.bind(
              this,
              user._id,
              AVAILABLE_ACTIONS.DELETE
            )}
            className={styles.menu_item}
          >
            <DeleteIcon style={{ color: "#fff" }} />
            <div>Delete</div>
          </MenuItem>
        )}

        {!user.verified ? (
          <MenuItem
            onClick={promptAction.bind(
              this,
              user._id,
              AVAILABLE_ACTIONS.SEND_REMINDER
            )}
            className={styles.menu_item}
          >
            <MailIcon style={{ color: "#fff" }} />
            <div>Send Reminder</div>
          </MenuItem>
        ) : null}
      </Menu>
      {actionId && (
        <Dialog open={!!actionId} onClose={cancelAction}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {promptedAction === AVAILABLE_ACTIONS.DELETE
                ? `Are you sure you want to delete ${user.name}?`
                : `Send reminder email to ${user.name} `}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.confirmation_dialog}>
            <Button
              onClick={cancelAction}
              color="primary"
              className={styles.confirm_button}
            >
              No
            </Button>
            <Button
              className={styles.confirm_button}
              onClick={handleAction}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
