// CommanModal.jsx
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { ActiveWrapper } from "../../../components/modal/styles/MuiStyles";
import { clearedSelectedRole } from "../../../redux/slices/editRoleSlice";
import { getAllPermissions } from "../../../redux/slices/userManagementSilce";
import { Input } from "../../../components/common/inputs/Input";
import { useNavigate } from "react-router-dom";

const EditRoleModal = ({
  open,
  setOpen,
  openPermission,
  formData,
  handleChangeRole,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.organizationReducer.loader);

  const selectedRoleData = useSelector(
    (state) => state.editRoleReducer.selectedRoleData
  );

  const handleClose = () => {
    setOpen(false);
    dispatch(clearedSelectedRole());
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleNext = () => {
    setOpen(false);
    openPermission(true);
    dispatch(getAllPermissions(navigate));
  };

  return (
    <ActiveWrapper>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={onSubmit}>
          <DialogTitle textAlign="center" color="#8176f2">
            Edit a new role
          </DialogTitle>
          <DialogContent>
            <div style={{ marginTop: "10px" }}>Role Name</div>
            <Input
              handleChange={handleChangeRole}
              name="name"
              value={formData.name}
              placeholder="Type here"
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              background: !formData.name ? "#c4c4c4" : "#8176f2",
              margin: "1.5rem",
              borderRadius: "5px",
              cursor: !formData.name ? "not-allowed" : "pointer",
              color: "white",
              padding: "15px 0px",
            }}
            onClick={!formData.name ? null : handleNext}
          >
            Next
          </DialogActions>
        </form>
      </Dialog>
    </ActiveWrapper>
  );
};

export default EditRoleModal;
