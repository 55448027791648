import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import styles from "./layout.module.scss";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/Navbar";

const Layout = ({ children, data }) => {
  const [isShow, setIsShow] = useState(true);
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(window.location.pathname.substring(1));
    if (data && data.length > 0) {
      const loginData = data.find((item) => item.path === currentPath);
      if (loginData && loginData.SideNav === false) {
        setIsShow(false);
      }
    } else {
      setIsShow(true);
    }
  }, [data, isShow, window]);

  return (
    <>
      {location.pathname === "/login" ? null : <Navbar />}
      <>
        {location.pathname === "/login" ? (
          <>{children}</>
        ) : (
          <div className={styles.page_layout}>
            <Sidebar>{children}</Sidebar>
          </div>
        )}
      </>
    </>
  );
};

export default Layout;
