import { ThemeProvider, createTheme } from "@mui/material";

const OrganizationWrapper = ({ children }) => {
  const organization_theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "start",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "0px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#8176f2 !important",
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: "0px !important",
            height: "74vh !important",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: "#202346 !important",
            color: "white !important",
            boxShadow: "none !important",
            border: "0px solid #202346 !important",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td, &:last-child th": { border: 0 },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            position: "absolute",
            right: "6px",
            bottom: "6px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "15px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none !important",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={organization_theme}>{children}</ThemeProvider>;
};

export { OrganizationWrapper };
