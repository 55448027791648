import React from "react";
import { Outlet, Navigate, createBrowserRouter } from "react-router-dom";
import { PAGE_ROUTES } from "../utils/contants";
import AuthGuard from "../auth/authGuard";
import UserManagement from "./userManagement/UserManagement";
import TrainFromContent from "./trainFromContent/TrainFromContent";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import EditRoles from "./editRoles/EditRoles";
import Organization from "./orgnization/Organization";

const Chat = React.lazy(() => import("./chat/Chat"));
const Integration = React.lazy(() => import("./integration/Integration"));
const Profile = React.lazy(() => import("./profile/Profile"));
const Analytics = React.lazy(() => import("./analytics/Analytics"));

const Error = React.lazy(() => import("./errorPage/Error"));
const Login = React.lazy(() => import("../pages/auth/Login"));
// const Users = React.lazy(() => import("./Users/Users"));
const Redirect = React.lazy(() => import("../components/redirect/redirect"));
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Redirect />,
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.CHAT,
    element: (
      <ProtectedRoute>
        <Chat />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.INTEGRATION,
    element: (
      <ProtectedRoute>
        <Integration />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.PROFILE,
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.ANALYTICS,
    element: (
      <ProtectedRoute>
        <Analytics />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Analytics />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.USERMANAGEMENT,
    element: (
      <ProtectedRoute>
        <UserManagement />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.ORGANIZATION,
    element: (
      <ProtectedRoute>
        <Organization />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.TRAINFROMECONTENT,
    element: (
      <ProtectedRoute>
        <TrainFromContent />
      </ProtectedRoute>
    ),
    SideNav: true,
  },
  {
    path: PAGE_ROUTES.EDITROLES,
    element: (
      <ProtectedRoute>
        <EditRoles />
      </ProtectedRoute>
    ),
    SideNav: true,
  },

  //     ],
  //   },
  {
    path: PAGE_ROUTES.LOGIN,
    element: <Login />,
    SideNav: false,
  },

  // { path: "*", element: <Error />, SideNav: false },
]);

export default routes;
