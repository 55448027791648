import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../../styles/style.module.scss";
import { TrainFromContentWrapper } from "../../styles/MuiStyle";
import ApplyLeaveStepper from "../applyLeave/Stepper";
import { CircularProgress, Menu, MenuItem, OutlinedInput } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSingleDataResponse,
  createCustomAPI,
  setAPIResponseData,
  setAPIResponseState,
  setEditApiRow,
  setEditBody,
  setEditCloseTestingApi,
  setEditMethod,
  setEditParams,
  setEditQuery,
  setEditUrl,
  updateCustom_data,
} from "../../../../redux/slices/trainAndContentSlice";
import { EditMethodDropdown } from "../CustomDropdown/EditMethodDropdown";
import { EditAPIModal } from "../CustomDropdown/EditAPIModal";
import { EditResponseKeyDropdown } from "../CustomDropdown/EditResponseKeyDropdown";
import { EditAPIModalQuery } from "../CustomDropdown/EditAPIModalQuery";
import { EditAPIModalParams } from "../CustomDropdown/EditAPIModalParams";
import { useNavigate } from "react-router-dom";

export default function EditAPITestingModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isEditTestingApiModal,
    apiResponseState,
    apiResponseData,
    singleDataResponse,
    pageCustom,
    editURL,
    limitCustom,
    loading,
    editmethod,
    editBody,
    editParams,
    editQuery,
    editableData,
  } = useSelector((state) => state.trainAndContentReducer);

  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [value, setValue] = useState("1");
  const [selectedElements, setSelectedElements] = useState(
    singleDataResponse?.apiData?.responseKeys
  );
  const [selectedKey, setSelectedKey] = useState("");
  const [keyValuePairs, setKeyValuePairs] = useState("");
  const [querykeyValuePairs, setqueryKeyValuePairs] = useState("");
  const [paramskeyValuePairs, setParamsKeyValuePairs] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [defaultValue, setDefaultValue] = useState(
    singleDataResponse?.apiData?.defaultMessage
  );
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionValue, setSuggestionValue] = useState(
    singleDataResponse?.apiData?.responseMessage
  );
  const [activate, setActivate] = useState(1);
  const [method, setMethod] = useState(singleDataResponse?.apiData?.method);
  const initialBody = singleDataResponse?.apiData?.body;
  const initialParams = singleDataResponse?.apiData?.params;
  const initialQuery = singleDataResponse?.apiData?.query;
  const formattedBody = {};
  const formattedQuery = {};
  const formattedParams = {};

  if (initialBody) {
    for (const key in initialBody) {
      formattedBody[key] = initialBody[key].replace(/\{\{(.+?)\}\}/g, "$1");
    }
  }
  if (initialParams) {
    for (const key in initialParams) {
      formattedParams[key] = initialParams[key].replace(/\{\{(.+?)\}\}/g, "$1");
    }
  }
  if (initialQuery) {
    for (const key in initialQuery) {
      formattedQuery[key] = initialQuery[key].replace(/\{\{(.+?)\}\}/g, "$1");
    }
  }

  const [inputValue, setInputValue] = useState(JSON.stringify(formattedBody));
  const [inputValueQuery, setInputValueQuery] = useState(
    JSON.stringify(formattedQuery)
  );
  const [inputValueParams, setInputValueParams] = useState(
    JSON.stringify(formattedParams)
  );
  const [selectedqueryKey, setSelectedqueryKey] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState({
    url: singleDataResponse?.apiData?.url,
    headers: JSON.stringify(singleDataResponse?.apiData?.headers),
    responseKeys: singleDataResponse?.apiData?.responseKeys,
    responseMessage: singleDataResponse?.apiData?.responseMessage,
    defaultMessage: singleDataResponse?.apiData?.defaultMessage,
  });
  const isValidJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackButtonStep3 = () => {
    setStep2(true);
    setStep3(false);
  };

  const handleNextStep1 = () => {
    const actualQuery = {};
    Object.keys(querykeyValuePairs).forEach((key) => {
      const value = querykeyValuePairs[key];
      const replacedValue = value.replace(
        /{{(.*?)}}/g,
        (match, p1) => querykeyValuePairs[p1.trim()] || p1
      );
      actualQuery[key] = replacedValue;
    });

    const actualParams = {};
    Object.keys(paramskeyValuePairs).forEach((key) => {
      const value = paramskeyValuePairs[key];
      const replacedValue = value.replace(
        /{{(.*?)}}/g,
        (match, p1) => paramskeyValuePairs[p1.trim()] || p1
      );
      actualParams[key] = replacedValue;
    });

    const replacedUrl = formData.url.replace(/:([^\/]+)/g, (match, p1) => {
      if (querykeyValuePairs.hasOwnProperty(p1.trim())) {
        return querykeyValuePairs[p1.trim()];
      }
      return match;
    });

    const replacedUrlQuery = formData.url.replace(/:([^\/]+)/g, (match, p1) => {
      if (actualParams.hasOwnProperty(p1.trim())) {
        return actualParams[p1.trim()];
      }
      return match;
    });

    let fullUrl;
    if (new URLSearchParams(actualQuery).toString()) {
      const queryString = new URLSearchParams(actualQuery).toString();
      fullUrl = replacedUrl + "?" + queryString;
    } else if (new URLSearchParams(actualParams).toString()) {
      fullUrl = replacedUrlQuery;
    } else {
      fullUrl = replacedUrl;
    }
    const data = {
      body: keyValuePairs,
      method: method,
      url: fullUrl,
      headers: formData.headers,
    };
    const lowercaseValue = method.toLowerCase();
    dispatch(setEditUrl(formData.url));
    dispatch(createCustomAPI(data));
    dispatch(setEditMethod(lowercaseValue));
    dispatch(setEditBody(keyValuePairs));
    dispatch(setEditQuery(querykeyValuePairs));
    dispatch(setEditParams(paramskeyValuePairs));
  };

  const handleNextStep2 = () => {
    setStep2(false);
    setStep3(true);
  };
  const handleNextStep3 = () => {
    const formattedBody = {};
    const formattedQuery = {};
    const formattedParams = {};
    // Iterate through the body query and params object and format the values
    Object.keys(editBody).forEach((key) => {
      formattedBody[key] = `{{${key}}}`;
    });

    Object.keys(editQuery).forEach((key) => {
      formattedQuery[key] = `{{${[key]}}}`;
    });
    Object.keys(editParams).forEach((key) => {
      formattedParams[key] = `{{${[key]}}}`;
    });
    const bodyData = {
      name: editableData?.name,
      entities: editableData?.entities,
      prompts: editableData?.prompts,
      apiData: {
        url: editURL,
        method: editmethod,
        headers: JSON.parse(formData.headers),
        query: formattedQuery,
        params: formattedParams,
        body: formattedBody,
        responseKeys: selectedElements,
        responseMessage: `${suggestionValue}`,
        defaultMessage: defaultValue,
      },
    };

    dispatch(
      updateCustom_data({
        id: singleDataResponse?._id,
        body: bodyData,
        page: pageCustom,
        limit: limitCustom,
        navigate: navigate,
      })
    );
    // Close the handler
    dispatch(clearSingleDataResponse());
    dispatch(setEditApiRow(null));
    dispatch(setAPIResponseState(false));
    setSelectedElements(null);
    setFormData({ ...formData, url: "", headers: "" });
    setSuggestions(null);
    setKeyValuePairs({});
    dispatch(setAPIResponseData([]));
    setStep1(false);
    setStep2(false);
    setStep3(false);
    handleClose();
    dispatch(setEditUrl(""));
    dispatch(setEditCloseTestingApi());
  };
  const handleClose = () => {};
  const handleBackButtonStep2 = () => {
    setStep1(true);
    setStep2(false);
  };
  useEffect(() => {
    setStep1(true);
  }, []);

  const handleDefaultMessageChange = (event) => {
    setDefaultValue(event.target.value);
  };

  const handleKeySelect = (key) => {
    setSelectedKey(key);
    const updatedPairs = { ...keyValuePairs, [key]: "" };
    setKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValue(updatedJSON);
  };
  const handleKeySelectQuery = (key) => {
    setSelectedqueryKey(key);
    const updatedPairs = { ...keyValuePairs, [key]: "" };
    setqueryKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValueQuery(updatedJSON);
  };
  const handleKeySelectParams = (key) => {
    setSelectedKey(key);
    const updatedPairs = { ...paramskeyValuePairs, [key]: "" };
    setParamsKeyValuePairs(updatedPairs);
    const updatedJSON = JSON.stringify(updatedPairs);
    setInputValueParams(updatedJSON);
  };

  const handleClick = (element) => {
    setSelectedElements((prevSelected) => {
      let updatedSelectedElements;
      if (prevSelected.includes(element)) {
        updatedSelectedElements = prevSelected.filter(
          (item) => item !== element
        );
      } else {
        updatedSelectedElements = [...prevSelected, element];
      }
      return updatedSelectedElements;
    });
  };
  useEffect(() => {
    if (apiResponseState) {
      setStep2(true);
      setStep1(false);
    }
  }, [apiResponseState, formData]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSuggestionValue(value);
    if (value.includes("@")) {
      // Filter suggestions based on the typed value after '@'
      const searchTerm = value.split("@")[1];
      const filteredSuggestions = selectedElements.filter((ele) =>
        ele.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Extract the last part of each suggestion
      const suggestions = filteredSuggestions.map((ele) => {
        return ele;
      });
      setSuggestions(suggestions);
      setAnchorEl(event.currentTarget);
    } else {
      setSuggestions([]);
      setAnchorEl(null);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSuggestionValue((prevValue) => prevValue + `{{${suggestion}}}`);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    try {
      const parsedValue = JSON.parse(value);
      setKeyValuePairs(parsedValue);
    } catch (error) {
      // Handle parsing errors if any
      setKeyValuePairs({});
    }
  };
  const handleValueChangeQuery = (event) => {
    const value = event.target.value;
    setInputValueQuery(value);
    try {
      const parsedValue = JSON.parse(value);
      setqueryKeyValuePairs(parsedValue);
    } catch (error) {
      // Handle parsing errors if any
      setqueryKeyValuePairs({});
    }
  };
  const handleValueChangeParams = (event) => {
    const value = event.target.value;
    setInputValueParams(value);
    try {
      const parsedValue = JSON.parse(value);
      setParamsKeyValuePairs(parsedValue);
    } catch (error) {
      // Handle parsing errors if any
      setParamsKeyValuePairs({});
    }
  };

  useEffect(() => {
    if (step1) {
      setActivate(1);
    } else if (step2) {
      setActivate(2);
    } else if (step3) {
      setActivate(3);
    }
  }, [step1, step2, step3]);

  return (
    <TrainFromContentWrapper>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isEditTestingApiModal}
        onClose={handleClose}
      >
        <>
          {step2 || step3 ? (
            <Box
              display="flex"
              justifyContent="space-between"
              padding="15px 15px 0px 15px"
              alignItems="center"
            >
              <KeyboardBackspaceIcon
                onClick={step2 ? handleBackButtonStep2 : handleBackButtonStep3}
                sx={{ cursor: "pointer" }}
              />
              <DialogTitle className={styles.api_test_text}>
                API Testing
              </DialogTitle>
              <div onClick={() => dispatch(setEditCloseTestingApi())}>
                <CloseIcon className={styles.cross_icon} />
              </div>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              padding="15px 15px 0px 15px"
              alignItems="center"
            >
              <DialogTitle className={styles.api_test_txt}>
                API Testing
              </DialogTitle>
              <div onClick={() => dispatch(setEditCloseTestingApi())}>
                <CloseIcon className={styles.cross_icon} />
              </div>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ApplyLeaveStepper
              step1={step1}
              step2={step2}
              step3={step3}
              value={activate}
            />
          </Box>
          {step2 ? (
            <Box sx={{ width: "100%", typography: "body1" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              ></Box>
              {loading ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="API Response"
                        value="1"
                        style={{ textTransform: "capitalize" }}
                      />
                      <Tab
                        label="Response Key's"
                        value="2"
                        style={{ textTransform: "capitalize" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel
                    style={{
                      height: "50vh",
                      overflowY: "scroll",
                      border: "1px solid #c4c4c4",
                      margin: "20px 35px 0px 35px",
                      borderRadius: "5px",
                    }}
                    value="1"
                  >
                    {apiResponseData?.map((ele, index) => {
                      return (
                        <Box
                          key={index}
                          className={styles.api_res}
                          onClick={() => handleClick(ele)}
                        >
                          {selectedElements?.includes(ele) ? (
                            <RemoveIcon className={styles.minus_api_icon} />
                          ) : (
                            <AddIcon className={styles.api_icon} />
                          )}
                          <Box className={styles.api_res_name}>{ele}</Box>
                        </Box>
                      );
                    })}
                  </TabPanel>
                  <TabPanel
                    style={{
                      height: "60vh",
                      overflowY: "scroll",
                      border: "1px solid #c4c4c4",
                      margin: "0px 35px 0px 35px",
                      borderRadius: "5px",
                    }}
                    value="2"
                  >
                    {selectedElements?.map((ele) => {
                      return (
                        <Box className={styles.api_res}>
                          <RemoveIcon className={styles.minus_api_icon} />
                          <Box className={styles.api_res_name}>{ele}</Box>
                        </Box>
                      );
                    })}
                  </TabPanel>
                </TabContext>
              )}
              <DialogActions
                className={
                  selectedElements?.length === 0
                    ? styles.next_btn_disable
                    : styles.next_btn
                }
              >
                <Button
                  onClick={
                    selectedElements?.length === 0 ? null : handleNextStep2
                  }
                >
                  Next
                </Button>
              </DialogActions>
            </Box>
          ) : step3 ? (
            <>
              {loading ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  ></Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    m={3}
                    mb={1}
                    alignItems="center"
                  >
                    <Box color="#323454" fontWeight="bold">
                      Response Message
                    </Box>
                    <EditResponseKeyDropdown
                      values={selectedElements}
                      setSuggestionValue={setSuggestionValue}
                    />
                  </Box>
                  <OutlinedInput
                    placeholder="Tip: you can add '@' to add response keys"
                    style={{
                      borderColor: "#c4c4c4",
                      margin: "0px 20px 0px 20px",
                      borderRadius: "5px",
                    }}
                    name="inputValue"
                    value={suggestionValue}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                      position: "start",
                    }}
                  />

                  <Menu
                    style={{ width: "100%" }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                  >
                    {suggestions?.map((option) => {
                      const lastDotIndex = option.lastIndexOf(".");
                      const valueAfterLastDot = option.substring(
                        lastDotIndex + 1
                      );
                      return (
                        <MenuItem
                          key={option}
                          onClick={() => handleSuggestionClick(option)}
                        >
                          {valueAfterLastDot}
                        </MenuItem>
                      );
                    })}
                  </Menu>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    m={3}
                    mb={1}
                    alignItems="center"
                  >
                    <Box color="#323454" fontWeight="bold">
                      Default Message
                    </Box>
                  </Box>
                  <OutlinedInput
                    placeholder="Type here"
                    style={{
                      borderColor: "#c4c4c4",
                      margin: "0px 20px 0px 20px",
                      borderRadius: "5px",
                    }}
                    name="defaultValue"
                    value={defaultValue}
                    onChange={handleDefaultMessageChange}
                  >
                    <div>Type here</div>
                  </OutlinedInput>
                </>
              )}
              <DialogActions
                className={
                  !defaultValue || !selectedElements
                    ? styles.next_btn_disable
                    : styles.next_btn
                }
              >
                <Button
                  onClick={
                    !defaultValue || !selectedElements ? null : handleNextStep3
                  }
                >
                  Next
                </Button>
              </DialogActions>
            </>
          ) : (
            step1 && (
              <>
                {loading ? (
                  <center>
                    <CircularProgress />
                  </center>
                ) : (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    ></Box>
                    <DialogContent>
                      <Box noValidate component="form">
                        <Box display="flex" gap="20px" width="100%">
                          <EditMethodDropdown
                            method={method}
                            setMethod={setMethod}
                          />
                          <Box width="100%">
                            <Box color="#323454" fontWeight="bold">
                              URL
                            </Box>
                            <OutlinedInput
                              value={formData?.url}
                              name="url"
                              fullWidth
                              placeholder="Enter URL"
                              onChange={handleChangeData}
                            />
                          </Box>
                        </Box>
                        <Box mt={2} color="#323454" fontWeight="bold">
                          Header
                        </Box>
                        <input
                          className={styles.auth_text}
                          placeholder="{Contnent-Type:application/json Autrization: bearer <token>}"
                          name="headers"
                          onChange={handleChangeData}
                          value={formData.headers}
                        />

                        {/* body */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={4}
                          mb={2}
                          alignItems="center"
                        >
                          <Box color="#323454" fontWeight="bold">
                            Body
                          </Box>
                          <EditAPIModal
                            rows={singleDataResponse?.apiData?.entities}
                            handleKeySelect={handleKeySelect}
                          />
                        </Box>
                        <input
                          className={
                            !isValidJSON(inputValue)
                              ? styles.error
                              : styles.auth_text
                          }
                          value={inputValue}
                          onChange={handleValueChange}
                        />
                        {!isValidJSON(inputValue) && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {`Please enter valid Json format`}
                          </div>
                        )}
                        {/* query */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={4}
                          mb={2}
                          alignItems="center"
                        >
                          <Box color="#323454" fontWeight="bold">
                            Query
                          </Box>
                          <EditAPIModalQuery
                            rows={singleDataResponse?.apiData?.query}
                            handleKeySelect={handleKeySelectQuery}
                          />
                        </Box>
                        <input
                          className={
                            !isValidJSON(inputValueQuery)
                              ? styles.error
                              : styles.auth_text
                          }
                          value={inputValueQuery}
                          onChange={handleValueChangeQuery}
                        />

                        {!isValidJSON(inputValueQuery) && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {`Please enter valid Json format`}
                          </div>
                        )}
                        {/* params */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={4}
                          mb={2}
                          alignItems="center"
                        >
                          <Box color="#323454" fontWeight="bold">
                            Param's
                          </Box>
                          <EditAPIModalParams
                            rows={singleDataResponse?.apiData?.params}
                            handleKeySelect={handleKeySelectParams}
                          />
                        </Box>
                        <input
                          className={
                            !isValidJSON(inputValueParams)
                              ? styles.error
                              : styles.auth_text
                          }
                          value={inputValueParams}
                          onChange={handleValueChangeParams}
                        />
                        {!isValidJSON(inputValueParams) && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {`Please enter valid Json format`}
                          </div>
                        )}
                      </Box>
                    </DialogContent>
                  </>
                )}
                <DialogActions
                  className={
                    !formData.url || !method
                      ? styles.next_btn_disable
                      : styles.next_btn
                  }
                >
                  <Button
                    onClick={!formData.url || !method ? null : handleNextStep1}
                  >
                    Next
                  </Button>
                </DialogActions>
              </>
            )
          )}
        </>
      </Dialog>
    </TrainFromContentWrapper>
  );
}
