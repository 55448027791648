import React, { useEffect, useState } from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import RateReviewIcon from "@mui/icons-material/RateReview";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getfeedbackData,
  setFeedbackStatus,
  setReview,
} from "../../redux/slices/chatSlice";
import { useNavigate } from "react-router-dom";
import styles from "../integration/styles/style.module.scss";
import { toast } from "react-toastify";

export const Feedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { review, jobId } = useSelector((state) => state.chatReducer);
  const [reviewStatus, setReviewStatus] = useState("");
  const [iconColor, setIconColor] = useState("");
  const { feedbackStatus, feedbackLoading } = useSelector(
    (state) => state.chatReducer
  );

  const handleChange = (event) => {
    setReviewStatus(event.target.value);
  };

  const submitFeedback = (status) => {
    dispatch(setFeedbackStatus(status));
    setIconColor(status);

    if (status === "Good") {
      handleSubmit(status);
    } else if (status === "Bad") {
      dispatch(setReview(true));
    }
  };

  const handleSubmit = (feedback) => {
    const body = {
      feedback,
      review: reviewStatus || null,
    };

    dispatch(
      getfeedbackData({
        body,
        navigate,
        id: jobId,
      })
    ).then(() => {
      dispatch(setReview(false));
      // setIconColor("");
      setReviewStatus("");
    });
  };

  const handleClose = () => {
    dispatch(setReview(false));
    dispatch(setFeedbackStatus(""));
    setIconColor("");
    setReviewStatus("");
  };

  useEffect(() => {
    if (feedbackLoading) toast.success("Feedback submitting please wait...");
  }, [feedbackLoading]);

  return (
    <Box display="flex" gap={3} margin={2}>
      <ThumbUpOffAltIcon
        style={{
          color: iconColor === "Good" ? "green" : null,
          cursor: "pointer",
        }}
        onClick={() => submitFeedback("Good")}
      />
      <ThumbDownOffAltIcon
        style={{
          color: iconColor === "Bad" ? "green" : null,
          cursor: "pointer",
        }}
        onClick={() => submitFeedback("Bad")}
      />
      {feedbackStatus === "Good" || feedbackStatus === "Bad" ? (
        <RateReviewIcon
          style={{
            color: review || iconColor === "Bad" ? "green" : null,
            cursor: "pointer",
          }}
          onClick={() => dispatch(setReview(!review))}
        />
      ) : null}
      <Dialog fullWidth maxWidth="sm" open={review} onClose={handleClose}>
        <DialogTitle textAlign="center" color="#8176f2">
          Submit a Review
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined">
            <textarea
              name="reviewStatus"
              value={reviewStatus}
              onChange={handleChange}
              placeholder="Please enter your review here..."
              style={{
                padding: "10px",
                height: "200px",
                borderRadius: "5px",
                border: "1px solid #c4c4c4",
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={styles.btns}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={reviewStatus.length === 0}
            onClick={() =>
              handleSubmit(feedbackStatus === "Good" ? "Good" : "Bad")
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
