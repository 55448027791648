import React, { useEffect, useState, useRef } from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import { Card, CircularProgress, Pagination, Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dropdown from "../../components/dropdown/Dropdown";
import { UserManagmentWrapper } from "./styles/MuiStyle";
import styles from "./styles/style.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "./userManagementDropdown";
import {
  getAllUsersManagementData,
  getRoles,
  setPage,
} from "../../redux/slices/userManagementSilce";
import ClearIcon from "@mui/icons-material/Clear";
import { UserManagementActions } from "./UserManagementActions";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const {
    getUserManagementData,
    page,
    limit,
    pageRole,
    limitRole,
    loading,
    filterValue,
  } = useSelector((state) => state.userManagementReducer);
  let totalPages = Math.ceil(getUserManagementData?.total / limit);
  const allAvailableRoles = useSelector(
    (state) => state.userManagementReducer.getRolesData
  );

  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  useEffect(() => {
    dispatch(
      getAllUsersManagementData({
        page: page,
        limit: limit,
        navigate: navigate,
        filterValue: filterValue?.toLowerCase(),
      })
    );
    dispatch(
      getRoles({ page: pageRole, limit: limitRole, navigate: navigate })
    );
  }, [page, limit, pageRole, limitRole, filterValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <UserManagmentWrapper>
      <ChatNav title={"User Management"} />
      <Card>
        <div className={styles.userMngWrapper}>
          <CssBaseline />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.table_head}>S.No.</TableCell>
                  <TableCell className={styles.table_head}>Name</TableCell>
                  <TableCell className={styles.table_head} align="right">
                    Email
                  </TableCell>
                  <TableCell className={styles.table_head} align="right">
                    Role
                  </TableCell>
                  <TableCell
                    className={styles.table_head}
                    align="left"
                    sx={{ display: "flex", gap: "130px" }}
                  >
                    <div style={{ position: "relative", top: "10px" }}>
                      Status
                    </div>
                    <div className={styles.dropdown} ref={dropdownRef}>
                      <CustomDropdown open={open} setOpen={setOpen} />
                    </div>
                  </TableCell>
                  <TableCell className={styles.table_head} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <div className={styles.loading_div}>
                    <CircularProgress disableShrink />
                  </div>
                ) : (
                  <>
                    {getUserManagementData?.data
                      ?.filter(
                        (row) =>
                          !filterValue ||
                          (filterValue === "All"
                            ? true
                            : filterValue === "Verified"
                            ? row.verified
                            : !row.verified)
                      )
                      ?.map((row, index) => (
                        <TableRow key={row._id}>
                          <TableCell component="th" scope="row">
                            {(page - 1) * limit + index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.email}</TableCell>
                          <TableCell align="right">
                            <Dropdown
                              page="userManagement"
                              values={allAvailableRoles?.data?.map(
                                (role) => role.name
                              )}
                              currentValue={row.role}
                              userId={row._id}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.verified === true ? (
                              <div className={styles.verified_text}>
                                <div>Verified</div>
                                <CheckIcon className={styles.check_icon} />
                              </div>
                            ) : (
                              <div className={styles.unverified_text}>
                                <div>Unverified</div>
                                <ClearIcon className={styles.cross_icon} />
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <UserManagementActions user={row} />
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
            {loading ? null : (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                className={styles.pageination_data}
              />
            )}
          </TableContainer>
        </div>
      </Card>
    </UserManagmentWrapper>
  );
};

export default UserManagement;
