import { ThemeProvider, createTheme } from "@mui/material";
import { useSelector } from "react-redux";

const SidebarWrapper = ({ children }) => {
  const isOpen = useSelector((state) => state.sidebarReducer.isOpen);
  const sidebar_theme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: "white !important",
            borderRadius: "27%",
            padding: "3px",
            border: " 2px solid #9c27b0 !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          wrapper: {
            paddingLeft: "5px",
          },
        },
      },

      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: " 20px",
            height: "20px",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            transition: "all 0.5s",
            position: "absolute",
            bottom: "42px",
            width: isOpen ? "254px" : "70px",
            boxShadow: "none",
            "@media (max-width: 1503px)": {
              width: isOpen && "203px !important",
            },
            "@media (max-width: 1015px)": {
              width: isOpen && "200px !important",
            },
            "@media (max-width: 788px)": {
              width: isOpen && "190px !important",
            },
            "@media (max-width: 767px)": {
              width: isOpen && "170px !important",
            },
            "@media (max-width: 748px)": {
              width: isOpen && "150px !important",
            },
            "@media (max-width: 721px)": {
              width: isOpen && "130px !important",
            },
            // Adjust width for smaller devices
            "@media (max-width: 600px)": {
              width: isOpen && "110px !important",
            },
            // Adjust width for even smaller devices
            "@media (max-width: 400px)": {
              width: isOpen && "90px !important",
            },
          },
        },
      },
    },
  });
  return <ThemeProvider theme={sidebar_theme}>{children}</ThemeProvider>;
};

export { SidebarWrapper };
