import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

const IntegrationWrapper = ({ children }) => {
  const integration_theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: "50%",
            padding: "20px !important",
            borderLeft: "none !important",
            background: "#ffffff",
            border: "none !important",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            padding: "19px",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none !important",
            borderBottom: "none !important",
            borderLeft: "none !important",
            borderRight: "none !important",
            borderRadius: "2px !important",
            padding: "4px 16px !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px !important",
            border: "1px solid #c8c8c8 !important",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            marginLeft: "0px !important",
            maxWidth: "100% !important",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            border: "none",
            display: "flex",
            gap: "20px",
          },
          avatar: {
            height: "40px",
            width: "40px",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            "&.Mui-checked": {
              color: "#47a3a4 !important",
            },
          },
          track: {
            backgroundColor: "white !important",
            border: "1px solid gray !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={integration_theme}>{children}</ThemeProvider>;
};

const ActivateBtnWrapper = ({ isActivated, children }) => {
  const btn_theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "& Mui-checked": {
              color: "black",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: isActivated
              ? "white !important"
              : "#8176f2 !important",
            color: isActivated ? "red !important" : " white !important",
            textTransform: "capitalize !important",
            border: isActivated
              ? "1px solid red !important"
              : " none !important",
            padding: "8px 40px !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={btn_theme}>{children}</ThemeProvider>;
};

export { IntegrationWrapper, ActivateBtnWrapper };
