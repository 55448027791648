import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./slices/global";
import statsReducer from "./slices/stats";
import sidebarReducer from "./slices/sidebarSlice";
import authReducer from "./slices/authSlice";
import profileReducer from "./slices/profileSlice";
import chatReducer from "./slices/chatSlice";
import integrationReducer from "./slices/integrationSlice";
import userManagementReducer from "./slices/userManagementSilce";
import trainFormReducer from "./slices/trainFormSlice";
import organizationReducer from "./slices/organizationSlice";
import trainAndContentReducer from "./slices/trainAndContentSlice";
import editRoleReducer from "./slices/editRoleSlice";
import analyticsReducer from "./slices/analyticsSlice";

export const store = configureStore({
  reducer: {
    globalReducer,
    statsReducer,
    sidebarReducer,
    authReducer,
    profileReducer,
    chatReducer,
    integrationReducer,
    userManagementReducer,
    trainFormReducer,
    organizationReducer,
    trainAndContentReducer,
    editRoleReducer,
    analyticsReducer,
  },
});
