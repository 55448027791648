import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Layout from "./components/layout/Layout";
import routes from "../src/pages/routes";
import Error from "./pages/errorPage/Error";
import { useSelector } from "react-redux";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={"...loading"}>
          <Routes>
            {routes.routes.map((list, index) => (
              <Route
                key={index}
                path={list.path}
                element={
                  <Layout data={routes.routes.map((item) => item)}>
                    {list.element}
                  </Layout>
                }
              />
            ))}
            {/* Fallback Route for Page Not Found */}
            <Route path="*" element={<Error />} />
          </Routes>
        </React.Suspense>
        <ToastContainer hideProgressBar={true} autoClose={2000} />
      </BrowserRouter>
    </>
  );
};

export default App;
