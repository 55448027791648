import { createTheme, ThemeProvider } from "@mui/material";

const ProfileWrapper = ({ children }) => {
  const profile_theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            padding: "19px",
            borderRadius: "0px",
            position: "relative",
            overflowY: "scroll !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "#202346 !important",
            color: "white",
            borderRadius: "5px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            width: "0px",
          },
          root: {
            color: "#1291ee !important",
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: "0px",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: "5px !important",
            padding: "0.5rem 2rem !important",
            textTransform: "capitalize !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            paddingLeft: "5px !important",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={profile_theme}>{children}</ThemeProvider>;
};
export { ProfileWrapper };
