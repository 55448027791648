import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { FormControl } from "@mui/base";
import { OutlinedInput } from "@mui/material";
import styles from "./styles/style.module.scss";
import {
  dbfIntegrations,
  kekaIntegrations,
  removeIntegrationsData,
  telegramIntegrations,
} from "../../redux/slices/integrationSlice";
import { toast } from "react-toastify";
import { Input } from "../../components/common/inputs/Input";
import { useNavigate } from "react-router-dom";

export default function IntegrationModal({ open, setOpen, isActivated }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDBF = useSelector((state) => state.integrationReducer.isDBF);
  const isKeka = useSelector((state) => state.integrationReducer.isKeka);
  const isTelegram = useSelector(
    (state) => state.integrationReducer.isTelegram
  );
  const [refreshToken, setRefereshToken] = useState();
  const [telegramId, setTelegramId] = useState();
  const [formData, setFormData] = useState({
    userEmail: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFormData({
      userEmail: "",
      password: "",
    });
    setRefereshToken("");
    setTelegramId("");
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(formData.userEmail));
  };

  const validatePassword = () => {
    setPasswordError(formData.password.length === 0);
  };

  const handleSubmit = () => {
    if (isKeka) {
      const body = {
        refreshToken: refreshToken,
      };
      const getKekaData = dispatch(
        kekaIntegrations({ body: body, navigate: navigate })
      );
      getKekaData.then((result) => {
        if (result.error) {
          if (Object.keys(result.error).length > 0) {
          }
        } else {
          setRefereshToken("");
          handleClose();
        }
      });
    } else if (isDBF) {
      validateEmail();
      validatePassword();
      if (!emailError && !passwordError) {
        const body = { email: formData.userEmail, password: formData.password };
        const getDBFData = dispatch(
          dbfIntegrations({
            body: body,
            navigate: navigate,
          })
        );
        getDBFData.then((result) => {
          if (result.error) {
            if (Object.keys(result.error).length > 0) {
            }
          } else {
            handleClose();
          }
        });
      } else {
        if (emailError) {
          toast.error("Please provide correct email");
        } else {
          toast.error("Please provide password");
        }
      }
    } else if (isTelegram) {
      const body = {
        telegramId: telegramId,
      };
      const getTelegramData = dispatch(
        telegramIntegrations({ body: body, navigate: navigate })
      );
      getTelegramData.then((result) => {
        if (result.error) {
          if (Object.keys(result.error).length > 0) {
          }
        } else {
          setRefereshToken("");
          handleClose();
        }
      });
    }
  };

  const handleChange = (event) => {
    setRefereshToken(event.target.value);
  };
  const handleChangeTelegram = (event) => {
    setTelegramId(event.target.value);
  };

  const handleChangeDBF = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Dialog
        open={isDBF ? open : isKeka ? open : isTelegram ? open : false}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle textAlign="center" color="#8176f2">
          {isDBF ? "DBF" : isKeka ? "Keka" : isTelegram && "Telegram"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              {isDBF
                ? "Email"
                : isKeka
                ? "Refresh token"
                : isTelegram && "Telegram Id"}
            </div>
            {!isDBF && (
              <Input
                handleChange={
                  isKeka ? handleChange : isTelegram && handleChangeTelegram
                }
                name={isKeka ? refreshToken : isTelegram && telegramId}
                value={isKeka ? refreshToken : isTelegram && telegramId}
                placeholder="Type here"
                error={emailError}
                helperText={emailError ? "Invalid email address" : ""}
              />
            )}
            {isDBF && (
              <>
                <Input
                  handleChange={handleChangeDBF}
                  name="userEmail"
                  value={formData.userEmail}
                  placeholder="Type here"
                  error={emailError}
                  helperText={emailError ? "Invalid email address" : ""}
                />
                <div style={{ marginTop: "20px" }}>Password</div>
                <Input
                  type="password"
                  handleChange={handleChangeDBF}
                  name="password"
                  value={formData.password}
                  placeholder="Type here"
                  error={passwordError}
                  helperText={passwordError ? "Password is required" : ""}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.btns}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
