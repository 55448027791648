import React, { useEffect, useMemo, useState } from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import {
  Card,
  Paper,
  IconButton,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./styles/style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  activateOrganizationData,
  clearSelectedOrg,
  editOrganizationData,
  getOrganizationData,
  setSelectedOrg,
} from "../../redux/slices/organizationSlice";
import EditModal from "../../components/common/modal/EditModal";
import CheckIcon from "@mui/icons-material/Check";
import CirculerLoader from "../../components/loader/circulerLoader/CirculerLoader";
import { useNavigate } from "react-router-dom";
import { OrganizationWrapper } from "./styles/MuiStyle";

const Organization = () => {
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const [activationStates, setActivationStates] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [orgData, setOrgData] = useState({
    name: "",
    domain: "",
    status: "active",
  });

  const dispatch = useDispatch();
  const selectedOrg = useSelector(
    (state) => state.organizationReducer.selectedOrg
  );
  const loader = useSelector((state) => state.organizationReducer.loader);

  const organisations = useSelector(
    (state) => state.organizationReducer.organisations
  );

  useEffect(() => {
    dispatch(getOrganizationData(navigate));
  }, [dispatch]);

  useEffect(() => {
    const initialActivationStates = {};
    organisations?.forEach((org) => {
      initialActivationStates[org._id] = org.status;
    });
    setActivationStates(initialActivationStates);
  }, [organisations]);

  useEffect(() => {
    setOrgData({
      name: selectedOrg?.name || "",
      domain: selectedOrg?.domain || "",
      status: "active",
    });
  }, [selectedOrg]);

  const handleMenuOpen = (event, data) => {
    const newActivateState = data.status === "active" ? "inactive" : "active";
    setActivationStates((prevStates) => ({
      ...prevStates,
      [data._id]: newActivateState,
    }));
    let body = {
      status: newActivateState,
    };
    dispatch(
      activateOrganizationData({ id: data._id, body: body, navigate: navigate })
    );
  };

  const memoOrganisations = useMemo(() => organisations, [organisations]);
  const memoActivationStates = useMemo(
    () => activationStates,
    [activationStates]
  );

  const handleChange = (e) => {
    e.preventDefault();
    setOrgData({ ...orgData, [e.target.name]: e.target.value });
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, memoOrganisations?.length - page * rowsPerPage);

  return (
    <OrganizationWrapper>
      <ChatNav title={"Organization"} />
      <Card>
        <CssBaseline />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.table_head}>S.No.</TableCell>
                <TableCell className={styles.table_head}>Name</TableCell>
                <TableCell className={styles.table_head} align="right">
                  Domain
                </TableCell>
                <TableCell className={styles.table_head} align="right">
                  Company Email
                </TableCell>
                <TableCell className={styles.table_head} align="right">
                  Status
                </TableCell>
                <TableCell className={styles.table_head} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <CirculerLoader />
              ) : (
                (rowsPerPage > 0
                  ? memoOrganisations?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : memoOrganisations
                )?.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.domain}</TableCell>
                    <TableCell align="right">{row.admin}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell
                      align="right"
                      onClick={(e) => {
                        handleMenuOpen(e, row);
                      }}
                    >
                      <IconButton>
                        <div
                          className={
                            memoActivationStates[row._id] === "active"
                              ? styles.inact_btn
                              : styles.act_btn
                          }
                          name={memoActivationStates[row._id]}
                          value="activationStates"
                        >
                          {memoActivationStates[row._id] === "active"
                            ? "Activated"
                            : "Activate"}
                          <span>
                            {memoActivationStates[row._id] === "active" && (
                              <CheckIcon />
                            )}
                          </span>
                        </div>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={memoOrganisations?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Card>

      <EditModal
        open={openEdit}
        setOpen={setOpenEdit}
        clearEditData={() => dispatch(clearSelectedOrg())}
        id={selectedOrg?._id}
        onEdit={() => {
          dispatch(
            editOrganizationData({
              id: selectedOrg?._id,
              body: orgData,
              navigate: navigate,
            })
          );
        }}
        getAllData={() => dispatch(getOrganizationData())}
        handleChange={handleChange}
        currentData={orgData}
      />
    </OrganizationWrapper>
  );
};

export default Organization;
