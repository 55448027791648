import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenAddTrainMoadl,
  uploadPdf,
  uploadText,
  uploadURLData,
} from "../../../redux/slices/trainAndContentSlice";
import styles from "../styles/style.module.scss";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TrainContentModal({
  filename,
  setAttachment,
  setUploadFile,
  selectedOptions,
  setUploadTextData,
  setUploadURL,
  fileType,
  file,
  setOpenTrainModal,
  uploadURL,
  uploadTextData,
  open,
  setSelectedOptions,
  setValue,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const { page, limit, roleData } = useSelector(
    (state) => state.trainAndContentReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = () => {
    setOpenTrainModal(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("role", JSON.stringify(roleData));
    if (selectedOptions.includes("pdf")) {
      if (!file) {
        toast.error("Please upload file");
        dispatch(setOpenAddTrainMoadl(false));
      } else {
        dispatch(setOpenAddTrainMoadl(false));
        setAttachment(null);
        setUploadFile(null);

        dispatch(
          uploadPdf({
            payloadData: formData,
            fileType: fileType,
            page: page,
            limit: limit,
            navigate: navigate,
          })
        );
      }
    } else if (selectedOptions.includes("url")) {
      dispatch(setOpenAddTrainMoadl(false));
      setUploadURL({
        url: "",
        urlFileName: "",
        role: [],
      });
      dispatch(
        uploadURLData({
          body: {
            urlFileName: `${uploadURL.urlFileName}.doc`,
            url: uploadURL.url,
            role: roleData,
          },
          fileType: "url",
          page: page,
          limit: limit,
          navigate: navigate,
        })
      );
    } else {
      dispatch(setOpenAddTrainMoadl(false));
      setUploadTextData({
        text: "",
        textFileName: "",
        role: [],
      });
      dispatch(
        uploadText({
          body: {
            text: uploadTextData.text,
            textFileName: `${uploadTextData.textFileName}.txt`,
            role: roleData,
          },
          fileType: "text",
          page: page,
          limit: limit,
          navigate: navigate,
        })
      );
    }
    setSelectedOptions([]);
    setValue(null);
  };
  const handleBack = () => {
    dispatch(setOpenAddTrainMoadl(true));
    setOpenTrainModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={"md"}
        open={open}
        onClose={() => setOpenTrainModal(false)}
      >
        <Box display="flex" justifyContent="space-between">
          <IconButton>
            <KeyboardBackspaceIcon onClick={handleBack} />
          </IconButton>
          <DialogTitle
            sx={{
              color: "#8176f2",
              textAlign: "center",
              width: "90%",
              position: "relative",
              right: "24px",
            }}
          >
            Train from content
          </DialogTitle>
          <CloseIcon
            className={styles.cross_icon_btn}
            onClick={() => setOpenTrainModal(false)}
          />
        </Box>
        <DialogContent>
          <h4 className={styles.test_bot_text}>Preview</h4>
          <DialogContentText>
            {selectedOptions.includes("pdf")
              ? filename
              : selectedOptions.includes("url")
              ? uploadURL.url
              : uploadTextData.text}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
        </DialogContent>
        <DialogActions className={styles.submit_btns}>
          <Button
            className={styles.submit_btn}
            variant="contained"
            fullWidth
            onClick={() => setOpenTrainModal(false)}
          >
            Close
          </Button>
          <Button
            className={styles.submit_btn}
            variant="contained"
            fullWidth
            onClick={onSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
