import React, { useEffect, useState } from "react";
import ChatNav from "../../components/chatNav/ChatNav";
import {
  Card,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  CircularProgress,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { EditRolesWrapper } from "./styles/MuiStyle";
import styles from "./styles/editRoles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getRolesData,
  setPage,
  setSelectedRoleData,
} from "../../redux/slices/editRoleSlice";
import EditRoleModal from "./EditModal/EditRoleModal";
import EditPermissionModal from "./EditModal/EditPermissionModal";
import { getSingleDataApi } from "../../redux/slices/trainAndContentSlice";
import AppSecureStorage from "../../services/storage/secureStorage";
import { useNavigate } from "react-router-dom";

const storage = new AppSecureStorage();

const EditRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null); // State for the anchor element of the menu
  const [selectedRole, setSelectedRole] = useState(null); // State to track the selected role
  const loading = useSelector((state) => state.editRoleReducer.loading);
  const getRolesDetails = useSelector((state) => state.editRoleReducer.roles);
  const { selectedRoleData, page, limit } = useSelector(
    (state) => state.editRoleReducer
  );
  let permissions = JSON.parse(storage.get("permissions"));
  let editRolesPermission =
    permissions && permissions["Role"] && permissions["Role"]?.includes("Edit");
  const [openEdit, setOpenEdit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({
    name: selectedRoleData?.name,
    permissions: null,
  });
  let totalPages = Math.ceil(getRolesDetails?.total / limit);

  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  const handleChangeRole = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOpenEdit = () => {};

  const handleMenuOpen = (event, role) => {
    setAnchorEl(event.currentTarget);
    setSelectedRole(role);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRole(null);
  };

  useEffect(() => {
    dispatch(getRolesData({ page: page, limit: limit, navigate: navigate }));
  }, [page, limit]);

  useEffect(() => {
    setFormData({
      ...formData,
      name: selectedRoleData?.name,
      permissions: selectedRoleData?.permissions,
    });
  }, [selectedRoleData]);

  return (
    <EditRolesWrapper>
      <ChatNav title={"Edit Roles"} />
      <Card>
        <div className={styles.editRoleWrapper}>
          <CssBaseline />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.table_head}>S.No.</TableCell>
                  <TableCell className={styles.table_head}>Name</TableCell>
                  <TableCell className={styles.table_head} align="right">
                    Permissions
                  </TableCell>
                  {editRolesPermission && (
                    <TableCell className={styles.table_head} align="right">
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <div className={styles.loading_div}>
                    <CircularProgress disableShrink />
                  </div>
                ) : (
                  getRolesDetails?.data?.map((role, index) => {
                    return (
                      <TableRow key={role?.name}>
                        <TableCell> {(page - 1) * limit + index + 1}</TableCell>
                        <TableCell>{role?.name}</TableCell>
                        <TableCell align="right">
                          {role?.permissions
                            ? Object.keys(role.permissions).join(", ")
                            : ""}
                        </TableCell>
                        {editRolesPermission && (
                          <TableCell align="right">
                            <IconButton
                              aria-label="more"
                              onClick={(e) => handleMenuOpen(e, role)}
                            >
                              <MoreVertIcon
                                className={styles.three_dots_icon}
                              />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl && selectedRole === role)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOpenEdit();
                                  handleMenuClose();
                                  dispatch(setSelectedRoleData(role));
                                  setOpenEdit(true);
                                }}
                                className={styles.menu_item}
                              >
                                <EditIcon /> Edit
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            {loading ? null : (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                className={styles.pageination_data}
              />
            )}
          </TableContainer>
        </div>
      </Card>
      <EditRoleModal
        open={openEdit}
        setOpen={setOpenEdit}
        openPermission={setIsActive}
        handleChangeRole={handleChangeRole}
        formData={formData}
      />
      <EditPermissionModal
        open={isActive}
        setOpen={setIsActive}
        handleChangeRole={handleChangeRole}
        formData={formData}
        setFormData={setFormData}
      />
    </EditRolesWrapper>
  );
};

export default EditRoles;
