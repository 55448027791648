// src/redux/TrainFormSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const TrainFormSlice = createSlice({
  name: "TrainForm",
  initialState: {
    loading: "idle",
    text: false,
    url: false,
    pdf: false,
  },
  reducers: {
    setText: (state, action) => {
      state.text = action.payload;
    },
    setURL: (state, action) => {
      state.url = action.payload;
    },
    setPDF: (state, action) => {
      state.pdf = action.payload;
    },
  },
});

export const { setText, setPDF, setURL } = TrainFormSlice.actions;

export default TrainFormSlice.reducer;
