import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const ChatWrapper = ({ children }) => {
  const chat_theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh",
            padding: "19px 19px 0px 19px !important",
            borderRadius: "0px",
            position: "relative",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "0px",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={chat_theme}>{children}</ThemeProvider>;
};

const MessageWrapper = ({ children }) => {
  const message_theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            position: "absolute",
            bottom: 0,
            left: "50px",
            right: "70px",
            boxShadow: "none",
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={message_theme}>{children}</ThemeProvider>;
};

export { ChatWrapper, MessageWrapper };
