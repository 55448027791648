import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";

export const EditMethodDropdown = ({ setMethod, method }) => {
  const [selectedMethod, setSelectedMethod] = useState("");
  // Function to handle method change
  const handleChange = (event) => {
    setMethod(event.target.value);
    setSelectedMethod(event.target.value);
  };

  return (
    <Box width="100%">
      <Box color="#323454" fontWeight="bold">
        Methods
      </Box>
      <FormControl fullWidth>
        <Select
          fullWidth
          value={method} // Set the value of the Select component
          onChange={handleChange} // Handle change event
          inputProps={{
            name: "max-width",
            id: "max-width",
          }}
          renderValue={(selected) => selected}
        >
          <MenuItem value="POST">POST</MenuItem>
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem>
          <MenuItem value="HEAD">HEAD</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
