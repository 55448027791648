import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Checkbox, Menu, MenuItem } from "@mui/material";
import styles from "./styles/style.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../assets/logo.svg";
import Button from "../common/buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import { ChatNavbarWrapper } from "./styles/MuiStyle";
import CommanModal from "../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  closeActiveModal,
  setIsActive,
} from "../../redux/slices/userManagementSilce";
import ActiveDeactiveModal from "../modal/ActiveDeactiveModal";
import AppSecureStorage from "../../services/storage/secureStorage";

const storage = new AppSecureStorage();
export default function ChatNav({ title, isSearch }) {
  const dispatch = useDispatch();
  const [isSearchIcon, setIsSearchIcon] = React.useState(false);
  const { isActive } = useSelector((state) => state.userManagementReducer);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addNewRole, setAddNewRole] = useState({
    name: "",
    permissions: null,
  });
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    roleId: "",
  });
  let permissions = JSON.parse(storage.get("permissions"));
  let editRolesPermission =
    permissions && permissions["Role"] && permissions["Role"]?.includes("Add");
  let User =
    permissions && permissions["User"] && permissions["User"]?.includes("Add");
  let Organization =
    permissions &&
    permissions["Organization"] &&
    permissions["Organization"]?.includes("Add");
  const handleSearch = () => {
    setIsSearchIcon(!isSearchIcon);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const permission_data = [
    "All",
    "Manager",
    "Assistant manager",
    "Admin",
    "Co-Admin",
    "Developer",
    "Senior developer",
    "Designer",
    "Senior designer",
    "Lead",
  ];
  useEffect(() => {}, [userData]);

  return (
    <ChatNavbarWrapper>
      <AppBar position="static" className={styles.appbar_wrapper}>
        <Toolbar>
          <Avatar alt="" src={logo} />
          <Typography
            className={`${styles.icon_heading}`}
            variant="h6"
            component="div"
          >
            {title ? title : null}
            {(title === "User Management" ||
              title === "Organization" ||
              title === "Edit Roles") && (
              <>
                {title === "User Management" && User && (
                  <Button
                    onClick={handleClickOpen}
                    className={styles.user_management_btn}
                  >
                    <AddIcon className={styles.add_icon} />
                    Add new users
                  </Button>
                )}
                {title === "Organization" && Organization && (
                  <Button
                    onClick={handleClickOpen}
                    className={styles.user_management_btn}
                  >
                    <AddIcon className={styles.add_icon} />
                    Add new organization
                  </Button>
                )}
                {title === "Edit Roles" && editRolesPermission && (
                  <Button
                    onClick={handleClickOpen}
                    className={styles.user_management_btn}
                  >
                    <AddIcon className={styles.add_icon} />
                    Add new role
                  </Button>
                )}
              </>
            )}
          </Typography>

          {isSearchIcon ? (
            <input placeholder="Search" className={styles.input} />
          ) : null}
          {isSearch && (
            <div className={styles.icons}>
              <IconButton size="large" color="inherit" className={styles.icons}>
                <SearchIcon onClick={handleSearch} />
              </IconButton>
              <IconButton onClick={handleMenuClick}>
                {window.location.pathname === "/userManagement" && (
                  <FilterAltIcon />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {permission_data.map((data) => {
                  return (
                    <MenuItem className={styles.drodown_item}>
                      <div>{data}</div>
                      <Checkbox />
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <CommanModal
        open={open}
        setOpen={setOpen}
        page="chatNav"
        addNewRole={addNewRole}
        setAddNewRole={setAddNewRole}
        userData={userData}
        setUserData={setUserData}
      />
      <ActiveDeactiveModal
        isActive={isActive}
        setIsActive={() => dispatch(setIsActive())}
        pageName={"editRoles"}
        onClose={() => dispatch(closeActiveModal())}
        addNewRole={addNewRole}
        setAddNewRole={setAddNewRole}
        userData={userData}
        setUserData={setUserData}
        setOpen={setOpen}
      />
    </ChatNavbarWrapper>
  );
}
