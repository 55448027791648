import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogActions,
  Radio,
  Select,
  Grid,
  Tab,
  Menu,
  alpha,
  Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../styles/style.module.scss";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  ChildMenuWrapper,
  DropdownWrapper,
  ParentWrapper,
  TrainFormWrapperCustom,
  TrainFormWrapperCustomAction,
} from "../styles/MuiStyle";
import AddCustomModal from "./CustomModals/AddCustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCustom_data,
  setEditApiRow,
  setIsEdit,
  getSingleDataApi,
  setTraineddata,
  setSelectedTrainedState,
  setSelectedItemId,
  setEditOpen,
  setSelectedTrainedStateEdit,
  setEditApplyLeave,
  setOpenCustom,
  setOpenCustomAction,
  getAllCustom_Action,
  setSelectedCategory,
  setSelectedAction,
  setSelectedActionData,
  setActionName,
} from "../../../redux/slices/trainAndContentSlice";
import EditAPITestingModal from "./CustomModals/EditAPITestingModal";
import EditCustomModal from "./CustomModals/EditCustomModal";
import AppSecureStorage from "../../../services/storage/secureStorage";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

const storage = new AppSecureStorage();

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#202346",
    color: "black",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "black",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#202346",
      },
    },
  },
}));

const CustomForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownId, setDropdownId] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [value, setValue] = React.useState("");
  const [selectedCategoryParent, setSelectedCategoryParent] = useState(null);
  const [selectedCategorySub, setSelectedCategorySub] = useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [customName, setCustomName] = useState("");
  const openCategory = Boolean(anchorEl);
  const openActions = Boolean(anchorE2);
  const menuRef = useRef(null);
  const {
    renderallCustomData,
    loading,
    pageCustom,
    limitCustom,
    selectedItemId,
    editApplyLeave,
    openCustom,
    openCustomAction,
    selectedTrainedState,
    renderallCustomAction,
    selectedCategory,
    selectedAction,
  } = useSelector((state) => state.trainAndContentReducer);

  let permissions = JSON.parse(storage.get("permissions"));
  let TrainContent =
    permissions &&
    permissions["TrainContent"] &&
    permissions["TrainContent"]?.includes("Add");
  let TrainContentDelete =
    permissions &&
    permissions["TrainContent"] &&
    permissions["TrainContent"]?.includes("Delete");
  let TrainContentEdit =
    permissions &&
    permissions["TrainContent"] &&
    permissions["TrainContent"]?.includes("Edit");

  const categories = [
    ...new Set(renderallCustomAction?.map((ele) => ele.category)),
  ];

  const categoriesActions = [
    ...new Set(renderallCustomAction?.map((ele) => Object.keys(ele?.actions))),
  ];

  const handleClick = (event, category) => {
    setAnchorEl(event.currentTarget);
    setSelectedCategoryParent(category);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleActionClick = (actionData) => {
    const data = {
      actionData: actionData,
      categoryName: selectedCategoryName,
    };
    dispatch(setSelectedActionData(data));
    handleClosesub();
  };

  const handleClicksub = (event, action) => {
    setAnchorE2(event.currentTarget);
    setSelectedCategorySub(action);
  };
  const handleClosesub = () => {
    setAnchorE2(null);
  };

  const handleCategoryChange = (category) => {
    dispatch(setSelectedCategory(category));
  };

  const handleDropdownToggle = (id, name) => {
    setDropdownId(id === dropdownId ? null : id);
    setCustomName(name);
  };

  const handleCustomDelete = () => {
    if (deleteId) {
      dispatch(
        removeCustom_data({
          body: { userId: deleteId },
          userId: deleteId,
          page: pageCustom,
          limit: limitCustom,
          navigate: navigate,
        })
      );
      setDeleteConfirmation(false);
      setDeleteId(null);
    }
  };

  const handleEdit = (ele) => {
    dispatch(setEditApplyLeave(true));
    dispatch(setEditOpen(true));
    dispatch(setIsEdit());
    dispatch(setEditApiRow(ele));
  };

  const handleSelect = (ele) => {
    dispatch(setSelectedItemId(ele._id));
    dispatch(setTraineddata(ele));
    dispatch(setSelectedTrainedState(true));
    dispatch(setSelectedTrainedStateEdit(true));
  };
  useEffect(() => {
    if (!selectedItemId && renderallCustomData?.data?.length > 0) {
      handleSelect(renderallCustomData?.data?.[0]);
      dispatch(getAllCustom_Action(navigate));
      dispatch(setOpenCustomAction(true));
    }
  }, [
    selectedItemId,
    renderallCustomData,
    selectedTrainedState,
    openCustomAction,
    openCustom,
    selectedCategoryName,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const renderActionsForCategory = (categoryName) => {
    const category = renderallCustomAction.find(
      (cat) => cat.category === selectedCategoryParent
    );
    if (!category) return null;

    const actions = category.actions;

    return Object.keys(actions).map((actionName) => {
      return (
        <ParentWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#202346",
            }}
          >
            <Button
              sx={{
                margin: "10px 20px",
                padding: "10px 10px",
                background: "#202346",
                color: "white",
              }}
              aria-controls={openActions ? "demo-customized-menu" : undefined}
              aria-expanded={openActions ? "true" : undefined}
              onClick={(e) => {
                handleClicksub(e, actionName);
                setSelectedCategoryName(category?.category);
              }}
              endIcon={<KeyboardArrowDownIcon />}
              key={actionName}
            >
              {actionName.replace(/_/g, " ")}
              <br />
            </Button>
            <ChildMenuWrapper>
              <StyledMenu
                anchorEl={anchorE2}
                open={openActions}
                onClose={handleClosesub}
                className={styles.apps_container}
              >
                <div className={styles.current_actions}>Apps</div>
                {Object.keys(actions).map((actionName) => {
                  if (actionName === selectedCategorySub) {
                    const actionProviders = actions[actionName];
                    return Object.keys(actionProviders).map((providerName) => {
                      const actionData = actionProviders[providerName];
                      return (
                        <MenuItem
                          key={providerName}
                          onClick={() => handleActionClick(actionData)}
                          className={styles.apps_name}
                        >
                          {providerName.replace(/_/g, " ")}
                        </MenuItem>
                      );
                    });
                  }
                  return null;
                })}
              </StyledMenu>
            </ChildMenuWrapper>
          </div>
        </ParentWrapper>
      );
    });
  };

  return (
    <TrainFormWrapperCustom>
      <div>
        {TrainContent && (
          <div className={styles.options}>
            {/* <Button
              size="large"
              variant="contained"
              startIcon={<NoteAddIcon />}
              onClick={() => {
                dispatch(setOpenCustom(true));
                dispatch(setOpenCustomAction(false));
                dispatch(setEditApplyLeave(false));
              }}
              className={styles.add_train_data}
            >
              Add Custom Action
            </Button> */}
            {/* <Button
              size="large"
              variant="contained"
              startIcon={<NoteAddIcon />}
              onClick={() => {
                dispatch(getAllCustom_Action(navigate));
                dispatch(setOpenCustomAction(true));
              }}
              className={styles.add_train_data}
            >
              Custom Actions
            </Button> */}
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    scrollButtons="auto"
                    // onClick={() => {
                    //   dispatch(getAllCustom_Action(navigate));
                    //   dispatch(setOpenCustomAction(true));
                    // }}
                  >
                    <Tab
                      label="Current Categories"
                      wrapped
                      value="1"
                      style={{ color: openCustomAction && "#8176f2" }}
                    />
                  </TabList>
                </Box>
              </TabContext>
            </Box>
          </div>
        )}

        <Box height="70vh" style={{ overflowY: "scroll" }}>
          {/* {!openCustomAction && <span>Titles</span>} */}
          {
            loading ? (
              <Box
                height="70vh"
                style={{
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress disableShrink />
              </Box>
            ) : openCustomAction ? (
              <TrainFormWrapperCustomAction>
                {/* <Grid item xs={4} md={11} display="flex" gap={10}>
                <Grid md={12}>
                  <Box fullWidth>Select Category</Box>
                  <Select
                    value={selectedCategory || null}
                    onChange={(e) => handleCategoryChange(e.target.value)}
                    displayEmpty
                    fullWidth
                    sx={{ marginTop: "20px" }}
                  >
                    <MenuItem value={null} disabled>
                      Select Category
                    </MenuItem>
                    {categories?.map((category, index) => (
                      <MenuItem
                        onClick={() => dispatch(setSelectedAction(null))}
                        key={index}
                        value={category}
                      >
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid> */}
                <DropdownWrapper>
                  <Grid>
                    {renderallCustomAction?.map((category, index) => {
                      return (
                        <div>
                          <Button
                            style={{ backgroundColor: "white", color: "black" }}
                            fullWidth
                            aria-expanded={openCategory ? "true" : undefined}
                            variant="contained"
                            onClick={(e) => handleClick(e, category?.category)}
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{ marginTop: "20px", padding: "15px 20px" }}
                          >
                            {category?.category}
                          </Button>
                          <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorEl}
                            open={openCategory}
                            onClose={handleClose}
                          >
                            <div className={styles.current_actions}>
                              Current Actions
                            </div>
                            {renderActionsForCategory(category?.category)}
                          </StyledMenu>
                        </div>
                      );
                    })}
                  </Grid>
                </DropdownWrapper>
              </TrainFormWrapperCustomAction>
            ) : (
              <Box mt={2}>Please select current categories</Box>
            )
            //  (
            //   renderallCustomData?.data?.map((ele) => {
            //     return (
            //       <div
            //         key={ele._id}
            //         style={{
            //           marginTop: "10px",
            //           border: "1px solid #e2e2e2",
            //           padding: "1rem",
            //           display: "flex",
            //           justifyContent: "space-between",
            //           background:
            //             !openCustomAction && selectedItemId === ele._id
            //               ? "#202346"
            //               : "none",
            //           color:
            //             !openCustomAction && selectedItemId === ele._id
            //               ? "white"
            //               : "black",
            //         }}
            //       >
            //         <div
            //           style={{
            //             display: "flex",
            //             gap: "10px",
            //             justifyContent: "center",
            //             alignItems: "center",
            //           }}
            //         >
            //           <div>
            //             <div key={ele._id}>
            //               <Radio
            //                 onClick={() => {
            //                   !openCustomAction && handleSelect(ele);
            //                   openCustomAction &&
            //                     dispatch(setOpenCustomAction(false));
            //                 }}
            //                 checked={
            //                   !openCustomAction && selectedItemId === ele._id
            //                 }
            //                 value={ele._id}
            //                 name="radio-buttons"
            //                 inputProps={{ "aria-label": "A" }}
            //               />
            //               {ele.name &&
            //                 ele.name.charAt(0).toUpperCase() + ele.name.slice(1)}
            //             </div>
            //           </div>
            //         </div>
            //         <div
            //           style={{
            //             display: "flex",
            //             justifyContent: "center",
            //             alignItems: "center",
            //           }}
            //         >
            //           <MoreVertIcon
            //             onClick={() => handleDropdownToggle(ele._id, ele.name)}
            //             style={{ color: "#cfcece", cursor: "pointer" }}
            //           />
            //           {dropdownId === ele._id && (
            //             <div ref={menuRef} style={{ position: "relative" }}>
            //               <div
            //                 style={{
            //                   position: "absolute",
            //                   top: "100%",
            //                   right: 0,
            //                   background: "#fff",
            //                   border: "1px solid #ccc",
            //                   borderRadius: "4px",
            //                   boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            //                   zIndex: 1,
            //                 }}
            //               >
            //                 {TrainContentEdit && (
            //                   <MenuItem
            //                     onClick={() => {
            //                       dispatch(getSingleDataApi(ele._id,navigate));
            //                       handleEdit(ele);
            //                     }}
            //                     className={styles.api_test_drodown}
            //                   >
            //                     <EditIcon />
            //                     Edit
            //                   </MenuItem>
            //                 )}
            //                 {TrainContentDelete && (
            //                   <MenuItem
            //                     onClick={() => {
            //                       setDeleteId(ele._id);
            //                       setDeleteConfirmation(true);
            //                     }}
            //                     className={styles.api_test_drodown}
            //                   >
            //                     <DeleteIcon />
            //                     Delete
            //                   </MenuItem>
            //                 )}
            //               </div>
            //             </div>
            //           )}
            //         </div>
            //       </div>
            //     );
            //   })
            // )
          }
        </Box>
      </div>
      {editApplyLeave ? (
        <EditCustomModal />
      ) : (
        <AddCustomModal
          open={openCustom}
          handleClose={() => dispatch(setOpenCustom(false))}
        />
      )}
      <EditAPITestingModal />

      <Dialog
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
      >
        <Box style={{ color: "black", padding: "20px" }}>
          Are you sure you want to delete{" "}
          {customName.charAt(0).toUpperCase() + customName.slice(1)}?
        </Box>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setDeleteConfirmation(false)}>No</Button>
          <Button onClick={handleCustomDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </TrainFormWrapperCustom>
  );
};

export default CustomForm;
