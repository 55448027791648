import { createTheme, ThemeProvider } from "@mui/material";

const ChatNavbarWrapper = ({ children }) => {
  const chat_nav_theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: "0px !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "#202346",
            border: "0px",
            color: "white",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: "0px",
            backgroundColor: "white",
            borderBottom: "2px solid #eaeaea !important",
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 38,
            height: 36,
            borderRadius: "0px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            flexGrow: 1,
            color: "#6E6E6E !important",
            margin: "15px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "black",
            marginRight: "10px ",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          backgroundColor: "#e8e9ec !important",
          boxShadow: "none",
          color: "black",
          fontWeight: "550",
          textTransform: "capitalize",
        },
      },
    },
  });

  return <ThemeProvider theme={chat_nav_theme}>{children}</ThemeProvider>;
};

export { ChatNavbarWrapper };
