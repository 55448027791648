import { FormControl, OutlinedInput } from "@mui/material";
import React from "react";

export const Input = ({
  value,
  handleChange,
  name,
  placeholder,
  disabled,
  error,
  helperText,
  type,
  InputLabelProps,
}) => {
  return (
    <FormControl fullWidth sx={{ marginTop: "10px" }}>
      <OutlinedInput
        value={value}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        required
        disabled={disabled}
        error={error}
        helperText={helperText}
        type={type}
        InputLabelProps={InputLabelProps}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#e0e0e0",
            border: "0.5px solid #e0e0e0",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e0e0e0",
            border: "0.5px solid #e0e0e0",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e0e0e0",
            border: "0.5px solid #e0e0e0",
          },
        }}
      />
    </FormControl>
  );
};
