
import { API_ROUTES } from '../../utils/contants';
import { AxiosBase } from './index';

export default class UserService extends AxiosBase {
  getAllUsers = async (body) => {
    const { page, limit } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.USERS.USER}?page=${page}&limit=${limit}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  getUserStats = async (body) => {
    const { type } = body
    try {
      const response = await this.requests.get(`${API_ROUTES.USERS.USER}/${API_ROUTES.USERS.STATS}?type=${type}`);
      return response;
    } catch (err) {
      throw err
    }
  }

  blockUser = async (id) => {
    try {
      const response = await this.requests.put(`${API_ROUTES.USERS.USER}/${id}/block`);
      return response;
    } catch (err) {
      throw err
    }
  }

}