import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";

export default function EditModal({
  open,
  setOpen,
  id,
  onEdit,
  getAllData,
  currentData,
  handleChange,
  clearEditData,
}) {
  const selectedOrg = useSelector(
    (state) => state.organizationReducer.selectedOrg
  );

  const handleClose = () => {
    setOpen(false);
    clearEditData();
  };

  const handleEdit = () => {
    onEdit({ id: selectedOrg?._id, body: currentData });
    handleClose();
    getAllData();
    clearEditData();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle textAlign="center" color="#8176f2">
          Update Organization
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <div>Name</div>
            <TextField
              value={currentData.name}
              name="name"
              onChange={handleChange}
              fullWidth
              sx={{ marginTop: "10px" }}
            />
          </DialogContentText>
          <DialogContentText sx={{ marginTop: "20px" }}>
            <div>Domain name</div>
            <TextField
              value={currentData.domain}
              name="domain"
              onChange={handleChange}
              fullWidth
              sx={{ marginTop: "10px" }}
            />
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
        </DialogContent>
        <Divider />
        <DialogActions className={styles.btns}>
          <Button onClick={handleClose} className={styles.cancel_btn}>
            Cancel
          </Button>
          <Button onClick={handleEdit} className={styles.delete_btn}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
