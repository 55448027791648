import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
import styles from "../styles/style.module.scss";
import { PieChartWrapper } from "../styles/MuiStyle";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const PieChartPage = () => {
  const { loading, analyticsData } = useSelector(
    (state) => state.analyticsReducer
  );

  if (
    !analyticsData?.successRate ||
    typeof analyticsData?.successRate !== "object"
  ) {
    return <CircularProgress />;
  }

  // Extracting data from analyticsData.successRate
  const data = Object.entries(analyticsData.successRate).map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  const colors = ["#7ebdbe", "#ed5031", "#c2a5f6"];

  return (
    <PieChartWrapper>
      <h3 style={{ margin: "20px 0px" }}>Success rate</h3>
      <ResponsiveContainer className={styles.pieChartContainer}>
        <PieChart>
          <Pie
            dataKey="value"
            data={data}
            innerRadius={60}
            outerRadius={80}
            nameKey="name"
            cx="50%"
            cy="50%"
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </PieChartWrapper>
  );
};

export default PieChartPage;
