import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import MuiButton from "@mui/material/Button";

const Button = ({ children, className = "", ...rest }) => {
  return (
    <MuiButton className={`${styles.button} ${className}`} {...rest}>
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Button;
