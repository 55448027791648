import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import styles from "../../../components/modal/styles/activeModal.module.scss";
import dropDown from "../../../assets/dropdown.svg";
import { useDispatch, useSelector } from "react-redux";
import { editRoleData } from "../../../redux/slices/editRoleSlice";
import { ActiveWrapper } from "../../../components/modal/styles/MuiStyles";
import { useNavigate } from "react-router-dom";

const EditPermissionModal = ({ open, setOpen, formData, setFormData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [isAnyMethodSelected, setIsAnyMethodSelected] = useState(false);
  const getPermission = useSelector(
    (state) => state.userManagementReducer.getAllPermission
  );
  const { selectedRoleData, page, limit } = useSelector(
    (state) => state.editRoleReducer
  );

  const handleDropdownClick = (label) => {
    setIsDropdownOpen((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const handleSwitchChange = (label) => {
    setChecked((prev) => {
      const updatedChecked = {
        ...prev,
        [label]: !prev[label],
      };

      // If the switch is turned off, remove all methods under this section
      if (!updatedChecked[label]) {
        setFormData((prevFormData) => {
          const updatedPermissions = { ...prevFormData.permissions };
          delete updatedPermissions[label];
          return {
            ...prevFormData,
            permissions: updatedPermissions,
          };
        });
      } else {
        // If the switch is turned on, initialize the section with empty methods
        setFormData((prevFormData) => ({
          ...prevFormData,
          permissions: {
            ...prevFormData.permissions,
            [label]: [],
          },
        }));
      }

      return updatedChecked;
    });

    // Ensure the dropdown opens when the switch is turned on
    setIsDropdownOpen((prev) => ({
      ...prev,
      [label]: !isChecked[label],
    }));
  };

  const handleChange = (label, method) => (event) => {
    const { checked } = event.target;

    // Update the isChecked state
    setChecked((prev) => ({
      ...prev,
      [label]: {
        ...prev[label],
        [method]: checked,
      },
    }));

    // Update the permissions state based on the checked status of the method
    setFormData((prev) => {
      const updatedPermissions = { ...prev.permissions };

      if (checked) {
        // If the method is checked, add it to the permissions
        updatedPermissions[label] = updatedPermissions[label]
          ? [...updatedPermissions[label], method]
          : [method];
      } else {
        // If the method is unchecked, remove it from the permissions
        updatedPermissions[label] = updatedPermissions[label].filter(
          (item) => item !== method
        );

        // If all methods are unchecked, remove the section
        if (updatedPermissions[label].length === 0) {
          delete updatedPermissions[label];
        }
      }

      return {
        ...prev,
        permissions: updatedPermissions,
      };
    });
  };

  useEffect(() => {
    if (selectedRoleData) {
      const initialCheckedState = {};
      for (const [section, methods] of Object.entries(
        selectedRoleData.permissions
      )) {
        initialCheckedState[section] = {};
        for (const method of methods) {
          initialCheckedState[section][method] = true;
        }
      }
      setChecked(initialCheckedState);
    }
  }, [selectedRoleData]);

  useEffect(() => {
    const anySelected = Object.values(isChecked).some((section) =>
      Object.values(section).some((checked) => checked)
    );
    setIsAnyMethodSelected(anySelected);
  }, [isChecked]);

  const SwitchSection = ({ label, methods }) => (
    <Box mt={2}>
      <div
        className={
          isChecked[label] ? styles.active_section : styles.inactive_section
        }
      >
        <div className={styles.text}>
          <img
            src={dropDown}
            alt="dropdown"
            onClick={() => handleDropdownClick(label)}
            style={{ cursor: "pointer" }}
          />
          <div>{label}</div>
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={!!isChecked[label]}
                onChange={() => handleSwitchChange(label)}
              />
            }
          />
        </FormGroup>
      </div>
      {isDropdownOpen[label] && (
        <div>
          <ul
            style={{
              display: "flex",
              margin: "0.2rem 1.5rem 0rem 1.5rem",
              justifyContent: "space-between",
              background: "#3e3e3e",
              paddingRight: "0.6rem",
              color: "white",
            }}
          >
            {methods.map((method, index) => (
              <div key={index}>
                <Checkbox
                  checked={isChecked[label] && isChecked[label][method]}
                  onChange={handleChange(label, method, methods)}
                  value={method}
                />
                <span style={{ marginLeft: "5px" }}>{method}</span>
              </div>
            ))}
          </ul>
        </div>
      )}
    </Box>
  );

  const handleSubmit = () => {
    dispatch(
      editRoleData({
        body: formData,
        id: selectedRoleData?._id,
        page: page,
        limit: limit,
        navigate: navigate,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ActiveWrapper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={styles.header}>
          Edit Activate/Deactivate Features
        </DialogTitle>
        {getPermission &&
          Object.entries(getPermission).map(([section, methods], index) => (
            <SwitchSection key={index} label={section} methods={methods} />
          ))}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            background: isAnyMethodSelected ? "#8176f2" : "#c4c4c4",
            margin: "1.5rem",
            borderRadius: "5px",
            cursor: !isAnyMethodSelected ? "not-allowed" : "pointer",
            color: "white",
            padding: "15px 0px",
          }}
          onClick={isAnyMethodSelected ? handleSubmit : null}
        >
          Submit
        </DialogActions>
      </Dialog>
    </ActiveWrapper>
  );
};

export default EditPermissionModal;
