import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const AnalytisWrapper = ({ children }) => {
  const analytics_theme = createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          item: {
            paddingRight: "2px !important",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: "81vh !important",
            padding: "19px",
            borderRadius: "0px",
            position: "relative",
            overflow: "scroll",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            border: "none",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // height: "100% !important",
            boxShadow: "none !important",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={analytics_theme}>{children}</ThemeProvider>;
};

const PieChartWrapper = ({ children }) => {
  const pieChart_theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            // height: "100% !important",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={pieChart_theme}>{children}</ThemeProvider>;
};

const TableWrapper = ({ children }) => {
  const table_theme = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important",
            marginTop: "1rem !important",
            minWidth: 650,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#8176f2 !important",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          "&:last-child td, &:last-child th": { border: 0 },
        },
      },
    },
  });
  return <ThemeProvider theme={table_theme}>{children}</ThemeProvider>;
};

export { AnalytisWrapper, PieChartWrapper, TableWrapper };
